<template>
 <div>
    <v-dialog v-model="loader" persistent width="300">
      <v-card class="primary" dark>
        <v-card-text
          >Please wait
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LoaderModal from "./index";
export default {
  data() {
    return {
      loader: false,
    };
  },
  beforeMount() {
    LoaderModal.EventBus.$on("show", () => {
      this.loader = true;
    });
    LoaderModal.EventBus.$on("hide", () => {
      this.loader = false;
    });
  },
};
</script>

