<template>
  <div>
    <div
      style="width: 100%; text-align: center; margin-top: 15px; height: 50px"
      class="headline"
    >
      Electricity
    </div>
    <v-autocomplete
      v-model="operator"
      :items="sortedproviver"
      label="Select Operator"
      style="margin: 0 auto; height: 60px"
      chips
      item-text="name"
      item-value="name"
    >
      <template slot="selection" slot-scope="data">
        <v-chip :selected="data.selected" close class="chip--select-multi">
          <v-avatar>
            <img :src="data.item.avatar" @error="errorimg" />
          </v-avatar>
          {{ data.item.name }}
        </v-chip>
      </template>
      <template slot="item" slot-scope="data">
        <template>
          <v-list-tile-avatar>
            <img :src="data.item.avatar" @error="errorimg" />
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile v-html="data.item.name"></v-list-tile>
          </v-list-tile-content>
        </template>
      </template>
    </v-autocomplete>

    <v-text-field
      v-model="rechargernumber"
      style="margin: 0 auto; height: 60px"
      label="Enter Account Number"
    />
    <v-btn
      small
      class="primary"
      depressed
      round
      style="
        float: left;
        font-size: 0.8em;
        padding-left: 15px;
        padding-right: 15px;
      "
      @click="fetchoffer"
      >Fetch Billinfo</v-btn>
     <br />
      <br />
      <p>Bill date is: {{ billdate }}</p>
      <p>Due date is: {{ duedate }}</p>
   
    <v-text-field
      type="text"
      v-model="name"
      style="margin: 0 auto; height: 60px"
      label="Enter Customer Name"
    />

    <v-text-field
      v-model="whatsapp"
      style="margin: 0 auto; height: 60px"
      label="Enter Whatsapp Number"
    />
    <v-text-field
      v-model="rechargeramount"
      style="margin: 0 auto; height: 60px"
      label="Enter Recharge Amount"
    />

    <div style="width: 100%; float: left">
      <v-btn color="primary" style="width: 100%; margin: 0 auto" @click="submit"
        >Submit</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      operator: "",
      provider: [],
      name: "",
      whatsapp: "",
      rechargernumber: "",
      rechargeramount: "",
      duedate:"",
      billdate:""
    };
  },
  computed: {
    getprovider() {
      return this.$store.getters.operator;
    },
    rwidth() {
      return this.$store.getters.rwidth;
    },
    user() {
      return this.$store.getters.user;
    },
    clear() {
      return this.$store.getters.clear;
    },
    sortedproviver() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      let provider = this.provider;
      return provider.sort(compare);
    },
  },
  watch: {
    getprovider() {
      let vm = this;
      vm.pushoperator();
    },
    clear() {
      this.cleardata();
    },
  },
  methods: {
    cleardata() {
      let vm = this;
      vm.operator = "";
      vm.rechargernumber = "";
      vm.rechargeramount = "";
      vm.whatsapp = "";
      vm.name = "";
    },
    errorimg(e) {
      e.target.src = require("../assets/logo_single.png");
    },
    pushoperator() {
      let vm = this;
      vm.provider = [];
      let path = this.$store.getters.path;
      for (let i = 0; i < vm.getprovider.length; i++) {
        let doc = vm.getprovider[i];
        console.log(doc.Category);
        if (doc.Category == "ElectricityBill") {
          let data = {
            avatar: path + doc.CircleImg,
            name: doc.ServiceName,
          };
          vm.provider.push(data);
        }
      }
    },
    fetchoffer() {

      let vm = this;
      var Servicecode ="NA";
      if (vm.operator === "BSESRajdhaniPowerLimitedDelhi") {
                Servicecode = "BSESR";
            }
            else if (vm.operator === "AjmerVidyutVitranNigamLimited") { Servicecode = "AVVNL"; }
            else if (vm.operator === "PaschimGujaratVijCompanyLimited") { Servicecode = "PGVCL"; }
            else if (vm.operator === "MadhyaGujaratVijCompanyLimited") { Servicecode = "MGVCL"; }
            else if (vm.operator === "UttarGujaratVijCompanyLimited") { Servicecode = "UGVCL"; }
            else if (vm.operator === "DakshinGujaratVijCompanyLimited") { Servicecode = "DGVCL"; }
            else if (vm.operator === "TorrentPowerAhmedabad") { Servicecode = "TORRENTAHME"; }
            else if (vm.operator === "TorrentPowerAhemdabad") { Servicecode = "TORRENTAHME"; }
            else if (vm.operator === "TorrentPowerSurat") { Servicecode = "TORRENTSURAT"; }
            else if (vm.operator === "TorrentPowerAgra") { Servicecode = "TORRENTAGRA"; }
            else if (vm.operator === "TorrentPowerBhivandi") { Servicecode = "TORRENTBHIVA"; }
            else if (vm.operator === "TorrentPowerDahej") { Servicecode = "TORRENTDAHEJ"; }
            else if (vm.operator === "MSEDCLimited") { Servicecode = "MSEDCL"; }
            else if (vm.operator === "CalcuttaElectricitySupplyLtd") { Servicecode = "CalcuttaElectricitySupplyLtd"; }
            else if (vm.operator === "DamanandDiuElectricityDepartment") { Servicecode = "DNDE"; }
            else if (vm.operator === "JaipurandAjmerViyutVitranNigam") { Servicecode = "JVVNL"; }
            else if (vm.operator === "KotaElectricityDistributionLtd") { Servicecode = "KEDL"; }
            else if (vm.operator === "MadhyaPradeshPaschimKshetraVidyutVitaranLtd") { Servicecode = "MPPKVVCL"; }
            else if (vm.operator === "RajasthanVidyutVitranNigamLimited") { Servicecode = "WBSEEDCL"; }
            else if (vm.operator === "INDIAPOWERCORPORATION-WESTBENGAL") { Servicecode = "RAPDR"; }
            else if (vm.operator === "WestBengalStateElectricity") { Servicecode = "NRAPDR"; }
            else if (vm.operator === "AssamPowerDistributionCompanyLtd") { Servicecode = "BESCOM"; }
            else if (vm.operator === "AssamPowerDistributionCompanyLtd-NONRAPDR") { Servicecode = "KEDL"; }
            else if (vm.operator === "BangaloreElectricitySupplyCompany") { Servicecode = "KEDL"; }
            else if (vm.operator === "BharatpurElectricityServicesLtd") { Servicecode = "BESTMUMBAI"; }
            else if (vm.operator === "BikanerElectricitySupplyLimited") { Servicecode = "BSESR"; }
            else if (vm.operator === "BrihanMumbaiElectricSupplyandTransportUndertaking") { Servicecode = "BSESY"; }
            else if (vm.operator === "BSESYamuna") { Servicecode = "DHBVN"; }
            else if (vm.operator === "ChhattisgarhStateElectricityBoard") { Servicecode = "DNHPDCL"; }
            else if (vm.operator === "DakshinHaryanaBijliVitranNigam") { Servicecode = "DHBVN"; }
            else if (vm.operator === "DNHPowerDistributionCompanyLimited") { Servicecode = "DNHPDCL"; }
            else if (vm.operator === "EasternPowerDistributionCompanyofAndhraPradeshLimited") { Servicecode = "APSPDCL"; }
            else if (vm.operator === "GulbargaElectricitySupplyCompanyLimited") { Servicecode = "GESCOM"; }
            else if (vm.operator === "IndiaPowerCorporationLimited") { Servicecode = "RAPDR"; }
            else if (vm.operator === "JamshedpurUtilitiesandServicesCompanyLimited") { Servicecode = "JUSC"; }
            else if (vm.operator === "JodhpurVidyutVitranNigamLtd") { Servicecode = "JDVVNL"; }
            else if (vm.operator === "JharkhandBijliVitranNigamLimited") { Servicecode = "JBVNL"; }
            else if (vm.operator === "MeghalayaPowerDistributionCorporationLtd") { Servicecode = "MPDC"; }
                //Mp State
            else if (vm.operator === "RURAL-MadhyaPradeshPoorvKshetraVidyutVitaranCompanyLimited") { Servicecode = "MPPKVVCLPUR"; }
            else if (vm.operator === "URBAN-MadhyaPradeshPoorvKshetraVidyutVitaranCompanyLimited") { Servicecode = "MPPKVVCLPUU"; }
            else if (vm.operator === "MadhyaPradeshPaschimKshetraVidyutVitaran") { Servicecode = "MPPKVVCL"; }
            else if (vm.operator === "BHOPAL-MadhyaPradeshMadhyaKshetraVidyutVitaranCompany") { Servicecode = "MPPKVVCL"; }
            else if (vm.operator === "URBAN-MadhyaPradeshMadhyaKshetraVidyutVitaranCompany") { Servicecode = "MPPKVVCLMU"; }
            else if (vm.operator === "RURAL-MadhyaPradeshMadhyaKshetraVidyutVitaranCompany") { Servicecode = "MPPKVVCLMR"; }

            else if (vm.operator === "MUZAFFARPURVIDYUTVITRANLIMITED") { Servicecode = "MPVL"; }
            else if (vm.operator === "NorthDelhiPowerLimited") { Servicecode = "NDMC"; }
            else if (vm.operator === "NorthBiharPowerDistributionCompanyLtd") { Servicecode = "NBPD"; }
            else if (vm.operator === "NoidaPowerCompanyLimited") { Servicecode = "NBPDCL"; }
            else if (vm.operator === "MUNICIPALCORPORATIONOFGURUGRAM") { Servicecode = "MCOG"; }
            else if (vm.operator === "PunjabStatePowerCorporationLtd") { Servicecode = "PSPCL"; }
            else if (vm.operator === "SouthBiharPowerDistributionCompanyLtd") { Servicecode = "SBPDCL"; }
            else if (vm.operator === "SNDLNagpur") { Servicecode = "SNDL"; }
            else if (vm.operator === "SouthernPowerDistrofAndhraPradesh") { Servicecode = "SPDP"; }
            else if (vm.operator === "TripuraStateElectricityCorporationLtd") { Servicecode = "TSEC"; }
            else if (vm.operator === "TamilNaduElectricityBoard") { Servicecode = "TNEB"; }
            else if (vm.operator === "TPAjmerDistributionLtd") { Servicecode = "TPADL"; }
            else if (vm.operator === "TataPower") { Servicecode = "TATA"; }
            else if (vm.operator === "UttarHaryanaBijliVitranNigam") { Servicecode = "UHBVN"; }
            else if (vm.operator === "UttarakhandPowerCorporationLimited") { Servicecode = "UPPCL"; }
            else if (vm.operator === "UttarPradeshPowerCorpLtd") { Servicecode = "UPPCL"; }
            else if (vm.operator === "UttarPradeshPowerCorpLtd-RURAL") { Servicecode = "UPPCLR"; }
            else if (vm.operator === "UITBhiwadi") { Servicecode = "UTB"; }
            else if (vm.operator === "UttarakhandJalSansthan-") { Servicecode = "UJS"; }
            else if (vm.operator === "DelhiJalBoard") { Servicecode = "DJB"; }
            else if (vm.operator === "ADANIGAS") { Servicecode = "ADGL"; }
            else if (vm.operator === "GujaratGascompanyLimited") { Servicecode = "GJGL"; }
            else if (vm.operator === "VadodaraGasLimited") { Servicecode = "VDGL"; }
            else if (vm.operator === "SabarmatiGasLimited") { Servicecode = "SBGL"; }
            else if (vm.operator === "MahanagarGasLimited") { Servicecode = "MHGL"; }
            else if (vm.operator === "IndraprashthaGas") { Servicecode = "IPGL"; }
            else if (vm.operator === "HaryanaCitygas") { Servicecode = "HCGL"; }
            else if (vm.operator === "SitiEnergy") { Servicecode = "SIGL"; }
            else if (vm.operator === "TripuraNaturalGasCompanyLtd") { Servicecode = "TNGL"; }
            else if (vm.operator === "UniqueCentralPipedGasesPvtLtd") { Servicecode = "UCPG"; }
            else if (vm.operator === "LIC") { Servicecode = "LICOF"; }

     
      if (vm.operator == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Operator Required",
          type: "error",
        });
      if (vm.circle == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Circle Required",
          type: "error",
        });
      if (vm.rechargernumber == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Mobile Number Required",
          type: "error",
        });
     
      let url = "ELECTRICITY_INFO";
      let doc =
        "offer=roffer|tel=" +
        vm.rechargernumber +
        "|operator=" +
        Servicecode +
        "&_=" +
        new Date().getTime();
      vm.$parent.progress = true;
      vm.$store
        .dispatch("planapi", {
          url: url,
          data: doc,
        })
        .then(function (res) {
          vm.$parent.progress = false;
          vm.offers = res.data.records[0];
          vm.rechargeramount =  vm.offers.Billamount;
          vm.name =  vm.offers.CustomerName;
          vm.duedate = vm.offers.Duedate;
          vm.billdate = vm.offers.Billdate;
        })
        .catch(function (error) {
          vm.$parent.progress = false;
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error.message,
            type: "error",
          });
        });
    },
    submit() {
      let vm = this;
      if (vm.user.length == 0)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Authentication Required!",
          type: "warn",
        });
      if (vm.operator == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Operator Required",
          type: "error",
        });
      if (vm.rechargernumber == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Account Number Required",
          type: "error",
        });
      if (vm.name == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Customer Name Required",
          type: "error",
        });
      if (vm.whatsapp == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Whatsapp Number Required",
          type: "error",
        });
      if (vm.whatsapp.length != 10)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "10 Digit Number Required",
          type: "error",
        });
      if (vm.rechargeramount == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Recharge Amount Required",
          type: "error",
        });
      let params =
        "?Mob=" +
        vm.user.phone +
        "&message=BP+" +
        vm.operator +
        "+" +
        vm.rechargernumber +
        "-" +
        vm.name +
        "-" +
        vm.whatsapp +
        "+" +
        vm.rechargeramount +
        "+" +
        vm.user.pin +
        "&source=ANDROID";
      let data = {
        Operator: vm.operator,
        "Account Number": vm.rechargernumber,
        Name: vm.name,
        "Whatsapp Number": vm.whatsapp,
        "Recharge Amount": vm.rechargeramount,
      };
      vm.$parent.sync = {
        title: vm.operator,
        subtitle: vm.rechargernumber,
        status: "process",
      };
      vm.$parent.data = data;
      vm.$parent.params = params.toString();
      vm.$parent.preview = true;
    },
  },
  mounted() {
    let vm = this;
    if (vm.getprovider.length != 0) {
      vm.pushoperator();
    }
  },
};
</script>
