<template>
  <div style="width:400px;margin:0 auto;">
    <img
      :src="require('../assets/settings.png')"
      style="object-fit:contain"
      width="400"
      height="150"
      alt=""
    />
    <v-list>
      <v-list-tile>
        <v-list-tile-action>
          <v-checkbox v-model="position"></v-checkbox>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile
            >Change the Main Table Position</v-list-tile
          >
        </v-list-tile-content>
      </v-list-tile>
      <v-divider></v-divider>
      <v-list-tile>
        <v-list-tile-action>
          <v-checkbox v-model="quickpay"></v-checkbox>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile>Enable Quick Recharge</v-list-tile>
        </v-list-tile-content>
      </v-list-tile>
      <v-divider></v-divider>

      <v-list-tile>
        <v-list-tile-action>
          <v-checkbox v-model="enableoperator"></v-checkbox>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile>Enable Operator Auto Fetch</v-list-tile>
        </v-list-tile-content>
      </v-list-tile>
      <v-divider></v-divider>
    </v-list>

    <v-radio-group
      row
      v-model="oftype"
      style="background: white;float: left;padding-left: 10px;border-bottom:1px solid lightgrey;margin-bottom: 5px;"
    >
      <v-radio label="Mplan Operator API" value="mplan"></v-radio>
      <v-radio label="PayTM Operator API" value="paytm"></v-radio>
    </v-radio-group>
    <div style="background: white">
      <div class="subheading" style="margin-left:72px">
        Set Auto Refresh Interval(second)
      </div>
      <v-slider
        style="width: 80%;margin:0 auto;margin-top:40px;"
        v-model="interval"
        thumb-color="red"
        :min="30"
        :max="300"
        :step="30"
        thumb-label="always"
      ></v-slider>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      position: false,
      quickpay: false,
      interval: 30,
      enableoperator: false,
      oftype: ""
    };
  },
  watch: {
    position() {
      if (this.position) {
        localStorage.position = "true";
      } else {
        localStorage.position = "false";
      }
    },
    quickpay() {
      if (this.quickpay) {
        localStorage.quickpay = "true";
      } else {
        localStorage.quickpay = "false";
      }
    },
    enableoperator() {
      if (this.enableoperator) {
        localStorage.enableoperator = "true";
      } else {
        localStorage.enableoperator = "false";
      }
    },
    interval() {
      localStorage.interval = this.interval;
    },
    oftype() {
      localStorage.oftype = this.oftype;
    }
  },
  mounted() {
    let vm = this;
    let style = localStorage.position;
    if (style == "true") {
      vm.position = true;
    } else {
      vm.position = false;
    }
    let qstyle = localStorage.quickpay;
    if (qstyle == "true") {
      vm.quickpay = true;
    } else {
      vm.quickpay = false;
    }
    let enableoperator = localStorage.enableoperator;
    if (enableoperator == "true" || enableoperator == undefined) {
      vm.enableoperator = true;
    } else {
      vm.enableoperator = false;
    }

    let interval = localStorage.interval;
    if (interval == null) {
      vm.interval = 30;
    } else {
      vm.interval = interval;
    }
    let oftype = localStorage.oftype;
    if (oftype == null) {
      vm.oftype = "mplan";
    } else {
      vm.oftype = oftype;
    }
  }
};
</script>
