<template>
  <div>
    <v-tabs
      next-icon="arrow_forward_ios"
      prev-icon="arrow_back_ios"
      show-arrows
    >
      <v-tabs-slider></v-tabs-slider>
      <template v-for="(tab, i) in tabs">
        <v-tab
          class="primary--text"
          :key="i + 'tab'"
          @click="way = tab.way"
          v-if="check(tab)"
        >
          <img :src="tab.img" :width="tab.width" />
          &nbsp; &nbsp;{{ tab.name }}
        </v-tab>
      </template>
    </v-tabs>
    <Transfer v-show="way == 'transfer'" />
    <Revert v-show="way == 'revert'" />
    <Credit v-show="way == 'credit'" />
    <Dedit v-show="way == 'debit'" />
    <Progress v-if="progress" />
    <Preview v-if="preview" :data="data" :params="params" />
    <Message v-if="message" :res="response" />
  </div>
</template>

<script>
import Progress from "../components/Progress.vue";
import Preview from "../components/Preview.vue";
import Message from "../components/Message.vue";
import Transfer from "../transaction/Transfer.vue";
import Revert from "../transaction/Revert.vue";
import Credit from "../transaction/Credit.vue";
import Dedit from "../transaction/Debit.vue";

export default {
  components: {
    Transfer,
    Revert,
    Credit,
    Dedit,
    Progress,
    Preview,
    Message,
  },
  data() {
    return {
      way: "",
      data: [],
      type: "TT",
      progress: false,
      preview: false,
      message: false,
      response: "",
      params: "",
      tabs: [
        {
          name: "Transfer",
          img: require("../assets/transfer.png"),
          way: "transfer",
          width: 25,
        },
        {
          name: "Revert",
          img: require("../assets/revert.png"),
          way: "revert",
          width: 25,
        },
        {
          name: "Credit Report",
          img: require("../assets/credit.png"),
          way: "credit",
          width: 25,
        },
        {
          name: "Debit Report",
          img: require("../assets/credit.png"),
          way: "debit",
          width: 25,
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    check(item) {
      let vm = this;
      if (vm.user.Type == "Dealer" && item.name == "Transfer") return false;
      if (vm.user.Type == "Dealer" && item.name == "Revert") return false;
      return true;
    },
  },
  mounted() {
    const vm = this;
    let height = window.innerHeight - 210;
    let v_table = document.getElementsByClassName("v-table__overflow");
    for (let i = 0; i < v_table.length; i++) {
      v_table[i].style.height = height + "px";
    }
    vm.tabs.forEach((item) => {
      if (vm.check(item) == true && !vm.way) {
        vm.way = item.way;
      }
    });
  },
};
</script>
