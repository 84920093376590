<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline" primary-title>
          Enter Complain Remark
        </v-card-title>

        <v-card-text>
          <v-textarea
            solo
            name="input-7-4"
            v-model="remark"
            label="Remark Here"
            no-resize
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="dialog = false">Cancel</v-btn>
          <v-btn color="primary" flat @click="submit">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["id"],
  data() {
    return {
      dialog: true,
      rid: "",
      remark: ""
    };
  },
  methods: {
    submit() {
      let vm = this;
      vm.$parent.progress = true;
      if (vm.rid == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Transaction ID Required",
          type: "error"
        });
      vm.$parent.complaint = false;
      let params =
        "?Mob=" +
        vm.user.phone +
        "&message=com+" +
        vm.rid +
        "+" +
        vm.user.pin +
        "+" +
        vm.remark +
        "&source=ANDROID";
      vm.$store
        .dispatch("raxios", {
          data: params
        })
        .then(function(doc) {
          vm.$parent.progress = false;
          vm.$parent.response = doc.data;
          vm.$parent.message = true;
        })
        .catch(function(error) {
          vm.$parent.progress = false;
          vm.$parent.response = error.message;
          vm.$parent.message = true;
        });
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  mounted() {
    let vm = this;
    vm.rid = vm.id;
  }
};
</script>
