<template>
    <div>
      <v-toolbar class="secondary" dense style="color: white; font-weight: bold">
      DMR Inquiry Report
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <download-excel
            :data="table"
            name="DMRInquiry.xls"
            :fields="fields"
            style="height: 30px; margin-top: 9px; margin-right: 10px"
          >
            <v-btn class="success" small>Export</v-btn>
          </download-excel>
          <input
            slot="activator"
            style="color: black; margin-top: 7px"
            v-model="phone"
            class="search"
            placeholder="Enter account number"
          />
          <v-btn
            class="success searchbtn"
            style="height: 34px; margin-left: 10px"
            @click="fetchstatement"
            >Search</v-btn
          >
        </v-toolbar-items>
      </v-toolbar>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="table"
          hide-actions
          :search="search"
          :loading="loading"
        >
          <v-progress-linear
            slot="progress"
            color="blue"
            indeterminate
          ></v-progress-linear>
          <template slot="items" slot-scope="props">
            <td :style="{ background: getbackground(props.item.status) }">
              {{ props.item.orderno }}
            </td>
            <td :style="{ background: getbackground(props.item.status) }">
              {{ props.item.name }}
            </td>
            <td :style="{ background: getbackground(props.item.status) }">
              {{ props.item.sender }}
            </td>
            <td :style="{ background: getbackground(props.item.status) }">
              {{ props.item.mobile }}
            </td>
            <td :style="{ background: getbackground(props.item.status) }">
              {{ props.item.bank_name }}
            </td>            
            <td :style="{ background: getbackground(props.item.status) }">
              {{ props.item.account }}
            </td>
            <td :style="{ background: getbackground(props.item.status) }">
              {{ props.item.bank_txtid }}
            </td>
            <td :style="{ background: getbackground(props.item.status) }">
              {{ props.item.amount }}
            </td>
            <td :style="{ background: getbackground(props.item.status) }">
              <v-btn
                small
                :class="checkstatus(props.item.status)"
                style="width: 100%"
                >{{ props.item.status }}</v-btn
              >
            </td>
                  
          
            <td :style="{ background: getbackground(props.item.status) }">
              {{
                new Date(parseInt(props.item.time))
                  | moment("DD-MM-YYYY, h:mm:ss a")
              }}
            </td>           
            <td :style="{ background: getbackground(props.item.status) }">
              <v-btn   v-if="isPrintable(props.item.status)"
            @click="getsummary(props.item)" small style="width: 100%" class="primary">Print</v-btn>
            </td>
          </template>
        </v-data-table>
      </v-card>
      <Progress v-if="progress" />
      <Preview v-if="preview" :data="data" :params="params" />
      <Message v-if="message" :res="response" />
      <Complaint v-if="complaint" :id="tid" />
      <Print :summary="summary" :list="filterByPos(summary.pos)" />
    </div>
  </template>
  
  <script>
  import Progress from "../components/Progress.vue";
  import Preview from "../components/Preview.vue";
  import Message from "../components/Message.vue";
  
  import Complaint from "../components/Complaint.vue";
  import Print from "../dmr1/Print.vue";
  export default {
    components: {
      Progress,
      Preview,
      Message,
      Complaint,
      Print
    },
    data() {
      return {
        way: "prepaid",
        loading: false,
        data: [],
        progress: false,
        preview: false,
        message: false,
        response: "",
        params: "",
        search: "",
        type: "BP",
        phone: "",
        tid: "",
        pagination: {},
        complaint: false,
        fields: {
          "Recharge ID": "orderno",
          Name: "name",
          Sender: "sender",
          Mobile: "mobile",
          "Bank Name": "bank_name",
          "Account": "account",
          "Bank Txtid": "bank_txtid",
          "amount": "amount",
          "status": "status",        
          "Recharge Time": "time",
        },
        headers: [
          {
            text: "Recharge ID",
            value: "orderno",
            align: "left",
            sortable: false,
          },
          { text: "Name", value: "name" },
          { text: "Sender", value: "sender" },
          { text: "Mobile", value: "mobile" },
          { text: "Bank Name", value: "bank_name" },
          { text: "Account", value: "account" },
          { text: "Bank Txtid", value: "bank_txtid" },
          { text: "Amount", value: "amount" },       
          { text: "Status", value: "status" },    
          { text: "Recharge Time", value: "time" },   
          { text: "Action", value: "action", align: "center" },
        ],
        viewstatus: false,
        summary: {},
        report: [],
        table: [],
      };
    },
    methods: {
      filterByPos(pos) {
      const vm = this;
      let list = [];
      vm.report.forEach((item) => {
        if (item.pos === pos && vm.isPrintable(item.status)) {
          list.push(item);
        }
      });
      return list;
    },
      getsummary(doc) {
      let vm = this;
      vm.viewstatus = true;
      vm.summary = doc;
      console.log(doc);
      // vm.summary = {
      //   "Order No": doc.orderid,
      //   "Name ": doc.name,
      //   Mobile: doc.mobile,
      //   Amount: doc.amount,
      //   Status: doc.status,
      //   Time: doc.time,
      //   "Bank Transaction Id": doc.bank_txtid,
      //   "Transaction Id": doc.tid
      // };
    },
      isPrintable(status) {
      return status == "success" || status == "pending";
    },
      checkstatus(status) {
        status = status.toLowerCase();
        if (status == "success") return "success";
        if (status == "pending") return "grey";
        if (status == "in process") return "warning";
        if (status == "fail") return "error";
        return "primary";
      },
      getbackground(status) {
        status = status.toLowerCase();
        if (status == "success") return "#ffff";
        if (status == "pending") return "#B0BEC5";
        if (status == "in process") return "#FFF59D";
        if (status == "fail") return "#EF9A9A";
        return "#90CAF9";
      },
      openremark(item) {
        let vm = this;
        vm.tid = item.tid;
        if (item.tid == null) return;
        vm.complaint = true;
      },
      handleresize() {
        let vm = this;
        let width = window.innerWidth - 330 - 293 + "px";
        vm.$store.commit("setrwidth", width);
      },
      showtable() {
        let vm = this;
        vm.table = [];
        for (let i = 0; i < vm.report.length; i++) {
          let doc = vm.report[i];
          let data = {
            orderno: doc.RechargeId,
            name: doc.recipient_name,
            sender: doc.CreateByUserName,
            mobile: doc.MobileNo,
            bank_name: doc.bankName,
            account: doc.accountno,
            bank_txtid: doc.BankRefrenceNo,
            amount: doc.Amount,
            status : doc.sTransactionStatus,            
            time: doc.CreatedDate.replace("/Date(", "").replace(")/", "")           
          };
          vm.table.push(data);
        }
        vm.loading = false;
      },
      fetchstatement() {
        let vm = this;
        if (vm.phone == "")
          return vm.$store.dispatch("notify", {
            vm: vm,
            message: "Please Enter account Number",
            type: "warn",
          });
      
        if (vm.user.length != 0) {
          vm.loading = true;
          let doc = {
            MobileNo: vm.user.phone,
            PinNo: vm.user.pin,
            AccountNumber: vm.phone,
          };
          let url = vm.$root.loginType === "dmr" ? "InquiryDMR" : "InquiryByAccount";
          vm.$store
            .dispatch("axios", {
              url: url,
              data: doc,
            })
            .then(function (res) {
              vm.report = [];
              if (res.data.Data != null) {
                vm.report = res.data.Data;
                vm.showtable();
              }
              vm.loading = false;
            })
            .catch(function (error) {
              vm.$store.dispatch("notify", {
                vm: vm,
                message: error,
                type: "warn",
              });
            });
        }
      },
    },
    computed: {
      user() {
        return this.$store.getters.user;
      },
    },
  };
  </script>
  
  <style scoped>
  .search {
    width: 200px;
    float: right;
    height: 34px;
    background: white;
    outline: none;
    margin-left: 20px;
    padding-left: 10px;
    font-size: 1em;
  }
  .searchbtn {
    margin-right: 15px;
    height: 34px;
    margin-left: 20px;
    float: right;
    margin-top: 0px;
  }
  </style>
  