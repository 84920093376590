<template>
  <v-dialog v-model="dialog" max-width="600px" persistent scrollable>
    <template v-slot:activator="{ on }">
      <v-btn depressed class="success" small v-on="on">
        {{ isKyc ? "KYC" : "UPDATE DOC" }}</v-btn
      >
    </template>
    <v-card class="pa-2">
      <v-card-title class="subheading font-weight-bold pb-0">
        Onboard User Detail
      </v-card-title>
      <v-card-text>
        <v-form v-model="isValid" ref="form" class="d-flex aeps2-row">
          <template v-if="isKyc">
            <v-text-field
              label="Mobile Number"
              :rules="[...field, ...phone]"
              v-model="form.mobilenumber"
              class="pr-3"
            />
            <v-text-field disabled label="User Id" v-model="readonly.user_id" />
          </template>
          <v-text-field
            label="PAN Number"
            :rules="field"
            class="pr-3"
            v-model="form.panno"
          />
          <v-text-field
            label="Outlet ID"
            :rules="field"
            v-model="form.outletid"
          />
          <template v-if="isKyc">
            <v-text-field
              label="First Name"
              class="pr-3"
              :rules="field"
              v-model="form.firstname"
            />
            <v-text-field
              label="Middle Name"
              :rules="field"
              v-model="form.middlename"
            />
            <v-text-field
              label="Last Name"
              v-model="form.lastname"
              class="pr-3"
              :rules="field"
            />
            <v-text-field
              label="Email"
              :rules="[...field, ...email]"
              v-model="form.email"
            />
            <v-text-field
              label="Pincode"
              :rules="[...field, ...pincode]"
              class="pr-3"
              v-model="form.pincode"
            />
            <v-text-field
              label="Address"
              v-model="form.address"
              :rules="field"
            />
            <v-text-field
              label="State"
              v-model="form.state"
              :rules="field"
              class="pr-3"
            />
            <v-text-field label="City" v-model="form.city" :rules="field" />
            <v-text-field
              label="Aadhar Number"
              class="pr-3"
              v-model="form.aadharno"
              :rules="[...field, ...aadhar]"
            />
            <v-text-field
              label="Comments"
              v-model="form.Comments"
              :rules="field"
            />
            <v-text-field
              label="Kyc Status"
              class="pr-3"
              disabled
              v-model="readonly.kyc_status"
            />
            <v-text-field
              label="Outlet Status"
              disabled
              v-model="readonly.outlet_status"
            />
          </template>
          <v-text-field
            type="text"
            @click="$refs.aadhar.click()"
            class="pr-3"
            readonly
            label="Aadhar Card"
            v-model="aadhar_file_name"
            :rules="field"
          >
          </v-text-field>
          <input
            type="file"
            @change="onFileChange($event, 'aadhar')"
            ref="aadhar"
            style="display: none"
            accept="image/*"
          />
          <v-text-field
            :rules="field"
            v-model="address_file_name"
            @click="$refs.address.click()"
            readonly
            label="Business Name & Address"
          >
          </v-text-field>
          <input
            type="file"
            @change="onFileChange($event, 'address')"
            ref="address"
            style="display: none"
            accept="image/*"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" @click="dialog = false" flat>Close</v-btn>
        <v-btn color="success" @click="submit()" flat>Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import aeps2Service from "../../services/aeps2.service.js";
export default {
  props: {
    item: {},
    type: {},
  },
  data() {
    return {
      dialog: false,
      isValid: false,
      field: [(v) => !!v || "Field is required"],
      phone: [(v) => v.length == 10 || "Mobile Number must be 10 digit"],
      aadhar: [(v) => v.length == 12 || "Aadhar must be 12 digit"],
      pincode: [(v) => v.length == 6 || "Pincode must be 6 digit"],
      email: [(v) => /.+@.+/.test(v) || "E-mail must be valid"],
      aadhar_file_name: "",
      address_file_name: "",
      aadhar_file: null,
      address_file: null,
      readonly: {
        user_id: "",
        kyc_status: "",
        outlet_status: "",
      },
      form: {
        mobilenumber: "",
        panno: "",
        outletid: "",
        email: "",
        firstname: "",
        middlename: "",
        lastname: "",
        aadharno: "",
        address: "",
        pincode: "",
        state: "",
        city: "",
        Comments: "",
      },
      keys: {
        mobilenumber: "mobile",
        panno: "pan_no",
        outletid: "outlet_id",
        email: "email",
        firstname: "first_name",
        middlename: "middle_name",
        lastname: "last_name",
        aadharno: "aadhaar_no",
        address: "address",
        pincode: "pincode",
        district: "district",
        state: "state",
        city: "city",
        Comments: "comments",
        user_id: "user_id",
        kyc_status: "kyc_status",
        outlet_status: "outlet_status",
      },
    };
  },
  computed: {
    isKyc() {
      return this.type == "kyc";
    },
    user() {
      return this.$store.getters.user;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.fetchAgent();
      }
    },
  },
  methods: {
    async fetchAgent() {
      const vm = this;
      try {
        vm.$loader.show();
        let { Outletid, MobileNo } = vm.item;
        let doc = { MobileNumber: MobileNo, OutletId: Outletid };
        let { Status, Message, data } = (
          await aeps2Service.getAgentDetails(doc)
        ).data;
        if (data.DATA instanceof Object) {
          let doc = data.DATA;
          Object.keys(vm.form).forEach((key) => {
            if (doc[vm.keys[key]] != undefined)
              vm.form[key] = doc[vm.keys[key]];
          });
        }
        if (data.DATA instanceof Object) {
          let doc = data.DATA;
          Object.keys(vm.form).forEach((key) => {
            if (doc[vm.keys[key]] != undefined)
              vm.form[key] = doc[vm.keys[key]];
          });
          Object.keys(vm.readonly).forEach((key) => {
            if (doc[vm.keys[key]] != undefined)
              vm.readonly[key] = doc[vm.keys[key]];
          });
        }
        if (Status == "True") {
        } else {
          vm.dialog = false;
          vm.$alert.show(Message);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    onFileChange(e, name) {
      const vm = this;
      let file = e.target.files[0];
      vm[`${name}_file`] = null;
      vm[`${name}_file_name`] = "";
      if (file != null) {
        vm[`${name}_file`] = file;
        vm[`${name}_file_name`] = file.name;
      }
    },
    async submit() {
      const vm = this;
      let { phone, pin } = vm.$store.getters.user;
      vm.$refs.form.validate();
      if (!vm.isValid) return;
      try {
        vm.$loader.show();
        let form = new FormData();
        console.log(vm.aadhar_file);
        console.log(vm.address_file);
        form.append("", vm.aadhar_file);
        form.append("", vm.address_file);
        form.append("MobileNo", phone);
        form.append("PinNo", pin);
        let doc = {};
        ["mobilenumber", "outletid", "panno"].forEach((key) => {
          doc[key] = vm.form[key];
        });
        let keys = vm.isKyc ? vm.form : doc;
        Object.keys(keys).forEach((key) => {
          form.append(key, vm.form[key]);
        });

        let { Status, Message } = (await aeps2Service.updateKYC(form)).data;
        if (Status == "True") {
          vm.$emit("onNew");
          vm.dialog = false;
        } else {
          vm.$alert.show(Message);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style>
.aeps2-row {
  flex-wrap: wrap;
}
.aeps2-row .v-input {
  flex: 0 0 50% !important;
}
</style>
