<template>
  <div>
    <v-toolbar color="secondary" dark dense>
      <v-tabs color="secondary" dark v-model="tab">
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab>&nbsp; &nbsp;AEPS 2 Agents</v-tab>
        <v-tab>&nbsp; &nbsp;AEPS 2 History</v-tab>
      </v-tabs>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <aeps-2-registration @onNew="onNew()"></aeps-2-registration>
      </v-toolbar-items>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <aeps-2-agents ref="agents"></aeps-2-agents>
      </v-tab-item>
      <v-tab-item>
        <aeps-2-history></aeps-2-history>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Aeps2Agents from "../components/aeps2/Aeps2Agents.vue";
import Aeps2History from "../components/aeps2/Aeps2History.vue";
import Aeps2Registration from "../components/aeps2/Aeps2Registration.vue";
export default {
  components: { Aeps2Registration, Aeps2Agents, Aeps2History },
  data() {
    return {
      tab: 0,
    };
  },
  methods: {
    onNew() {
      this.$refs.agents.fetchAgents(false);
    },
  },
};
</script>

<style></style>
