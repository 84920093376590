<template>
  <v-card class="mt-3">
    <v-data-table :items="list" :headers="headers">
      <template v-slot:items="props">
        <td>{{ props.item.name }}</td>
        <td>{{ props.item.MobileNo }}</td>
        <td>{{ props.item.Pan }}</td>
        <td>{{ props.item.Outletid }}</td>
        <td>
          <v-btn
            depressed
            small
            :class="props.item.Status ? 'success' : 'primary'"
          >
            {{ props.item.Status ? "Verified" : "Check OnBoard" }}
          </v-btn>
        </td>
        <td>
          <v-btn
            depressed
            class="secondary"
            v-if="isICICIBank"
            @click="openICICIBank(props.item)"
            small
            >ICIC Bank</v-btn
          >
          <v-btn
            depressed
            class="secondary"
            v-if="isYesBank"
            small
            @click="openYesBank(props.item)"
            >Yes Bank</v-btn
          >
          <template v-if="props.item.Status == false">
            <aeps-2-kyc
              :item="props.item"
              type="kyc"
              @onNew="fetchAgents(false)"
            ></aeps-2-kyc>
            <aeps-2-kyc
              :item="props.item"
              type="doc"
              @onNew="fetchAgents(false)"
            ></aeps-2-kyc>
          </template>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import aeps2Service from "../../services/aeps2.service";
import Aeps2Kyc from "./Aeps2Kyc.vue";
import config from "../../config/index.js";
export default {
  components: { Aeps2Kyc },
  data() {
    return {
      list: [],
      isICICIBank: false,
      isYesBank: false,
    };
  },
  computed: {
    headers() {
      let doc = {
        name: "Name",
        MobileNo: "Mobile Number",
        Pan: "PAN Number",
        Outletid: "Outlet ID",
        Status: "Status",
        action: "Actions",
      };
      let list = [];
      Object.keys(doc).forEach((value) => {
        list.push({
          text: doc[value],
          divider: value != "action",
          value,
        });
      });
      return list;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async openICICIBank({ Pan, MobileNo, Outletid }) {
      const vm = this;
      try {
        vm.$loader.show();
        let { data, Status, Message } = (
          await aeps2Service.getICICIWithdrawToken({
            pancard: Pan,
            RegMobile: MobileNo,
            OutletId: Outletid,
          })
        ).data;
        vm.$loader.hide();
        if (Status != "True") {
          vm.$alert.show(Message);
          return;
        }
        let token = decodeURIComponent(data.ACCESSKEY);
        let url = `${config.apes2LoginUrl}?token=${token}`;
        window.open(url, "_blank");
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    async openYesBank({ Pan, MobileNo, Outletid }) {
      const vm = this;
      try {
        vm.$loader.show();
        let { data, Status, Message } = (
          await aeps2Service.getYesBankWithdrawToken({
            pancard: Pan,
            RegMobile: MobileNo,
            OutletId: Outletid,
          })
        ).data;
        vm.$loader.hide();
        if (Status != "True") {
          vm.$alert.show(Message);
          return;
        }
        let token = decodeURIComponent(data.ACCESSKEY);
        let url = `${config.apes2LoginUrl}?token=${token}`;
        window.open(url, "_blank");
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    async fetchAgents(isGateWay) {
      const vm = this;
      try {
        vm.$loader.show();
        let { Status, Message, data } = (await aeps2Service.getAgents({})).data;
        if (Status == "True") {
          vm.list = data.map((item) => {
            let { FirstName, MiddelName, LastName } = item;
            item.name = `${FirstName} ${MiddelName} ${LastName}`;
            return item;
          });
        } else {
          vm.$alert.show(Message);
        }
        vm.$loader.hide();
        if (isGateWay) {
          vm.getGateway();
        }
      } catch (error) {
        console.log(error);
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    async getGateway() {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = (await aeps2Service.getGateway({})).data;
        if (data.AEPS2Subgateway instanceof Object) {
          let { AEPSICICI, AEPSYES } = data.AEPS2Subgateway;
          vm.isICICIBank = AEPSICICI == "True";
          vm.isYesBank = AEPSYES == "True";
        }
        vm.$loader.hide();
      } catch (error) {
        console.log(error);
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.fetchAgents(true);
  },
};
</script>

<style></style>
