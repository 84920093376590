<template>
  <v-dialog v-model="$parent.viewstatus" width="950">
    <div class="printer__container">
      <div class="printer" ref="print">
        <table style="margin: 10px;width: 100%; font-size: 9px;color:#000000;">
          <tr>
            <td colspan="4">
              <table style=" font-size: 12px;color:#000000; width:100%">
                <tr>
                  <td rowspan="2" style="width: 30%;">
                    <img
                      style="display: inline; height: 70px;"
                      src="../assets/logo.png"
                      alt
                    />
                  </td>
                  <td
                    style="padding-left: 5%; padding-top: 20px; white-space: nowrap;"
                    colspan="3"
                  >
                    <h3 style="display: inline;">Money Transfer Receipt</h3>
                  </td>
                </tr>
                <tr>
                  <td style="width:19%"></td>
                  <td style="width:19%"></td>
                  <td style="font-size: 11px">
                    Date:
                    {{
                      new Date(parseInt(summary.time))
                        | moment("DD/MM/YYYY hh:mm A")
                    }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td
              style="font-size: 10px; background-color: #227788; color: #FFFFFF; padding: 3px 8px;"
              colspan="2"
            >
              Retailer Details
            </td>
            <td
              style="font-size: 10px; background-color: #227788; color: #FFFFFF; padding: 3px 8px;"
              colspan="2"
            >
              Beneficiary Details
            </td>
          </tr>
          <tr>
            <td style="width: 17%; padding: 5px 0px 2px 9px;">Name</td>
            <td style="width: 33%; padding: 5px 0px 2px 9px;">
              {{ summary.sender }}
            </td>
            <td style="width: 16%; padding: 5px 0px 2px 9px;">Name</td>
            <td style="width: 34%; padding: 5px 0px 2px 9px;">
              {{ summary.name }}
            </td>
          </tr>

          <tr>
            <td style="width: 17%; padding: 2px 9px; white-space: nowrap;">
              Contact Info
            </td>
            <td style="width: 33%; padding: 2px 9px;">{{ summary.mobile }}</td>
            <td style="width: 16%; padding: 2px 9px;">Bank Name</td>
            <td style="width: 34%; padding: 2px 9px;">
              {{ summary.bank_name }}
            </td>
          </tr>
          <tr>
            <td style="width: 17%; padding: 2px 9px;"></td>
            <td style="width: 33%; padding: 2px 9px;"></td>
            <td style="width: 16%; padding: 2px 9px;">Acc. No</td>
            <td style="width: 34%; padding: 2px 9px;">{{ summary.account }}</td>
          </tr>
          <tr>
            <td style="width: 17%; padding: 2px 9px;"></td>
            <td style="width: 33%; padding: 2px 9px;"></td>
            <td style="width: 16%; padding: 2px 9px;">Ifsc Code</td>
            <td style="width: 34%; padding: 2px 9px;">
              {{ summary.bank_ifsc }}
            </td>
          </tr>
          <tr>
            <td style="width: 17%; padding: 2px 9px;"></td>
            <td style="width: 33%; padding: 2px 9px;"></td>
            <td style="width: 16%; padding: 2px 9px;">Sender No</td>
            <td style="width: 34%; padding: 2px 9px;">{{ summary.sender }}</td>
          </tr>
          <tr>
            <td
              style="font-size: 10px; background-color: #227788; color: #FFFFFF; padding: 3px 8px;"
              colspan="4"
            >
              Transaction Details
            </td>
          </tr>
          <tr>
            <td colspan="4" style="padding: 2px 10px;">
              <table
                style="width: 100%;border-collapse: collapse; font-size: 9px;color:#000000; margin-top:4px;"
              >
                <tr>
                  <th
                    width="18%"
                    style="padding: 2px 5px; border: 1px solid #EEEEEE;"
                  >
                    Transaction Id
                  </th>
                  <th
                    width="22%"
                    style="padding: 2px 5px; border: 1px solid #EEEEEE;"
                  >
                    Bank Reference No
                  </th>
                  <th style="padding: 2px 5px; border: 1px solid #EEEEEE;">
                    Method
                  </th>
                  <th style="padding: 2px 5px; border: 1px solid #EEEEEE;">
                    Status
                  </th>
                  <th style="padding: 2px 5px; border: 1px solid #EEEEEE;">
                    Amount
                  </th>
                </tr>

                <tr v-for="(item, i) in list" :key="'tlist' + i">
                  <td
                    width="18%"
                    style="padding: 2px 5px; border: 1px solid #EEEEEE;"
                  >
                    {{ item.orderid }}
                  </td>
                  <td
                    width="22%"
                    style="padding: 2px 5px; border: 1px solid #EEEEEE;"
                  >
                    {{ item.bank_txtid }}
                  </td>
                  <td style="padding: 2px 5px; border: 1px solid #EEEEEE;">
                    {{ item.service }}
                  </td>
                  <td style="padding: 2px 5px; border: 1px solid #EEEEEE;">
                    {{ getStatus(item.status) }}
                  </td>
                  <td
                    style="padding: 2px 5px; border: 1px solid #EEEEEE; text-align:right;"
                  >
                    Rs. {{ item.amount }}
                  </td>
                </tr>

                <tr>
                  <td
                    colspan="4"
                    style="text-align:center; font-weight:bold;padding: 2px 5px; border: 1px solid #EEEEEE;"
                  >
                    Total
                  </td>
                  <td
                    style="padding: 2px 5px; font-weight:bold; border: 1px solid #EEEEEE; text-align:right;"
                  >
                    Rs. {{ total }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
      <div class="printer__footer">
        <v-spacer />
        <v-btn color="red" dark @click="$parent.viewstatus = false"
          >Cancel</v-btn
        >
        <v-btn color="primary" @click="print()">Print</v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import moment from "moment";
export default {
  props: ["summary", "list"],
  computed: {
    total() {
      let total = 0;
      if (this.list) {
        this.list.forEach(item => (total += item.amount));
      }
      return total;
    }
  },
  methods: {
    print() {
      let vm = this;
      const prtHtml = vm.$refs.print.innerHTML;
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
                <html>
                <head>
                <style>
                @media print {
                body {-webkit-print-color-adjust: exact;}
                }
                </style>
                </head>
                <body>
                    <div class="printer">
                        ${prtHtml}
                    </div>
                </body>
                </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function() {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
    getStatus(status) {
      if (status == 7) return "Success";
      if (status == 5) return "Response Awaited/Initiated";
      if (status == 3) return "Refund Pending";
      if (status == 4) return "Refunded";
      if (status == 6) return "Fail";
      return "-";
    }
  },
  mounted() {
    console.log(this.list);
  }
};
</script>

<style>
.printer__container {
  background: white;
}
.printer {
  width: 100%;
  background: white;
  padding: 25px;
}
.printer__header {
  padding-bottom: 20px;
}
.printer__image {
  width: 100px;
  height: 40px;
  float: left;
  object-fit: contain;
}
.printer__title {
  font-size: 2rem;
  float: left;
  text-align: center;
}
.printer__table {
  width: 100%;
  border-collapse: collapse;
}
.printer__tr td {
  padding: 10px !important;
  font-size: 1.2em;
}
.printer__tr--title {
  background: green;
  font-weight: bold;
  color: white;
}
.printer__table--border td {
  border: 1px solid lightgrey;
  margin-bottom: 10px;
}
.printer__footer {
  display: flex;
  padding-right: 15px;
  padding-bottom: 15px;
}
</style>
