<template>
  <div>
    <v-toolbar class="secondary" dense style="color: white; font-weight: bold">
      Statement Report
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <download-excel
          :data="statementlist"
          name="StatementReport.xls"
          :fields="fields"
          style="height: 30px; margin-top: 9px; margin-right: 10px"
        >
          <v-btn class="success" small>Export</v-btn>
        </download-excel>
        <v-menu
          :close-on-content-click="false"
          v-model="menu1"
          lazy
          transition="scale-transition"
          offset-y
          style="float: right"
        >
          <input
            type="text"
            slot="activator"
            style="color: black"
            v-model="date1"
            readonly
            class="search"
            placeholder="From Date"
          />
          <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
        </v-menu>
        <v-menu
          :close-on-content-click="false"
          v-model="menu2"
          lazy
          transition="scale-transition"
          offset-y
          style="float: right"
        >
          <input
            type="text"
            slot="activator"
            style="color: black"
            v-model="date2"
            readonly
            class="search"
            placeholder="To Date"
          />
          <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
        </v-menu>

        <v-btn
          class="success searchbtn"
          style="height: 34px; margin-left: 10px"
          @click="fetchstatement"
        >
          Search</v-btn
        >
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      hide-actions
      :items="statementlist"
      :loading="loading"
    >
      <v-progress-linear
        slot="progress"
        color="blue"
        indeterminate
      ></v-progress-linear>
      <template slot="items" slot-scope="props">
        <td>
          {{ new Date(props.item.date) | moment("DD-MM-YYYY, h:mm:ss a") }}
        </td>
        <td>{{ props.item.remark }}</td>
        <td>{{ props.item.paymenttype }}</td>
        <td>{{ props.item.debit }}</td>
        <td>{{ props.item.credit }}</td>
        <td>{{ props.item.useramount }}</td>
        <td>{{ props.item.username }}</td>
        <td>{{ props.item.mobile }}</td>
        <td>{{ props.item.rechargeid }}</td>
        <td>{{ props.item.paytransferid }}</td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      date1: new Date().toISOString().substr(0, 10),
      menu1: false,
      date2: new Date().toISOString().substr(0, 10),
      menu2: false,
      loading: false,
      headers: [
        { text: "Created Date", value: "date" },
        { text: "Remark", value: "remark" },
        { text: "Payment Type", value: "paymenttype" },
        { text: "Debit", value: "debit" },
        { text: "Credit", value: "credit" },
        { text: "Closing Amount", value: "useramount" },
        { text: "Username", value: "username" },
        { text: "Mobile Number", value: "mobile" },
        { text: "Recharge ID", value: "rechargeid" },
        { text: "Payment Transfer ID", value: "paytransferid" },
      ],
      fields: {
        "Created Date": "date",
        Remark: "remark",
        "Payment Type": "paymenttype",
        Debit: "debit",
        Credit: "credit",
        "User Amount": "useramount",
        "Payment Type ID": "paymenttypeid",
        Username: "username",
        "Mobile Number": "mobile",
        "Recharge ID": "rechargeid",
        "Payment Transfer ID": "paytransferid",
      },
      statementlist: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    fetchstatement() {
      let vm = this;
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc =
        "?Mob=" +
        vm.user.phone +
        "&pin=" +
        vm.user.pin +
        "&StartDate=" +
        vm.date1 +
        "+23%3A15%3A30&EndDate=" +
        vm.date2 +
        "+23%3A15%3A30&Cmd=Statement&source=ANDROID";
      vm.$store
        .dispatch("saxios", {
          data: doc,
        })
        .then(function (res) {
          vm.pushstatement(res.data);
        })
        .catch(function (error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn",
          });
        });
    },
    pushstatement(datas) {
      let vm = this;
      vm.statementlist = [];
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        let credit = "";
        let debit = "";
        if (data.Type == "D") {
          debit = data.Amount;
        } else {
          credit = data.Amount;
        }
        let doc = {
          date: data.CreateDate,
          remark: data.Remark,
          paymenttype: data.PaymentType,
          debit: debit,
          credit: credit,
          useramount: data.UserAmount,
          paymenttypeid: data.PaymentTypeId,
          username: data.UserName,
          mobile: data.MobileNo,
          rechargeid: data.RechargeId,
          paytransferid: data.PayTrfId,
        };
        vm.statementlist.push(doc);
      }
      vm.loading = false;
    },
  },
  mounted() {
    let vm = this;
    vm.fetchstatement();
  },
};
</script>

<style scoped>
.search {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
