<template>
  <div>
    <v-dialog v-model="view" width="400" persistent>
      <v-card color="#01579B">
        <v-card-title style="height:40px;color:white">
          <span class="body-2">Confirm Details</span>
        </v-card-title>
        <v-card-text style="padding-top:0px">
          <v-layout row wrap v-for="(item, key) in summary" :key="key + item">
            <v-flex xs6>
              <v-card-text
                class="px-0 title"
                style="color:white;padding-top:5px;padding-bottom:8px"
                >{{ key }}</v-card-text
              >
            </v-flex>
            <v-flex xs6>
              <v-card-text
                class="px-0 title"
                style="color:white;padding-top:5px;padding-bottom:8px"
                >{{ item }}</v-card-text
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions style="width:100%">
          <v-spacer></v-spacer>
          <v-btn small class="error" @click="cancel">Cancel</v-btn>
          <v-btn small class="success" @click="submit">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["data", "params", "sync"],
  data() {
    return {
      view: true,
      summary: []
    };
  },
  computed: {
    cleardata() {
      return this.$store.getters.clear;
    }
  },
  methods: {
    submit() {
      let vm = this;
      let way = localStorage.quickpay;
      if (way == "true" && vm.sync != null) {
        vm.quickpay();
        return;
      }
      vm.$parent.progress = true;
      let path = "raxios";
      if (vm.$parent.type == "RQ") path = "saxios";
      vm.$store
        .dispatch(path, {
          data: vm.params
        })
        .then(function(doc) {
          if (vm.$parent.type == "BP") {
            vm.$parent.fetchdata();
          }
          vm.$store.dispatch("getbalance");
          vm.$store.commit("setclear", new Date().getTime());
          vm.$parent.progress = false;
          vm.$parent.message = true;
          vm.$parent.response = doc.data;
          vm.$parent.preview = false;
        })
        .catch(function(error) {
          vm.$parent.progress = false;
          vm.$parent.message = true;
          vm.$parent.response = error;
        });
    },
    quickpay() {
      let vm = this;
      let path = "raxios";
      vm.$parent.preview = false;
      vm.$store.commit("setclear", new Date().getTime());
      vm.$snotify.async(
        vm.sync.subtitle,
        vm.sync.title,
        () =>
          new Promise(resolve => {
            if (vm.$parent.type == "RQ") path = "saxios";
            vm.$store
              .dispatch(path, {
                data: vm.params
              })
              .then(function(doc) {
                if (vm.$parent.type == "BP") {
                  vm.$parent.fetchdata();
                }
                vm.$store.dispatch("getbalance");
                var response = doc.data;
                var check = vm.checktype(response);
                if (check == "process") {
                  resolve({
                    title: vm.sync.title,
                    body: vm.sync.subtitle + " Recharge Under Processing",
                    config: {
                      closeOnClick: true,
                      type: "info"
                    }
                  });
                } else if (check == "failure") {
                  resolve({
                    title: vm.sync.title,
                    body: vm.sync.subtitle + " Recharge failure",
                    config: {
                      closeOnClick: true,
                      type: "error"
                    }
                  });
                } else if (check == "success") {
                  resolve({
                    title: vm.sync.title,
                    body: vm.sync.subtitle + " Recharge Success",
                    config: {
                      closeOnClick: true
                    }
                  });
                } else {
                  resolve({
                    title: vm.sync.title,
                    body: vm.sync.subtitle + " " + response,
                    config: {
                      closeOnClick: true,
                      type: "warning"
                    }
                  });
                }
                vm.$parent.sync = [];
              });
            /*.catch(function (error) {
                console.log(error)
                 reject({
                    title: vm.sync.title,
                    body: vm.sync.subtitle,
                    config: {
                      closeOnClick: true
                    }
                  })
                vm.$parent.sync = []                
            })
            */
          })
      );
    },
    cancel() {
      let vm = this;
      vm.$parent.preview = false;
    },
    checktype(response) {
      if (response.includes("Processed")) {
        return "process";
      }
      if (response.includes("fail")) {
        return "failure";
      }
      if (response.includes("Success")) {
        return "success";
      }
    }
  },
  mounted() {
    let vm = this;
    vm.summary = vm.data;
  }
};
</script>
