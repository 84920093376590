<template>
  <div>
    <v-dialog v-model="proccess" persistent width="400">
      <v-card
        style="background: #f0f0f0; padding-left: 15px; padding-right: 15px"
      >
        <v-card-title style="padding: 5px">
          Payment Collection
          <v-spacer />
          <v-btn @click="close" icon>
            <v-icon style="margin-top: 0px" color="red">close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            style="margin: 0 auto; height: 60px"
            v-model="bank"
            :items="banks"
            label="Select Bank"
            solo
          />
          <v-autocomplete
            style="margin: 0 auto; height: 60px"
            v-model="type"
            :items="types"
            label="Select Type"
            solo
          />
          <div v-if="check">
            <v-text-field
              type="text"
              v-model="bankname"
              style="margin: 0 auto; height: 60px"
              label="Enter Bank Name"
              solo
              v-if="dd"
            />
            <v-text-field
              type="text"
              v-model="bankbranch"
              style="margin: 0 auto; height: 60px"
              label="Enter Bank Branch"
              solo
            />
            <v-text-field
              v-if="dd"
              v-model="accountno"
              style="margin: 0 auto; height: 60px"
              label="Enter Account Number"
              solo
            />
            <v-text-field
              type="text"
              v-if="dd"
              v-model="tid"
              style="margin: 0 auto; height: 60px"
              label="Enter UTR/REF/TRANS"
              solo
            />
          </div>
          <v-text-field
            v-model="phone"
            style="margin: 0 auto; height: 60px"
            label="Enter Mobile Number"
            solo
          ></v-text-field>
          <v-text-field
            v-model="amount"
            style="margin: 0 auto; height: 60px"
            label="Enter Amount"
            solo
          />
          <v-text-field
            v-model="advance"
            style="margin: 0 auto; height: 60px"
            label="Enter Commission"
            solo
          />
          <v-text-field
            :disabled="true"
            v-model="advanceflat"
            style="margin: 0 auto; height: 60px"
            label="Enter Commission Flat"
            solo
          />
          <v-text-field
            :disabled="true"
            v-model="advanceamount"
            style="margin: 0 auto; height: 60px"
            label="Enter Advance Amount"
            solo
          />
          <v-text-field
            type="remark"
            v-model="remark"
            style="margin: 0 auto; height: 60px"
            label="Enter Remark"
            solo
          />
          <v-btn
            color="primary"
            style="width: 100%; margin: 0 auto; margin-bottom: 10px"
            @click="submit('c')"
            >Collection</v-btn
          >
          <v-btn
            color="primary"
            style="width: 100%; margin: 0 auto; margin-bottom: 10px"
            @click="submit('cw')"
            >Collection With Transfer</v-btn
          >
          <v-btn
            color="primary"
            style="width: 100%; margin: 0 auto"
            @click="submit('cp')"
            >Collect With Partial Transfer</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="view" persistent width="400">
      <v-card color="#01579B">
        <v-card-title style="height: 40px; color: white">
          <span class="body-2">Confirm Details</span>
        </v-card-title>
        <v-card-text style="padding-top: 0px">
          <v-layout row wrap v-for="(item, key) in summary" :key="key + item">
            <v-flex xs6>
              <v-card-text
                class="px-0 title"
                style="color: white; padding-top: 5px; padding-bottom: 8px"
                >{{ key }}</v-card-text
              >
            </v-flex>
            <v-flex xs6>
              <v-card-text
                class="px-0 title"
                style="color: white; padding-top: 5px; padding-bottom: 8px"
                >{{ item }}</v-card-text
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions style="width: 100%">
          <v-spacer></v-spacer>
          <v-btn
            small
            class="error"
            @click="
              proccess = true;
              view = false;
            "
            >Cancel</v-btn
          >
          <v-btn small class="success" @click="confirm">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="checkcp" persistent width="400">
      <v-card color="#01579B">
        <v-card-title style="height: 40px; color: white">
          <span class="body-2">Confirm Details</span>
        </v-card-title>
        <v-card-text style="padding-top: 0px">
          <v-text-field
            v-model="pbalance"
            style="margin: 0 auto; height: 60px"
            label="Enter Patial Transfer Amount"
            solo
          />
        </v-card-text>
        <v-card-actions style="width: 100%">
          <v-spacer></v-spacer>
          <v-btn
            small
            class="error"
            @click="
              proccess = true;
              checkcp = false;
            "
            >Cancel</v-btn
          >
          <v-btn small class="success" @click="confirmcp">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["vdata"],
  data() {
    return {
      proccess: true,
      progress: false,
      remark: "",
      check: false,
      phone: "",
      amount: "",
      tid: "",
      bankname: "",
      bankbranch: "",
      accountno: "",
      advance: "",
      advanceflat: "",
      advanceamount: "",
      bank: "",
      summary: [],
      dd: false,
      view: false,
      banks: [],
      type: "",
      childid: "",
      checkcp: "",
      childname: "",
      pbalance: "",
      types: [
        "Cash",
        "CashDeposit",
        "DD",
        "Cheque",
        "NEFT",
        "RTGS",
        "ATMTransfer",
        "MConnect",
        "IMPS",
        "Moneytransfer",
      ],
      submitway: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    clear() {
      return this.$store.getters.clear;
    },
    getbank() {
      return this.$store.getters.bank;
    },
  },
  watch: {
    type(value) {
      if (value == "Cash" || value == "CashDeposit" || value == "ATMTransfer") {
        this.check = false;
        this.dd = false;
      } else {
        this.check = true;
        this.dd = true;
        if (value == "DD") {
          this.dd = false;
        }
      }
    },
    getbank() {
      this.pushbank();
    },
    clear() {
      this.cleardata();
    },
    amount() {
      if (this.amount != "" && this.advance != "") {
        var per = this.advance / 100;
        per = per * this.amount;
        this.advanceflat = per;
        this.advanceamount = parseFloat(this.amount) + parseFloat(per);
      }
    },
    advance() {
      if (this.amount != "" && this.advance != "") {
        var per = this.advance / 100;
        per = per * this.amount;
        this.advanceflat = per;
        this.advanceamount = parseFloat(this.amount) + parseFloat(per);
      }
    },
  },
  methods: {
    cleardata() {
      let vm = this;
      vm.type = "";
      vm.phone = "";
      vm.amount = "";
      vm.advance = "";
      vm.remark = "";
    },

    submit(type) {
      let vm = this;
      vm.submitway = type;
      if (vm.user.length == 0)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Authentication Required!",
          type: "warn",
        });
      if (vm.bank == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Select Bank",
          type: "error",
        });
      if (vm.type == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Select Type",
          type: "error",
        });
      vm.summary = {
        Bank: vm.bank,
        Type: vm.type,
        Phone: vm.phone,
        Amount: vm.amount,
        Commission: vm.advance,
        "Commission Flat": vm.advanceflat,
        "Advance Amount": vm.advanceamount,
        Remark: vm.remark,
      };
      if (
        vm.type == "Cash" ||
        vm.type == "CashDeposit" ||
        vm.type == "ATMTransfer"
        // eslint-disable-next-line no-empty
      ) {
      } else {
        if (vm.type == "DD") {
          vm.summary["Bank Branch"] = vm.bankbranch;
          if (vm.bankbranch == "")
            return vm.$store.dispatch("notify", {
              vm: vm,
              message: "Enter Bank Branch",
              type: "error",
            });
        } else {
          if (vm.bankname == "")
            return vm.$store.dispatch("notify", {
              vm: vm,
              message: "Enter Bank Name ",
              type: "error",
            });
          if (vm.bankbranch == "")
            return vm.$store.dispatch("notify", {
              vm: vm,
              message: "Enter Bank Branch",
              type: "error",
            });
          if (vm.accountno == "")
            return vm.$store.dispatch("notify", {
              vm: vm,
              message: "Enter Account",
              type: "error",
            });
          if (vm.tid == "")
            return vm.$store.dispatch("notify", {
              vm: vm,
              message: "Enter UTR/REF/TRANF",
              type: "error",
            });
          vm.summary["Bank Name"] = vm.bankname;
          vm.summary["Bank Branch"] = vm.bankbranch;
          vm.summary["Account Number"] = vm.accountno;
          vm.summary["UTR/REF/TRANS"] = vm.tid;
        }
      }
      if (vm.phone == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Number Required",
          type: "error",
        });
      if (vm.phone.length != 10)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "10 Digit Number Required",
          type: "error",
        });
      if (vm.amount == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Amount Required",
          type: "error",
        });
      if (vm.advance == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Commission Required",
          type: "error",
        });
      vm.proccess = false;
      vm.view = true;
    },
    confirm() {
      let vm = this;
      vm.view = false;
      vm.$parent.progress = true;
      let url =
        vm.$root.loginType === "dmr"
          ? "PaymentCollectionDMR"
          : "PaymentCollection";
      let doc = [];
      if (vm.submitway == "c") {
        doc = {
          MobileNo: vm.user.phone,
          PinNo: vm.user.pin,
          ParentBankDetail: vm.bankname,
          ChildBankName: vm.bankname,
          ChildAccountNo: vm.accountno,
          TransferId: vm.tid,
          Paymenttype: vm.type,
          ChildId: vm.childid,
          ChildUsername: vm.childname,
          AmountWithCommission: vm.advanceamount,
          AdvanceCommision: vm.advance,
          Amounts: vm.amount,
          Remarks: vm.remark,
        };
      }
      if (vm.submitway == "cw") {
        url =
          vm.$root.loginType === "dmr"
            ? "PaymentCollectionWithTransferDMR"
            : "PaymentCollectionWithTransfer";
        doc = {
          MobileNo: vm.user.phone,
          PinNo: vm.user.pin,
          ParentBankDetail: vm.bankname,
          ChildBankName: vm.bankname,
          ChildAccountNo: vm.accountno,
          TransferId: vm.tid,
          Paymenttype: vm.type,
          ChildId: vm.childid,
          ChildUsername: vm.childname,
          AmountWithCommission: vm.advanceamount,
          AdvCommission: vm.advance,
          Amounts: vm.amount,
          flat: vm.advanceflat,
          Remarks: vm.remark,
        };
      }
      if (vm.submitway == "cp") {
        vm.checkcp = true;
        vm.pbalance = vm.advanceamount + vm.vdata.out;
        return;
      }
      vm.$store
        .dispatch("axios", {
          data: doc,
          url: url,
        })
        .then(function (res) {
          vm.$parent.progress = false;
          vm.$parent.res = res.data.Message;
          vm.$parent.message = true;
          vm.$parent.collection = false;
          vm.$store.commit("setclear", new Date().getTime());
          vm.$parent.fetchstatement();
        })
        .catch(function (error) {
          vm.$parent.progress = false;
          vm.$parent.collection = true;
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn",
          });
        });
    },
    confirmcp() {
      let vm = this;
      vm.checkcp = false;
      vm.$parent.progress = true;
      let url =
        vm.$root.loginType === "dmr"
          ? "PaymentCollectionWithPartialTransferDMR"
          : "PaymentCollectionWithPartialTransfer";
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        ParentBankDetail: vm.bankname,
        ChildBankName: vm.bankname,
        ChildAccountNo: vm.accountno,
        TransferId: vm.tid,
        Paymenttype: vm.type,
        ChildId: vm.childid,
        ChildUsername: vm.childname,
        AmountWithCommission: vm.advanceamount,
        AdvCommission: vm.advance,
        Amounts: vm.amount,
        flat: vm.advanceflat,
        Remarks: vm.remark,
        PartialBalanceTransfer: vm.pbalance,
      };
      vm.$store
        .dispatch("axios", {
          data: doc,
          url: url,
        })
        .then(function (res) {
          vm.$parent.progress = false;
          vm.$parent.res = res.data.Message;
          vm.$parent.message = true;
          vm.$parent.collection = false;
          vm.$store.commit("setclear", new Date().getTime());
          vm.$parent.fetchstatement();
        })
        .catch(function (error) {
          vm.$parent.progress = false;
          vm.$parent.collection = true;
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn",
          });
        });
    },
    close() {
      let vm = this;
      vm.$parent.collection = false;
    },
    pushbank() {
      this.banks = [];
      for (let i = 0; i < this.getbank.length; i++) {
        this.banks.push(this.getbank[i].CommonBankDetails);
      }
    },
  },
  mounted() {
    let vm = this;
    vm.pushbank();
    vm.childid = vm.vdata.cid;
    vm.childname = vm.vdata.child;
  },
  components: {},
};
</script>
