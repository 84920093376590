import { render, staticRenderFns } from "./IMPS.vue?vue&type=template&id=422e095b&"
import script from "./IMPS.vue?vue&type=script&lang=js&"
export * from "./IMPS.vue?vue&type=script&lang=js&"
import style0 from "./IMPS.vue?vue&type=style&index=0&id=422e095b&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports