import Vue from "vue";
import Router from "vue-router";
import Billpay from "./pages/Billpay.vue";
import Transaction from "./pages/Transaction.vue";
import Report from "./pages/Report.vue";
import Complaint from "./pages/Complaint.vue";
import Request from "./pages/Request.vue";
import Setting from "./pages/Setting.vue";
import Support from "./pages/Support.vue";
import Account from "./pages/Login.vue";
import Offer from "./pages/Offer.vue";
import Commission from "./pages/Commission.vue";
import Downline from "./pages/Downline.vue";
import Recharge from "./pages/Recharge.vue";
import LastTransaction from "./pages/LastTransaction.vue";
import Outstanding from "./pages/Outstanding.vue";
import Dashboard from "./pages/Dashboard.vue";
import DMRHome from "./pages/DMRHome";
import MicroAtmReport from "./pages/MicroAtmReport.vue";
import AEPS2 from "./pages/AEPS2.vue";

import DMR1 from "./pages/DMR1.vue";
import DMR2 from "./pages/DMR2.vue";
import DMR3 from "./pages/DMR3.vue";

import DMRPS from "./pages/DMRPS.vue"

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/recharge",
      name: "billpay",
      component: Billpay,
    },
    {
      path: "/",
      name: "Dashboard",
      component: Dashboard,
    },
    {
      path: "/downline",
      name: "downline",
      component: Downline,
    },
    {
      path: "/inquiry",
      name: "inquiry",
      component: Recharge,
    },
    {
      path: "/transaction",
      name: "transaction",
      component: Transaction,
    },
    {
      path: "/lasttransaction",
      name: "lasttransaction",
      component: LastTransaction,
    },
    {
      path: "/report",
      name: "report",
      component: Report,
    },
    {
      path: "/complaint",
      name: "complaint",
      component: Complaint,
    },
    {
      path: "/request",
      name: "request",
      component: Request,
    },
    // {
    //   path: "/offer",
    //   name: "offer",
    //   component: Offer,
    // },
    {
      path: "/commission",
      name: "commission",
      component: Commission,
    },
    {
      path: "/setting",
      name: "setting",
      component: Setting,
    },
    {
      path: "/support",
      name: "support",
      component: Support,
    },
    {
      path: "/account",
      name: "account",
      component: Account,
    },
    {
      path: "/outstanding",
      name: "outstanding",
      component: Outstanding,
    },
    {
      path: "/billutility",
      name: "billutility",
      component: DMRHome,
    },
    {
      path: "/dmr1",
      name: "dmr1",
      component: DMR1,
    },
    {
      path: "/dmr2",
      name: "dmr2",
      component: DMR2,
    },
    {
      path: "/dmr3",
      name: "dmr3",
      component: DMR3,
    },
    {
      path: "/dmrps",
      name: "dmrps",
      component: DMRPS,
    },
    {
      path: "/aeps-2",
      name: "aeps-2",
      component: AEPS2,
    },
    {
      path: "/micro-atm-report",
      name: "micro-atm-report",
      component: MicroAtmReport,
    },
  ],
});
