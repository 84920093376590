<template>
  <div>
    <Validate v-if="!isValidated" @onVerify="initDetails"></Validate>
    <div v-else>
      <v-container fluid>
        <v-layout row>
          <v-flex sm6 style="margin-right: 10px">
            <div>
              <v-toolbar dense>
                <v-toolbar-title>Beneficiary List</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items></v-toolbar-items>
              </v-toolbar>
              <v-card
                v-for="(item, i) in benifylist"
                :key="i"
                style="margin-top: 5px"
              >
                <v-card-title style="padding-bottom: 0px">
                  <div>
                    <div>Name: {{ item.name }}</div>
                    <div>Bank: {{ item.bank }}</div>
                    <div>Acc No: {{ item.account }}</div>
                    <div>IFSC Code: {{ item.ifsc }}</div>
                  </div>
                </v-card-title>
                <v-card-actions>
                  <v-btn
                    flat
                    color="success"
                    @click="
                      currentb = item;
                      transfer = true;
                    "
                    >Transfer</v-btn
                  >
                  <v-btn flat color="info" @click="validate(item)">{{
                    item.status === "1" ? "Revalidate" : "Validate"
                  }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn flat color="error" @click="remove(item)">remove</v-btn>
                </v-card-actions>
              </v-card>
            </div>
          </v-flex>
          <v-flex sm6 style="margin-left: 10px">
            <v-toolbar dense>
              <v-toolbar-title>Add Beneficiary</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items></v-toolbar-items>
            </v-toolbar>
            <div>
              <v-text-field
                type="text"
                style="
                  width: 300px;
                  margin: 0 auto;
                  height: 60px;
                  margin-top: 20px;
                "
                label="Beneficiary Name"
                v-model="bname"
                solo
              />
              <v-text-field
                style="width: 300px; margin: 0 auto; height: 60px"
                label="Beneficiary Mobile"
                v-if="false"
                v-model="bmobile"
                solo
              />
              <v-text-field
                style="width: 300px; margin: 0 auto; height: 60px"
                label="Beneficiary Account Number"
                v-model="baccount"
                solo
              />
              <v-autocomplete
                style="width: 300px; margin: 0 auto; height: 60px"
                :items="banklist"
                item-text="bank_name"
                item-value="bank_name"
                label="Bank Name"
                v-model="bank"
                @change="onBankChange"
                solo
              />
              <v-text-field
                type="text"
                style="width: 300px; margin: 0 auto; height: 60px"
                label="IFSC Code"
                v-model="bifsccode"
                solo
              />
              <!-- <v-text-field
                type="text"
                style="width:300px;margin:0 auto;height:60px;"
                label="Beneficiary Name"
                v-model="bbname"
                solo
              />-->
              <v-text-field
                type="text"
                style="
                  width: 300px;
                  margin: 0 auto;
                  height: 60px;
                  display: none;
                "
                label="Branch Name"
                v-model="bbranch"
                solo
              />
              <div style="width: 300px; margin: 0 auto; height: 60px">
                <v-btn
                  color="primary"
                  @click="addbeneficiary"
                  style="width: 300px; margin-left: 0px"
                  >Add Beneficiary</v-btn
                >
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <v-dialog v-model="transfer" persistent width="400">
      <v-card>
        <v-card-title>Transfer Money</v-card-title>
        <v-divider></v-divider>
        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <div>Name: {{ currentb.name }}</div>
            <div>Bank: {{ currentb.bank }}</div>
            <div>Acc No: {{ currentb.account }}</div>
            <div>IFSC Code: {{ currentb.ifsc }}</div>
          </div>
        </v-card-title>
        <v-layout row wrap>
          <v-flex sm6>
            <v-radio-group style="margin-left: 20px" row v-model="type">
              <v-radio label="IMPS" value="IMPS"></v-radio>
              <v-radio label="NEFT" value="NEFT"></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex sm6>
            <v-text-field            
            @change="numbertoword"
              style="height: 60px; width: 180px"
              label="Amount"
              v-model="amount"
              solo
            />
          </v-flex>
          
        </v-layout>
        <v-text-field
          class="mx-3"
          v-if="dmrmessage != ''"
          type="password"
          label="Pin No"
          v-model="pinno"
          solo
        />
        <v-card-title>
          <div>
            <div style="color: red">{{ dmrmessage }}</div>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-btn flat color="success" v-if="dmrmessage !== ''" @click="submit"
            >Confirm and pay</v-btn
          >
          <v-btn flat color="info" v-else @click="dmrcharge">Transfer</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            flat
            color="error"
            @click="
              transfer = false;
              amount = '';
              dmrmessage = '';
              currentb = {};
            "
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="otpcheck" persistent width="300" height="300">
      <v-card>
        <v-card-title>Enter OTP</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            style="width: 200px; margin: 0 auto; height: 60px"
            label="OTP"
            v-model="otp"
            solo
          />
          <v-btn
            color="primary"
            @click="verifyotp"
            style="width: 200px; margin-left: 35px"
            >Submit</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="paymentPop" persistent max-width="700" scrollable>
      <v-card>
        <v-card-title class="title">Payment processing list</v-card-title>
        <v-divider></v-divider>
        <v-card-text class>
          <v-layout row>
            <div class="subheading">Total DMR Requested = Rs.{{ amount }}</div>
            <v-spacer />
            <div class="subheading">
              DMR Opening Balance = Rs.{{ openingBalance }}
            </div>
          </v-layout>
          <v-data-table
            :headers="headers"
            :items="transferList"
            hide-actions
            class="payment__table"
          >
            <template slot="items" slot-scope="props">
              <td>Rs.{{ props.item.amount }}</td>
              <td>Rs.{{ props.item.charge }}</td>
              <td>{{ props.item.status }}</td>
              <td>{{ props.item.bank }}</td>
              <td>{{ props.item.utr }}</td>
              <td>{{ props.item.closing }}</td>
              <td class="text-sm-center">
                <v-icon color="blue" v-if="props.item.status === '-'"
                  >autorenew</v-icon
                >
                <v-icon color="red" v-if="props.item.status === 'fail'"
                  >highlight_off</v-icon
                >
                <v-icon color="green" v-if="props.item.status === 'success'"
                  >check_circle</v-icon
                >
                <v-progress-circular
                  indeterminate
                  size="30"
                  v-if="props.item.status === 'process'"
                  color="green"
                ></v-progress-circular>
              </td>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-layout row class="mt-2">
            <div class="subheading">Total DMR Charge Rs.{{ totalDMR }}</div>
            <v-spacer />
            <div class="subheading">Total DMR Charge Rs.{{ balance }}</div>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="success" depressed @click="printReceipt()">Print</v-btn>
          <v-spacer />
          <v-btn
            color="green"
            v-if="startPayment === null"
            dark
            depressed
            @click="startIMPS()"
            >Start</v-btn
          >
          <v-btn
            color="yellow"
            v-if="startPayment === 'start'"
            dark
            depressed
            @click="startPayment = 'stop'"
            >Stop</v-btn
          >
          <v-btn
            color="blue"
            v-if="startPayment === 'stop'"
            @click="gotoTransaction()"
            dark
            depressed
            >Transaction</v-btn
          >
          <v-btn color="red" dark depressed @click="cancelTransfer()"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Loader v-if="loader"></Loader>
    <Print :summary="summary" :list="filterTransferList" />
  </div>
</template>
<script>
import Loader from "../components/Progress.vue";
import Validate from "./Validate.vue";
import Print from "./Print";
import moment from "moment";
export default {
  data() {
    return {
      isValidated: false,
      loader: false,
      transfer: false,
      otpcheck: false,
      paymentPop: false,
      type: "IMPS",
      otp: "",
      benifylist: [],
      banklist: [],
      bname: "",
      bank: {},
      bmobile: "",
      baccount: "",
      bifsccode: "",
      bbname: "",
      bbranch: "",
      currentb: {},
      amount: "",
      dmrmessage: "",
      bid: "",
      startPayment: null,
      transferList: [],
      isPaytm: false,
      charge: 0,
      pinno: "",
      start: false,
      viewstatus: false,
      openingBalance: "",
      headers: [
        { text: "DMR Amount", value: "", sortable: false },
        { text: "DMR Charge", value: "", sortable: false },
        { text: "Status", value: "", sortable: false },
        { text: "Bank", value: "", sortable: false },
        { text: "UTR No", value: "", sortable: false },
        { text: "Closing", value: "", sortable: false },
        { text: "Progress", value: "", sortable: false, align: "center" },
      ],
      viewstatus: false,
      summary: {},
      filterTransferList: [],
    };
  },

  filters: {
  toWords: function (value) {
    alert(value);
    if (!value) return ''
    return converter.toWords(value);
  }
},
  computed: {
    user() {
      return this.$store.getters.user;
    },
    security() {
      return this.$store.getters.security;
    },
    dmr() {
      return this.$store.getters.dmr;
    },
    balance() {
      return this.$store.getters.balance;
    },
    totalDMR() {
      let vm = this;
      let amt = 0;
      vm.transferList.forEach((item) => (amt += item.charge));
      return amt;
    },
  },
  watch: {
    amount() {
      this.dmrmessage = "";
    },
  },
  methods: {
    initDetails(beneficiary) {
      this.isValidated = true;
      this.fetchbank();
      this.benifylist = beneficiary;
    },
    onBankChange() {
      let bank = this.banklist.find((item) => item.bank_name == this.bank);
      this.bifsccode = "";
      if (bank != null) {
        this.bifsccode = bank.branch_ifsc;
      }
      console.log(this.bifsccode);
    },
    numbertoword() {
      debugger
      // let bank = this.banklist.find((item) => item.bank_name == this.bank);
      // this.bifsccode = "";
      // if (bank != null) {
      //   this.bifsccode = bank.branch_ifsc;
      // }
      // console.log(this.bifsccode);
      alert("work");
    },
    gotoTransaction() {
      const vm = this;
      vm.paymentPop = false;
      vm.amount = "";
      vm.startPayment = null;
      vm.transferList = [];
      vm.$parent.way = "report";
    },
    cancelTransfer() {
      let vm = this;
      vm.paymentPop = false;
      vm.amount = "";
      vm.startPayment = null;
      vm.transferList = [];
    },
    paytmRoot(doc) {
      return new Promise((resolve, reject) => {
        const vm = this;
        vm.$store
          .dispatch("axiosdmrpost", {
            url: "PaytmRoot",
            data: doc,
          })
          .then((res) => {
            let payment = res.data.Paytmroot;
            resolve(payment);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    paytmLimit(doc) {
      return new Promise((resolve, reject) => {
        const vm = this;
        vm.$store
          .dispatch("axiosdmrpost", {
            url: "PaytmMothalyLimit",
            data: doc,
          })
          .then((res) => {
            if (res.data.status === "True") {
              let limit = res.data.Paytmlimit.Availblelimit;
              resolve(limit);
            } else {
              reject(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    impsStatus(doc) {
      return new Promise((resolve, reject) => {
        const vm = this;
        vm.$store
          .dispatch("axiosdmrpost", {
            url: "BankIMPS",
            data: doc,
          })
          .then((res) => {
            if (res.data.status === "True") {
              resolve(res.data.IMPS);
            } else {
              reject(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    sendMoney(doc) {
      return new Promise((resolve, reject) => {
        const vm = this;
        const url = vm.isPaytm ? "SendMoneyPaytm" : "SendMoney";
        vm.$store
          .dispatch("axiosdmrpost", {
            url: url,
            type: "instapay",
            data: doc,
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async submit() {
      let vm = this;
      if (!vm.pinno) {
        return vm.notification("warn", "Please enter the pin");
      }
      if (vm.pinno != vm.user.pin) {
        return vm.notification("warn", "Your pin is wrong");
      }
      if (vm.amount === "") return vm.notification("warn", "Amount Required");
      if (vm.user == null || !vm.user.phone)
        return vm.notification("error", "Login Required!");
      if (vm.amount <= 0)
        return vm.notification("error", "Amount must be above 0");
      if (vm.balance < vm.charge) {
        vm.notification("error", "Insufficiant Balance");
        return;
      }
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        Amount: vm.amount,
        BankName: vm.currentb.bank,
        SenderMobileNo: vm.dmr.mobile_no,
      };
      vm.loader = true;
      vm.openingBalance = vm.balance;
      vm.startPayment = null;
      try {
        // const payment = await vm.paytmRoot(doc);
        // if (payment === true) {
        //   vm.isPaytm = true;
        //   const limit = await vm.paytmLimit(doc);
        //   if (limit < vm.charge) {
        //     let message = "Remaining Limit is lower than Transfer Amount. \n";
        //     message +=
        //       "Your current limit on " + vm.dmr.mobile_no + " is " + limit;
        //     vm.$root.alert(message);
        //     vm.loader = false;
        //   } else {
        //     const status = await vm.impsStatus(doc);
        //     if (status === true) {
        //       vm.transferList = [];
        //       vm.transferList = await vm.transList();
        //       vm.loader = false;
        //       vm.dmrmessage = "";
        //       vm.transfer = false;
        //       vm.paymentPop = true;
        //     } else {
        //       vm.notification("error", "IMPS down try after sometime");
        //       vm.loader = false;
        //     }
        //   }
        // } else {
        vm.isPaytm = false;
        // const status = await vm.impsStatus(doc);
        // if (status === true) {
        if (vm.dmr.remaininglimit < vm.charge) {
          let message = "Remaining Limit is lower than Transfer Amount. \n";
          message +=
            "Your current limit on " +
            vm.dmr.mobile_no +
            " is " +
            vm.dmr.remaininglimit;
          vm.$root.alert(message);
          vm.loader = false;
        } else {
          vm.transferList = [];
          vm.transferList = await vm.transList();
          vm.loader = false;
          vm.dmrmessage = "";
          vm.transfer = false;
          vm.paymentPop = true;
        }
        // } else {
        //   vm.loader = false;
        //   vm.notification("error", "IMPS down try after sometime");
        // }
        vm.loader = false;
        // }
      } catch (e) {
        vm.notification("error", e.message);
        vm.loader = false;
      }
    },
    async startIMPS() {
      let vm = this;
      vm.startPayment = "start";
      let error = false;
      for (var i = 0; i < vm.transferList.length; i++) {
        let paymentDoc = {
          MobileNo: vm.user.phone,
          PinNo: vm.user.pin,
          Amount: vm.transferList[i].amount,
          pinnumber: vm.user.pin,
          sndrmobile: vm.dmr.mobile_no,
          servicename: vm.type,
          benid: vm.currentb.id,
          benmobile: vm.currentb.mobile,
          benname: vm.currentb.name,
          benbankname: vm.currentb.bank,
          benaccountno: vm.currentb.account,
          ifsc: vm.currentb.ifsc,
        };
        if (vm.startPayment === "start") {
          vm.transferList[i].status = "process";
          try {
            if (!error) {
              let res = await vm.sendMoney(paymentDoc);
              vm.$store.dispatch("getbalance");
              vm.fetchbenify();
              if (res.Status === "True") {
                vm.transferList[i].status = "success";
                vm.transferList[i].utr = res.CompanyData.data.ipay_id;
              } else {
                error = true;
                vm.transferList[i].status = "fail";
                vm.transferList[i].utr = res.message;
              }
            } else {
              vm.transferList[i].status = "fail";
            }
          } catch (e) {
            error = true;
            vm.transferList[i].utr = e.message;
            vm.transferList[i].status = "fail";
          }
        }
      }
      vm.startPayment = "stop";
    },
    async transList() {
      const vm = this;
      let list = [];
      let total = vm.amount;
      let limit = vm.dmr.perm_txn_limit;
      let closing = vm.balance;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        Amount: limit,
      };
      if (total > limit) {
        let reminder = total % limit;
        let subtotal = total - reminder;
        let count = subtotal / limit;
        let dmrcharge = await vm.getDMRCharge(doc);
        let charge = dmrcharge - limit;
        for (var i = 0; i < count; i++) {
          closing -= limit;
          closing -= charge;
          list.push({
            amount: limit,
            status: "-",
            message: "",
            charge: charge,
            bank: vm.currentb.account,
            utr: "",
            closing: closing,
          });
        }
        if (reminder !== 0) {
          doc.Amount = reminder;
          dmrcharge = await vm.getDMRCharge(doc);
          charge = dmrcharge - reminder;
          closing -= reminder;
          closing -= charge;
          list.push({
            amount: reminder,
            status: "-",
            message: "",
            charge: charge,
            bank: vm.currentb.account,
            utr: "",
            closing: closing,
          });
        }
      } else {
        doc.Amount = total;
        let dmrcharge = await vm.getDMRCharge(doc);
        let charge = dmrcharge - total;
        closing -= total;
        closing -= charge;
        list.push({
          amount: total,
          status: "-",
          message: "",
          charge: charge,
          bank: vm.currentb.account,
          utr: "",
          closing: closing,
        });
      }
      return list;
    },
    getDMRCharge(doc) {
      return new Promise((resolve, reject) => {
        const vm = this;
        vm.$store
          .dispatch("axiosdmrpost", {
            url: "DMRCharges",
            data: doc,
          })
          .then((res) => {
            let charge = parseFloat(res.data.DMRCharge.split("Total Rs.")[1]);
            resolve(charge);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    dmrcharge(amount) {
      let vm = this;
      if (vm.amount === "") return vm.notification("warn", "Amount Required");
      if (vm.amount <= 0)
        return vm.notification("error", "Amount must be above 0");
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        Amount: vm.amount,
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "DMRCharges",
          type: "instapay",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          vm.dmrmessage = res.data.Message;
          vm.charge = parseFloat(res.data.DMRCharge.split("Total Rs.")[1]);
        })
        .catch(function (error) {
          vm.loader = false;
          vm.notification("error", error);
        });
    },

    addbeneficiary() {
      let vm = this;
      if (vm.bname === "")
        return vm.notification("warn", "Beneficiary Name Required");
      // if (vm.bmobile === "")
      //   return vm.notification("warn", "Beneficiary Mobile Required");
      // if (vm.bmobile.length !== 10)
      //   return vm.notification("warn", "Enter Valid Mobile");
      if (vm.baccount === "")
        return vm.notification("warn", "Beneficiary Account Required");
      if (vm.bifsccode === "")
        return vm.notification("warn", "Beneficiary IFSC Code Required");
      // if (vm.bbname === "")
      //   return vm.notification("warn", "Beneficiary Name Required");
      //    if(vm.bbranch === "") return vm.notification("warn","Beneficiary Branch Required")
      let split = vm.bname.split(" ");
      let lastname = split.length > 1 ? split[1] : "";
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        RemId: vm.dmr.id,
        FirstName: split[0],
        LastName: lastname,
        AccountNumber: vm.baccount,
        ifsc: vm.bifsccode,
        benmobileno: vm.bmobile,
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "AddRecipient",
          type: "instapay",
          data: doc,
        })
        .then(function (res) {
          vm.fetchbenify();
          vm.loader = false;
          let data = res.data;
          vm.bname = "";
          vm.bank = {};
          vm.bmobile = "";
          vm.baccount = "";
          vm.bifsccode = "";
          vm.bbname = "";
          vm.bbranch = "";
          if (data.Status === "True") {
            vm.notification("success", "Beneficiary added");
            return;
          }
          vm.notification("error", data.Message);
        })
        .catch(function (err) {
          vm.loader = false;
          vm.notification("error", err.message);
        });
    },
    print() {},
    verifyotp() {
      let vm = this;
      if (vm.otp === "") return vm.notification("warn", " OTP Required");
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        remid: vm.dmr.id,
        benid: vm.currentb.id,
        OTP: vm.otp,
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "DeleteValidateBeneficiary",
          type: "instapay",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          let data = res.data;
          if (data.Status === "False") {
            vm.notification("error", data.Message);
            return;
          }
          vm.otpcheck = false;
          vm.notification("success", data.Message);
          vm.otp = "";
          vm.fetchbenify();
        })
        .catch(function (err) {
          vm.loader = false;
          vm.notification("error", err.message);
        });
    },
    fetchbenify() {
      let vm = this;
      let doc = {
        MobileNo: vm.user.phone,
        pinNo: vm.user.pin,
        mobilenumber: vm.dmr.mobile_no,
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "ValidateCustomer",
          type: "instapay",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          let data = res.data;
          if (data.Status === "True") {
            let { beneficiary, remitter } = data.CompanyData.data;
            vm.benifylist = beneficiary;
            let user = { ...vm.$store.getters.dmr };
            user.remaininglimit = remitter.remaininglimit;
            user.consumedLimit = remitter.consumedlimit;
            vm.$store.commit("setdmr", user);
          }
        })
        .catch(function (err) {
          vm.loader = false;
          vm.notification("error", err.message);
        });
    },
    fetchbank() {
      let vm = this;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
      };
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "Banklist",
          type: "instapay",
          data: doc,
        })
        .then(function (res) {
          vm.banklist = res.data.CompanyData;
        })
        .catch(function (err) {
          vm.notification("error", err.message);
        });
    },
    remove(item) {
      let vm = this;
      vm.currentb = item;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        remid: vm.dmr.id,
        benid: item.id,
      };
      let check = confirm("Are you sure?");
      if (!check) return;
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "DeleteBeneficiary",
          type: "instapay",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          let data = res.data;
          if (data.Status === "True") {
            vm.otpcheck = true;
            vm.bid = item.id;
            vm.notification("success", data.Message);
            return;
          }
          vm.notification("error", data.Message);
        })
        .catch(function (err) {
          vm.loader = false;
          vm.notification("error", err.message);
        });
    },
    validate(item) {
      let vm = this;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        sndrMobileNo: vm.dmr.mobile_no,
        BenName: item.name,
        RecipientId: item.id,
        BenAccountNo: item.account,
        BenIFSC: item.ifsc,
        benbankname: item.bank,
        benmobileno: item.mobile,
        pinnno: vm.user.pin,
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "validatebeneficiary",
          type: "instapay",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          let data = res.data;
          vm.$store.dispatch("getbalance");
          let message = data.Message;
          vm.$root.alert(message);
        })
        .catch(function (err) {
          vm.loader = false;
          vm.notification("error", err.message);
        });
    },
    notification(type, message) {
      this.$notify({
        group: "foo",
        title: "Important message",
        text: message,
        duration: 5000,
        type: type,
      });
    },
    async printReceipt() {
      const vm = this;
      let doc = {
        MobileNo: vm.user.phone,
        pinNo: vm.user.pin,
        StartDate: moment().format("YYYY-MM-DD"),
        EndDate: moment().format("YYYY-MM-DD"),
        DMRGateway: "All",
      };
      try {
        let { Data } = (
          await vm.$store.dispatch("axios", {
            url: "DMRTransactionListByDateIdandGateWay",
            data: doc,
          })
        ).data;
        let pos = 0;
        let mobile = "";
        let account = "";
        let statementlist = [];
        Data = Data.reverse();
        for (let i = 0; i < Data.length; i++) {
          let doc = Data[i];
          let time = doc.CreatedDate.replace("/Date(", "").replace(")/", "");
          if (mobile != doc.MobileNo || account != doc.accountno) {
            pos++;
          }
          mobile = doc.MobileNo;
          account = doc.accountno;
          statementlist.push({
            amount: doc.Amount,
            orderid: doc.RechargeId,
            mobile: doc.MobileNo,
            time: time,
            pos: pos,
            name: doc.recipient_name,
            remark: doc.DMRResponse,
            sender: doc.CreateByUserName,
            account: doc.accountno,
            status: doc.TransactionStatus,
            bank_txtid: doc.BankRefrenceNo,
            user_name: doc.CreateByUserName,
            bank_name: doc.bankName,
            service: doc.ServiceName,
            bank_ifsc: doc.ifsc,
            tid: doc.TransactionId,
            mobile_no: doc.recipient_mobile,
          });
        }
        console.log(statementlist);
        vm.filterTransferList = statementlist.filter((item) => item.pos == 1);
        console.log(vm.filterTransferList);
        vm.summary = vm.filterTransferList[0] || {};
        vm.dialog = false;
        vm.viewstatus = true;
      } catch (error) {
        console.log(error);
        vm.$root.alert(error.message);
      }
    },
  },
  async mounted() {
    let vm = this;
  },
  components: { Loader, Validate, Print },
};
</script>

<style>
.payment__table .v-table__overflow {
  width: 670px;
}
</style>