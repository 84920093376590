<template>
  <div>
    <v-card style="width: 100%; padding: 10px">
      <v-layout row wrap style="height: 40px">
        <v-flex xs12 style="padding-top: 8px">
          <v-btn class="success searchbtn" @click="fetchstatement"
            >Search</v-btn
          >
          <v-menu
            :close-on-content-click="false"
            v-model="menu2"
            lazy
            transition="scale-transition"
            offset-y
            style="float: right"
          >
            <input
              type="text"
              slot="activator"
              v-model="date2"
              readonly
              class="search"
              placeholder="To Date"
            />
            <v-date-picker
              v-model="date2"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            :close-on-content-click="false"
            v-model="menu1"
            lazy
            transition="scale-transition"
            offset-y
            style="float: right"
          >
            <input
              type="text"
              slot="activator"
              v-model="date1"
              readonly
              class="search"
              placeholder="From Date"
            />
            <v-date-picker
              v-model="date1"
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
          <input
            class="search"
            style="float: left"
            v-model="search"
            placeholder="Search"
          />
        </v-flex>
        <v-layout row wrap>
          <v-spacer></v-spacer>
        </v-layout>
      </v-layout>
    </v-card>
    <v-data-table :headers="headers" :items="statementlist" :search="search">
      <template slot="items" slot-scope="props">
        <td>
          <v-btn
            v-if="isPrintable(props.item.status)"
            @click="getsummary(props.item)"
            small
            color="success"
            >Print</v-btn
          >
        </td>
        <td>{{ props.item.orderid }}</td>
        <td>{{ props.item.name }}</td>
        <td>{{ props.item.sender }}</td>
        <td>{{ props.item.mobile }}</td>
        <td>{{ props.item.bank_name }}</td>
        <td>{{ props.item.account }}</td>
        <td>{{ props.item.bank_txtid }}</td>
        <td>{{ props.item.amount }}</td>
        <td>
          <v-btn small :class="checkstatus(props.item.status)">{{
            getStatus(props.item.status)
          }}</v-btn>
        </td>
        <td>{{ props.item.tid }}</td>
        <td>
          {{
            new Date(parseInt(props.item.time))
              | moment("DD-MM-YYYY, h:mm:ss a")
          }}
        </td>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please wait
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <Print :summary="summary" :list="filterByPos(summary.pos)" />
  </div>
</template>

<script>
import moment from "moment";
import Print from "./Print";
export default {
  components: { Print },
  data() {
    return {
      modal: false,
      date1: moment().format("YYYY-MM-DD"),
      menu1: false,
      date2: moment().format("YYYY-MM-DD"),
      menu2: false,
      mobile: "",
      headers: [
        { text: "Action", value: "action" },
        { text: "Order No", value: "orderno", align: "left" },
        { text: "Name", value: "name" },
        { text: "Sender Name", value: "sender" },
        { text: "MobileNo", value: "mobile" },
        { text: "Bank Name", value: "bankName" },
        { text: "Account No", value: "account" },
        { text: "Bank Transaction ID", value: "bank_txtid" },
        { text: "Amount", value: "amount" },
        { text: "Status", value: "status" },
        { text: "TransactionID", value: "transactionid" },
        { text: "Recharge Time", value: "time" },
      ],
      dialog: false,
      viewstatus: false,
      summary: {},
      statementlist: [],
      modal: false,
      search: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    dmr() {
      return this.$store.getters.dmr;
    },
  },
  watch: {
    dmr(val) {
      if (val != null) {
        this.mobile = val.mobile_no;
        this.fetchstatement();
      }
    },
  },
  methods: {
    isPrintable(status) {
      return status == 5 || status == 7;
    },
    checkstatus(status) {
      if (status == 7) return "success";
      if (status == 5) return "grey";
      if (status == 3 && status == 4) return "warning";
      if (status == 6) return "error";
      return "primary";
    },
    getStatus(status) {
      if (status == 7) return "Success";
      if (status == 5) return "Response Awaited/Initiated";
      if (status == 3) return "Refund Pending";
      if (status == 4) return "Refunded";
      if (status == 6) return "Fail";
      return "-";
    },
    notification(type, message) {
      this.$notify({
        group: "foo",
        title: "Important message",
        text: message,
        duration: 5000,
        type: type,
      });
    },
    filterByPos(pos) {
      const vm = this;
      let list = [];
      vm.statementlist.forEach((item) => {
        if (item.pos === pos && vm.isPrintable(item.status)) {
          list.push(item);
        }
      });
      return list;
    },
    pushstatement(value) {
      let vm = this;
      vm.statementlist = [];
      let pos = 0;
      let mobile = "";
      let account = "";
      for (let i = 0; i < value.length; i++) {
        let doc = value[i];
        let time = doc.CreatedDate.replace("/Date(", "").replace(")/", "");
        if (mobile != doc.MobileNo || account != doc.accountno) {
          pos++;
        }
        mobile = doc.MobileNo;
        account = doc.accountno;
        vm.statementlist.push({
          amount: doc.Amount,
          orderid: doc.RechargeId,
          mobile: doc.MobileNo,
          time: time,
          pos: pos,
          name: doc.recipient_name,
          remark: doc.DMRResponse,
          sender: doc.CreateByUserName,
          account: doc.accountno,
          status: doc.TransactionStatus,
          bank_txtid: doc.BankRefrenceNo,
          user_name: doc.CreateByUserName,
          bank_name: doc.bankName,
          service: doc.ServiceName,
          bank_ifsc: doc.ifsc,
          tid: doc.TransactionId,
          mobile_no: doc.recipient_mobile,
        });
      }
    },
    getsummary(doc) {
      let vm = this;
      vm.viewstatus = true;
      vm.summary = doc;
      console.log(doc);
      // vm.summary = {
      //   "Order No": doc.orderid,
      //   "Name ": doc.name,
      //   Mobile: doc.mobile,
      //   Amount: doc.amount,
      //   Status: doc.status,
      //   Time: doc.time,
      //   "Bank Transaction Id": doc.bank_txtid,
      //   "Transaction Id": doc.tid
      // };
    },
    fetchAll() {
      let vm = this;
      if (!vm.user.phone)
        return vm.notification("warn", "Authentication Required!");
      let doc = {
        MobileNo: vm.user.phone,
        pinNo: vm.user.pin,
        SenderMobileNo: vm.mobile,
        StartDate: vm.date1,
        EndDate: vm.date2,
        DMRGateway: "All",
      };
      vm.dialog = true;
      vm.$store
        .dispatch("axios", {
          url: "DMRTransactionListByDateIdandGateWay",
          data: doc,
        })
        .then(function ({ data }) {
          vm.dialog = false;
          if (data.Data) {
            vm.pushstatement(data.Data);
          }
        })
        .catch(function (error) {
          vm.notification("error", error.message);
          vm.dialog = false;
        });
    },
    fetchstatement() {
      let vm = this;
      if (!vm.user.phone)
        return vm.notification("warn", "Authentication Required!");
      if (vm.mobile === "") return vm.fetchAll();
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        SenderMobileno: vm.mobile,
      };
      vm.dialog = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "TransactionList",
          type: "dmr",
          data: doc,
        })
        .then(function ({ data }) {
          vm.dialog = false;
          if (data.data) {
            vm.pushstatement(data.data);
          }
        })
        .catch(function (error) {
          vm.notification("error", error.message);
          vm.dialog = false;
        });
    },
  },
  mounted() {
    let vm = this;
    if (vm.dmr !== null) {
      vm.mobile = vm.dmr.mobile_no;
    }
    vm.fetchstatement();
  },
};
</script>

<style scoped>
.search {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  border: 1px solid lightgray;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
