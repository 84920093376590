<template>
  <div>
    <v-tabs
      next-icon="arrow_forward_ios"
      prev-icon="arrow_back_ios"
      show-arrows
    >
      <v-tabs-slider></v-tabs-slider>
      <template v-for="(tab, i) in tabs">
        <v-tab
          class="primary--text"
          v-if="check(tab)"
          :key="i + 'tab'"
          @click="way = tab.way"
        >
          <img :src="tab.img" :width="tab.width" />
          &nbsp; &nbsp;{{ tab.name }}
        </v-tab>
      </template>
    </v-tabs>
    <Payment v-show="way == 'payment'" />
    <MyRequest v-show="way == 'mypayment'" />
    <ChildRequest v-show="way == 'childpayment'" />
    <Myrequestbydate v-show="way == 'myreqdate'" />
    <Progress v-if="progress" />
    <Preview v-if="preview" :data="data" :params="params" />
    <Message v-if="message" :res="response" />
  </div>
</template>

<script>
import Progress from "../components/Progress.vue";
import Preview from "../components/Preview.vue";
import Message from "../components/Message.vue";
import Payment from "../request/Payment.vue";
import MyRequest from "../request/Myrequest.vue";
import Myrequestbydate from "../request/Myrequestbydate.vue";
import ChildRequest from "../request/Childrequest.vue";

export default {
  components: {
    Payment,
    MyRequest,
    ChildRequest,
    Progress,
    Preview,
    Message,
    Myrequestbydate
},
  data() {
    return {
      way: "payment",
      data: [],
      progress: false,
      preview: false,
      message: false,
      response: "",
      params: "",
      type: "RQ",
      tabs: [
        {
          name: "Request Payment",
          img: require("../assets/refund.png"),
          way: "payment",
          width: 25
        },
        {
          name: "MyPayment Request List By Date",
          img: require("../assets/refund.png"),
          way: "myreqdate",
          width: 25
        },
        {
          name: "MyPayment Request List",
          img: require("../assets/mypayment.png"),
          way: "mypayment",
          width: 25
        },
        {
          name: "Child Payment Request List",
          img: require("../assets/child.png"),
          way: "childpayment",
          width: 25
        }
      ]
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    check(item) {
      let vm = this;
      if (vm.user.Type == "Dealer" && item.way == "childpayment") return false;
      return true;
    }
  },
  mounted() {
    let height = window.innerHeight - 210;
    let v_table = document.getElementsByClassName("v-table__overflow");
    for (let i = 0; i < v_table.length; i++) {
      v_table[i].style.height = height + "px";
    }
  }
};
</script>
