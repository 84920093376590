<template>
  <v-navigation-drawer permanent app width="220px">
    <img src="../assets/logo.png" class="left-logo" />
    <v-divider></v-divider>
    <v-list dense class="mt-0 pt-0">
      <template v-for="(item, i) in menu">
        <v-list-tile          v-show="checkmenu(item)"          :style="isActive(item)"          :key="i"          @click="$router.push(item.path)"        >
          <v-list-tile-avatar class="left-menu-avater" :size="30" tile>
            <img :src="item.img" />
          </v-list-tile-avatar>
          <v-list-tile>{{ item.name }}</v-list-tile>
        </v-list-tile>
        <v-divider v-show="checkmenu(item)" :key="'divider' + i"></v-divider>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    menu: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    path() {
      return this.$store.getters.way;
    },
  },
  methods: {
    checkmenu(item) {
      let vm = this;
      let dmr = [       
        "dmr2",
        "dmr3",
        "aeps-2",
        "billutility",
        "micro-atm-report",
      ];
      if (vm.path == "recharge" && dmr.indexOf(item.way) != -1) return false;
      if (vm.path === "dmr" && item.name === "Recharge") return false;
      if (vm.user.Type == "Admin" && item.name == "Recharge") return false;
      if (vm.user.Type == "MasterDistributor" && item.name == "Recharge")
        return false;
      if (vm.user.Type == "Distributor" && item.name == "Recharge")
        return false;
        if (vm.user.Type == "Dealer" && item.name == "Balance Transfer") return false;
      if (vm.user.Type == "Dealer" && item.name == "Downline") return false;
      return true;
    },
    isActive({ way }) {
      const vm = this;
      let check = way === vm.$route.name;
      let style = {
        background: vm.$vuetify.theme.secondary,
        color: "white",
      };
      console.log(check);
      return check ? style : {};
    },
  },
};
</script>

<style>
.left-menu-avater {
  min-width: 30px !important;
}
.left-logo {
  width: 100%;
  height: 37px;
  margin-top: 5px;
  object-fit: contain;
}
</style>
