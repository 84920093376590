<template>
  <div style="width: 350px; margin-left: auto; margin-right: auto">
    <div
      style="width: 100%; text-align: center; margin-top: 15px; height: 50px"
      class="headline"
    >
      Create Downline
    </div>

    <v-text-field
      v-model="number"
      style="margin: 0 auto; height: 60px"
      label="Enter Mobile Number"
      solo
    ></v-text-field>
    <v-text-field
      type="text"
      v-model="username"
      style="margin: 0 auto; height: 60px"
      label="Enter Username"
      solo
    />
    <div style="width: 100%; float: left">
      <v-btn color="primary" style="width: 100%; margin: 0 auto" @click="submit"
        >Submit</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      number: "",
      username: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    clear() {
      return this.$store.getters.clear;
    },
  },
  watch: {
    clear() {
      this.cleardata();
    },
  },
  methods: {
    cleardata() {
      let vm = this;
      vm.number = "";
      vm.username = "";
    },
    submit() {
      let vm = this;
      if (vm.user.length == 0)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Authentication Required!",
          type: "warn",
        });
      if (vm.number == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Number Required",
          type: "error",
        });
      if (vm.number.length != 10)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "10 Digit Number Required",
          type: "error",
        });
      if (vm.username == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Username Required",
          type: "error",
        });
      let params =
        "?Mob=" +
        vm.user.phone +
        "&message=RegAc+" +
        vm.username +
        "+" +
        vm.number +
        "+" +
        vm.user.pin +
        "&source=ANDROID";
      let data = {
        "Mobile Number": vm.number,
        "User Name": vm.username,
      };
      vm.$parent.data = data;
      vm.$parent.params = params.toString();
      vm.$parent.preview = true;
    },
  },
};
</script>

<style>
.leftdiv {
  width: 300px;
  float: left;
  margin-bottom: 10px;
}
.rightdiv {
  margin-top: 10px;
  float: left;
  height: 100px;
}
</style>
