<template>
  <div class="download">
    <div class="download_wrapper" >
      <div class="display-3">Bank Details</div>
      <div class="bank_div elevation-5" v-for="(item,i) in list" :key="i">
        <div class="title">{{item.BankName}}</div>
        <div class="body-1">Account Name</div>
        <div class="subheading">{{item.CompanyName}}</div>
        <div class="body-1">Account No</div>
        <div class="subheading">{{item.AcountNo}}</div>
        <div class="body-1">Branch Name</div>
        <div class="subheading">{{item.Branch}}</div>
        <div class="body-1">IFSC Code</div>
        <div class="subheading">{{item.IfscCode}}</div>
        <div class="body-1">Account Type</div>
        <div class="subheading">{{item.AccountType}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../config/index";
export default {
  name: "Bank",
  data() {
    return {
      list: config.banks
    }
  },
  mounted() {
    console.log(config);
  }
};
</script>

<style scoped>
.download {
  width: 100%;
  background: white;
  float: left;
  padding-bottom: 50px;
}
.download_wrapper {
  width: 1000px;
  min-height: 500px;
  margin: 0 auto;
}

.display-3 {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: #F5A21C;
  margin-bottom: 10px;
}
.bank_div {
  width: 480px;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  margin-right: 20px;
  float: left;
  margin-bottom: 5px;
  height: 265px;
}
.bank_div .body-1 {
  margin-top: 10px;
  color: grey;
  width: 130px;
  height: 30px;
  float: left;
}
.bank_div .subheading {
  margin-top: 10px;
  width: 280px;
  height: 30px;
  float: left;
}
.title {
  margin-bottom: 10px;
}
</style>
