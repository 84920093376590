<template>
  <div>
    <v-toolbar color="secondary" dark dense>
      <v-tabs color="secondary" dark>
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab @click="way = 'money'">&nbsp; &nbsp;Money Transfer - IMPS</v-tab>
        <v-tab @click="way = 'report'"
          >&nbsp; &nbsp;DMR Transaction Report</v-tab
        >
      </v-tabs>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down" v-if="dmr !== null">
        <v-btn flat>Consumed Limit: ₹ {{ dmr.consumedlimit }}</v-btn>
        <v-btn flat>Remaining Limit: ₹ {{ dmr.remaininglimit }}</v-btn>
        <v-btn flat @click="$store.commit('setdmr', null)">Change</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div>
      <div v-show="way === 'money'">
        <MoneyTransfer v-if="isActive"></MoneyTransfer>
        <service-down v-else></service-down>
      </div>
    </div>
    <DMRReport v-if="way === 'report'"></DMRReport>
  </div>
</template>
<script>
import MoneyTransfer from "../dmr3/MoneyTransfer.vue";
import DMRReport from "../dmr3/DMRReport.vue";
import axios from "axios";
import config from "../config";
import ServiceDown from "../components/ServiceDown.vue";
export default {
  components: {
    MoneyTransfer,
    DMRReport,
    ServiceDown,
  },
  data() {
    return {
      way: "money",
      isActive: true,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    dmr() {
      return this.$store.getters.dmr;
    },
    dmrenable() {
      return this.$store.getters.dmrenable;
    },
  },
  watch: {
    user(value) {
      let vm = this;
      if (vm.user.length == 0) {
        this.$router.push("/account");
      }
    },
  },

  methods: {
    notification(type, message) {
      this.$notify({
        group: "foo",
        title: "Important message",
        text: message,
        duration: 5000,
        type: type,
      });
    },
    async checkDMRStatus() {
      const vm = this;
      try {
        vm.$loader.show();
        let url = `${config.moneyTransfer}DMRGateWay`;
        let params = {
          MobileNo: vm.user.phone,
          pinNo: vm.user.pin,
        };
        let data = (await axios.get(url, { params })).data;
        let dmr = data.find((item) => {
          return item.Id == 1;
        });
        // vm.isActive = dmr != null ? dmr.IsActive == true : false;
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.notification("error", error.message);
      }
    },
  },
  beforeMount() {
    this.checkDMRStatus();
  },
  mounted() {
    let vm = this;
    if (vm.user.length == 0) {
      this.$router.push("/account");
    }
    vm.$store.commit("setdmr", null);
  },
};
</script>
