<template>
  <div>
    <v-dialog v-model="view" width="600" persistent>
      <v-card :color="color">
        <v-card-title style="height: 40px; color: white">
          <span class="title">Reponse</span>
        </v-card-title>
        <v-layout v-if="type != ''">
          <v-flex xs2>
            <v-img
              :src="src"
              contain
              style="margin-left: 20px; margin-top: 5px"
            ></v-img>
          </v-flex>
          <v-flex xs10>
            <v-card-text
              style="
                padding-top: 0px;
                color: white;
                line-height: 28px !important;
              "
              class="title"
            >
              {{ response }}
            </v-card-text>
          </v-flex>
        </v-layout>
        <v-card-text
          v-else
          style="padding-top: 0px; color: white; line-height: 28px !important"
          class="title"
        >
          {{ response }}
        </v-card-text>
        <v-card-actions style="width: 100%">
          <v-spacer></v-spacer>
          <v-btn small class="primary" @click="cancel">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["res"],
  data() {
    return {
      view: true,
      type: "",
      color: "green",
      src: "",
      response: "",
    };
  },
  methods: {
    cancel() {
      let vm = this;
      vm.$parent.message = false;
    },
    fetchdata() {
      let vm = this;
      vm.$parent.fetchdata();
    },
    checktype() {
      let vm = this;
      if (vm.response.includes("Processed")) {
        vm.type = "proccess";
        vm.src = require("../assets/process.svg");
        vm.color = "blue";
      }
      if (vm.response.includes("fail")) {
        vm.type = "fail";
        vm.src = require("../assets/warning.svg");
        vm.color = "red";
      }
      if (vm.response.includes("Success")) {
        vm.type = "success";
        vm.src = require("../assets/success.svg");
        vm.color = "green";
      }
    },
  },
  mounted() {
    let vm = this;
    vm.response = vm.res;
    vm.checktype();
  },
};
</script>
