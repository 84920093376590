<template>
  <v-dialog v-model="dialog" persistent width="800" height="500">
    <v-card>
      <v-card-title style="padding:10px;"
        >Browse Plans
        <v-spacer />
        <v-btn style="margin:0px;" @click="close" class="error">Close</v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-tabs
        color="primary"
        dark
        next-icon="arrow_forward_ios"
        prev-icon="arrow_back_ios"
        show-arrows
        centered
      >
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab
          v-for="(tab, key) in tabs"
          @click="planlist = plans[tab.name]"
          :key="'tab' + key"
        >
          {{ tab.name }}
        </v-tab>
      </v-tabs>
      <v-data-table :headers="headers" :items="planlist" hide-actions>
        <template slot="items" slot-scope="props">
          <td>
            <v-btn
              v-if="props.item.rs"
              @click="select(props.item.rs)"
              style="margin:0px;"
              small
              color="primary"
              >SELECT</v-btn
            >
          </td>
          <td>{{ props.item.rs }}</td>
          <td>{{ props.item.validity }}</td>
          <td>{{ props.item.desc }}</td>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["model", "pplans"],
  data() {
    return {
      plans: [],
      dialog: false,
      tabs: [
        { name: "FULLTT" },
        { name: "TOPUP" },
        { name: "3G/4G" },
        { name: "RATE CUTTER" },
        { name: "2G" },
        { name: "SMS" },
        { name: "Roaming" },
        { name: "COMBO" },
        { name: "FRC" }
      ],
      planlist: [],
      headers: [
        { text: "Action", value: "action" },
        { text: "Rs", value: "rs", align: "left", sortable: false },
        { text: "Validity", value: "validity" },
        { text: "Description", value: "desc" }
      ]
    };
  },
  methods: {
    select(rs) {
      let vm = this;
      vm.dialog = false;
      vm.planlist = [];
      vm.plans = [];
      vm.$parent.showplan = false;
      vm.$parent.rechargeramount = rs;
    },
    close() {
      let vm = this;
      vm.dialog = false;
      vm.$parent.showplan = false;
      vm.plans = [];
      vm.planlist = [];
    }
  },
  mounted() {
    let vm = this;
    vm.dialog = false;
    vm.dialog = vm.model;
    vm.plans = vm.pplans;
    vm.planlist = vm.plans["FULLTT"];
  }
};
</script>
