<template>
  <div style="width: 350px; margin-left: auto; margin-right: auto">
    <div
      style="width: 100%; text-align: center; margin-top: 15px; height: 50px"
      class="headline"
    >
      Request Payment
    </div>
    <v-autocomplete
      style="margin: 0 auto; height: 60px"
      v-model="type"
      :items="paymenttype"
      label="Payment Type"
      solo
    />

    <v-autocomplete
      style="margin: 0 auto; height: 60px"
      v-model="puser"
      :items="users"
      label="Request To"
      solo
    />
    <v-autocomplete
      style="margin: 0 auto; height: 60px"
      v-model="bank"
      :items="banks"
      label="Pay Bank"
      solo
    />
    <template v-if="type !== 'cash'">
      <v-text-field
        type="text"
        v-model="bankname"
        style="margin: 0 auto; height: 60px"
        label="Enter Bank Name"
        solo
      ></v-text-field>
      <v-text-field
        type="text"
        v-model="accountnumber"
        style="margin: 0 auto; height: 60px"
        label="Enter Account Number"
        solo
      ></v-text-field>
      <v-text-field
        type="text"
        v-model="utr"
        style="margin: 0 auto; height: 60px"
        label="Enter UTR REF"
        solo
      ></v-text-field>
    </template>
    <v-text-field
      v-model="amount"
      style="margin: 0 auto; height: 60px"
      label="Enter Amount"
      solo
    />
    <v-text-field
      type="text"
      v-model="remark"
      style="margin: 0 auto; height: 60px"
      label="Enter Remark"
      solo
    />
    <v-form v-model="isValid" ref="form" class="d-flex aeps2-row">
    <input
            type="file" 
            @change="onFileChange($event, 'Paymentreceipt')"         
            ref="Paymentreceipt"
            style="margin: 0 auto; height: 60px"
            accept="image/*"
          />
          <div style="width: 100%; float: left; margin-bottom: 10px">
      <v-btn color="primary" style="width: 100%; margin: 0 auto" @click="submitPaymentReceipt"
        >Upload Payment Receipt</v-btn
      >
    </div></v-form>

    <div style="width: 100%; float: left; margin-bottom: 10px">
      <v-btn color="primary" style="width: 100%; margin: 0 auto" @click="submit"
        >Submit</v-btn
      >
    </div>
  </div>
</template>

<script>

import config from "../config";
import axios from "axios";
import store from "../store.js";
const baseURL = config.baseUrl;
const http = axios.create({
  baseURL,
});
export default {

  
  data() {
    return {
      cmd:"",
      isValid: false,
      puser: "",
      users: ["ADMIN", "PARENT"],
      bank: "",
      banks: [],
      bankname: "",
      accountnumber: "",
      utr: "",
      paymenttype: [
        "cash",
        "DD",
        "cheque",
        "NEFT",
        "RGTS",
        "ATMTransfer",
        "MConnect",
        "IMPS",
        "Moneytransfer",
      ],
      amount: "",
      remark: "",
      type: "",
      fileName:""
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    clear() {
      return this.$store.getters.clear;
    },
  },
  watch: {
    getprovider() {
      let vm = this;
      vm.pushoperator();
    },
    clear() {
      this.cleardata();
    },
  },
  methods: {

    updatePaymentRec(data) {
      debugger;
    return http.post("/AddPaymentReqReceipt", data);
  },
  onFileChange(e, name) {
    debugger;
      const vm = this;
      let file = e.target.files[0];
      vm[`${name}_file`] = null;
      vm[`${name}_file_name`] = "";
      if (file != null) {
        vm[`${name}_file`] = file;
        vm[`${name}_file_name`] = file.name;
      }
    },
    errorimg(e) {
      e.target.src = require("../assets/logo_single.png");
    },
    cleardata() {
      let vm = this;
      vm.type = "";
      vm.amount = "";
      vm.remark = "";
      vm.utr = "";
      vm.accountnumber = "";
      vm.bankname = "";
      vm.puser = "";
      vm.bank = "";
      vm.fileName = "";
    },
    submit() {
      let vm = this;
      if (vm.user.length == 0)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Authentication Required!",
          type: "warn",
        });
      if (vm.puser == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "User Type Required",
          type: "error",
        });
      if (vm.type !== "cash") {
        if (vm.bank == "")
          return vm.$store.dispatch("notify", {
            vm: vm,
            message: "Select Bank",
            type: "error",
          });
        if (vm.bankname == "")
          return vm.$store.dispatch("notify", {
            vm: vm,
            message: "Bank Name Required",
            type: "error",
          });
        if (vm.accountnumber == "")
          return vm.$store.dispatch("notify", {
            vm: vm,
            message: "Account Number Required",
            type: "error",
          });
        if (vm.utr == "")
          return vm.$store.dispatch("notify", {
            vm: vm,
            message: "UTR Required",
            type: "error",
          });
      }

      if (vm.type == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Payment Type Required",
          type: "error",
        });
      if (vm.amount == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Amount Required",
          type: "error",
        });

        if (vm.puser == "ADMIN"){
          vm.cmd ="PayReqAdmin";
        }
        else{
          vm.cmd ="PayReq";
        }
      let params =
        "?Mob=" +
        vm.user.phone +
        "&pin=" +
        vm.user.pin +
        "&Cmd="+vm.cmd+"&Amount=" +
        vm.amount +
        "&PBank=" +
        vm.bank +
        "&CBank=" +
        vm.bankname +
        "&CAC=" +
        vm.accountnumber +
        "&TID=" +
        vm.utr +
        "&PType=" +
        vm.type +
        "&Remark=" +
        vm.remark +
        "&source=ANDROID&PaymentReceipt=" + this.fileName;
      let data = {
        "Request To": vm.puser,
        "Pay Bank": vm.bank,
        "Bank Name": vm.bankname,
        "Account Number": vm.accountnumber,
        "UTR REF": vm.utr,
        "Payment Type": vm.type,
        Amount: vm.amount,
        Remark: vm.remark,
      };
      vm.$parent.data = data;
      vm.$parent.params = params.toString();
      vm.$parent.preview = true;
    },
    async submitPaymentReceipt() {
      debugger;
      const vm = this;
      let { phone, pin } = vm.$store.getters.user;
      vm.$refs.form.validate();
      if (!vm.isValid) return;
      try {
        vm.$loader.show();
        let form = new FormData();
        console.log(vm.Paymentreceipt_file);       
        form.append("", vm.Paymentreceipt_file);      
        form.append("MobileNo", phone);
        form.append("PinNo", pin);
        let doc = {};
       
        let { Status, Message } = (await this.updatePaymentRec(form)).data;
        if (Status == "True") {
        this.fileName = Message;
        alert("Receipt Upload successfully");
        } else {
         
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }

    },
    fetchbank() {
      let vm = this;
      if (vm.user.length == 0) return;
      let doc =
        "?Mob=" +
        vm.user.phone +
        "&pin=" +
        vm.user.pin +
        "&Cmd=BNMADMIN&source=ANDROID";
      vm.$store
        .dispatch("saxios", {
          data: doc,
        })
        .then(function (doc) {
          let split = doc.data.split("#");
          for (let i = 0; i < split.length; i++) {
            if (split[i] != "") {
              vm.banks.push(split[i].split(",")[1]);
            }
          }
        })
        .catch(function () {});
    },
  },
  mounted() {
    let vm = this;
    vm.fetchbank();
  },
};
</script>

<style>
.leftdiv {
  width: 300px;
  float: left;
  margin-bottom: 10px;
}
.rightdiv {
  margin-top: 10px;
  float: left;
  height: 100px;
}
</style>
