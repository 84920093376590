<template>
  <v-carousel delimiter-icon="stop" style="margin-top: 80px">
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      :src="item.src"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
      items: [
        {
          src: require("../assets/bus-booking-1-1.jpg")
        },
        {
          src: require("../assets/bus-booking-1-1.jpg")
        },
        {
          src: require("../assets/bank-banking-blue-50987.jpg")
        },
        {
          src: require("../assets/014.jpg")
        },
        {
          src: require("../assets/015.jpg")
        },
      ]
    };
  }
};
</script>

<style scoped></style>
