<template>
  <v-container grid-list-md text-xs-center>
    <v-layout row wrap style="width: 750px; margin: 0 auto">
      <v-flex xs6>
        <v-card class="primary">
          <v-list two-line>
            <v-list-tile>
              <v-list-tile-action>
                <v-icon color="indigo">help</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ details.HelpLineNo }}</v-list-tile-title>
                <v-list-tile-sub-title>Help Line</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile>
              <v-list-tile-action> </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{
                  details.InqueryNumber
                }}</v-list-tile-title>
                <v-list-tile-sub-title>Inquery</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile>
              <v-list-tile-action>
                <v-icon color="indigo">phone</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ details.ContactMob }}</v-list-tile-title>
                <v-list-tile-sub-title>Contact</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider inset></v-divider>
          </v-list>
          <v-list>
            <v-list-tile>
              <v-list-tile-action>
                <v-icon color="indigo">mail</v-icon>
              </v-list-tile-action>

              <v-list-tile-content>
                <v-list-tile-title>{{ details.EmailId }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile>
              <v-list-tile-action>
                <v-icon color="indigo">public</v-icon>
              </v-list-tile-action>

              <v-list-tile-content>
                <v-list-tile-title>{{ details.Footer }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
          <v-list two-line>
            <v-divider inset></v-divider>

            <v-list-tile>
              <v-list-tile-action>
                <v-icon color="indigo">location_on</v-icon>
              </v-list-tile-action>

              <v-list-tile-content>
                <v-list-tile-title>{{ details.Address1 }}</v-list-tile-title>
                <v-list-tile-sub-title>{{
                  details.Address2
                }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex xs6>
        <v-card class="primary">
          <v-list>
            <v-list-tile>
              <v-list-tile-action>
                <v-avatar>
                  <img :src="require('../assets/google.png')" />
                </v-avatar>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ details.AndroidAppName }}
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn icon ripple @click="openlink(details.PlayStoreUrl)">
                  <v-icon color="blue darken-1">insert_link</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </v-card>
        <v-card
          style="margin-top: 10px; padding-top: 10px; padding-bottom: 10px"
          v-for="(item, i) in bankdetails"
          :key="i"
        >
          <div>
            <div style="height: 100px; width: 70px; float: left">
              <v-avatar style="float: left; margin: 10px">
                <img
                  :src="require('../assets/safebox.svg')"
                  style="border-radius: 0px"
                />
              </v-avatar>
            </div>
            <div style="text-align: left; width: 400px">
              {{ item.BankName }}
            </div>

            <div style="text-align: left">
              Name : {{ item.CompanyName }}<br />
              Branch : {{ item.Branch }}<br />
              AcountNo : {{ item.AcountNo }}<br />
              IfscCode : {{ item.IfscCode }}<br />
            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    bankdetails() {
      return this.$store.getters.bankdetails;
    },
    getprofile() {
      return this.$store.getters.profile.Data;
    },
    details() {
      return this.$store.getters.details;
    },
  },
  methods: {
    openlink(url) {
      let win = window.open(url, "_blank");
      win.focus();
    },
  },
};
</script>
