<template>
  <div>
    <v-tabs
      next-icon="arrow_forward_ios"
      prev-icon="arrow_back_ios"
      show-arrows
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        class="primary--text"
        v-for="(tab, i) in tabs"
        :key="i + 'tab'"
        @click="way = tab.way"
      >
        <img :src="tab.img" :width="tab.width" />
        &nbsp; &nbsp;{{ tab.name }}
      </v-tab>
    </v-tabs>
    <CreateDownline v-show="way == 'create'" />
    <DownlineList v-show="way == 'list'" />
    <Progress v-if="progress" />
    <Preview v-if="preview" :data="data" :params="params" />
    <Message v-if="message" :res="response" />
  </div>
</template>

<script>
import Progress from "../components/Progress.vue";
import Preview from "../components/Preview.vue";
import Message from "../components/Message.vue";
import CreateDownline from "../downline/CreateDownline.vue";
import DownlineList from "../downline/DownlineList.vue";
export default {
  components: {
    CreateDownline,
    Progress,
    Preview,
    Message,
    DownlineList
  },
  data() {
    return {
      way: "create",
      data: [],
      progress: false,
      preview: false,
      message: false,
      response: "",
      check: true,
      params: "",
      type: "DW",
      tabs: [
        {
          name: "Create Downline",
          img: require("../assets/adduser.png"),
          way: "create",
          width: 25
        },
        {
          name: "Downline List",
          img: require("../assets/childlist.png"),
          way: "list",
          width: 25
        }
      ]
    };
  },
  mounted() {
    let height = window.innerHeight - 210;
    let v_table = document.getElementsByClassName("v-table__overflow");
    for (let i = 0; i < v_table.length; i++) {
      v_table[i].style.height = height + "px";
    }
  }
};
</script>
