<template>
  <div>
    <div class="intro_wrapper">
      <div style="margin-bottom: 50px;float:left;width: 100%">
        <div class="display-3">We provide great services</div>
        <div class="headline">
          Conceived to deliver a simplified recharge experience online,<br />
          Self Technology has been the front runner in this domain.
        </div>
      </div>

      <div class="service_right">
        <div class="display-2">AEPS</div>
        <div class="headline">
          AEPS is online payment solution.It ia simple and secure system whick allows people to carry on transactions at point
          of sale or small scale ATM's just by providing their Aadhar number and finger print scan for verification .
        </div>
      </div>
      <div class="service_left">
        <img src="../assets/aeps.png" alt="" />
      </div>
      <div class="service_right">
        <img src="../assets/micro-atm.png" alt="" />
      </div>
      <div class="service_left">
        <div class="display-2">Micro ATM</div>
        <div class="headline">
          Micro will be enable novel Financial Inclusion features such
          as Cash Withdrawal & Cash Deposit supplementary to Debit/Credit Card transactions.
        </div>
      </div>


      <div class="service_left">
        <div class="display-2">Recharge</div>
        <div class="headline">
          Self Technology is India’s No.1 recharge site that delivers next generation
          instant Online pre-paid recharge solutions to end users.
        </div>
      </div>
      <div class="service_right">
        <img src="../assets/sim-card.png" alt="" />
      </div>
      <div class="service_left">
        <img src="../assets/signal.png" alt="" />
      </div>
      <div class="service_right">
        <div class="display-2">DTH</div>
        <div class="headline">
          We are the most reliable online DTH recharge Service provider. Our DTH
          service covers providers like Tata Sky, Dish TV, Sun Direct, Videocon
          d2h, Big TV. etc.
        </div>
      </div>
      <div class="service_left">
        <div class="display-2">Data Card Recharge</div>
        <div class="headline">
          All major data card operators like Airtel, Idea, Vodafone, MTS, Tata
          Indicom, Tata Docomo, Aircel, Reliance & Uninor can be recharged using
          our service.
        </div>
      </div>
      <div class="service_right">
        <img src="../assets/wifi.png" alt="" />
      </div>
      <div class="service_left">
        <img src="../assets/parking.png" alt="" />
      </div>
      <div class="service_right">
        <div class="display-2" style="margin-top: 30px">Post Paid</div>
        <div class="headline">
          Postpaid recharges of all the operators can be done instantly with our
          system.
        </div>
      </div>
      <div class="service_left">
        <div class="display-2" style="margin-top: 50px">Utility Payment</div>
        <div class="headline">
          Utility Payment Like Electricity, Gas, Water, etc.
        </div>
      </div>
      <div class="service_right">
        <img src="../assets/challenge.png" alt="" />
      </div>
      <div class="service_left">
        <img src="../assets/dollar.png" alt="" />
      </div>
      <div class="service_right" style="margin-top: 30px">
        <div class="display-2">Money Transfer</div>
        <div class="headline">
          Instant money transfer across all the major banks in India can be done
          within seconds by registering with us.
        </div>
      </div>
      <div class="service_left">
        <div class="display-2" style="margin-top: 40px">Bus Booking</div>
        <div class="headline">
          Self Technology Enterprise Provides the Online bus booking facility from our website.
        </div>
      </div>
      <div class="service_right">
        <img src="../assets/bus.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
  name: "Introduction",
  components: {}
  };
</script>

<style scoped="">
  .intro_wrapper {
  width: 1000px;
  margin: 0 auto;
  }
  .display-3 {
  margin-top: 50px;
  color: #F5A21C;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  }

  .headline {
  margin-bottom: 50px;
  width: 100%;
  color: grey;
  text-align: center;
  }
  .service_left {
  width: 500px;
  float: left;
  height: 300px;
  }
  .service_left .display-2 {
  font-weight: bold;
  color: #6e7379;
  width: 100%;
  text-align: center;
  }
  .service_left .headline {
  margin-top: 20px;
  }
  .service_right .display-2 {
  font-weight: bold;
  color: #6e7379;
  width: 100%;
  text-align: center;
  }
  .service_right .headline {
  margin-top: 20px;
  }
  .service_right {
  width: 500px;
  float: left;
  height: 300px;
  }
  .service_right img {
  width: 200px;
  margin-left: 150px;
  height: 200px;
  }
  .service_left img {
  width: 200px;
  margin-left: 150px;
  height: 200px;
  }
</style>
