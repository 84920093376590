import Vue from "vue";
import Vuex from "vuex";
import config from "./config";

let baseUrl = config.baseUrl;
let rbaseUrl = config.rbaseUrl;
let sbaseUrl = config.sbaseUrl;
let planUrl = config.planUrl;
let path = config.path;
let apikey = config.apikey;
let details = config.details;
let bankdetails = config.banks;
let image = config.logo;
let dmr2 = config.dmr2;
let instapay = config.instapay;

const dmrUrl = config.dmrUrl;
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    _way: localStorage.loginType ? localStorage.loginType : "website",
    _rwidth: window.innerWidth - 360 + "px",
    _user: [],
    _profile: [],
    _details: details,
    _bankdetails: bankdetails,
    _logo: image,
    _lasttrans: [],
    _operator: [],
    _path: path,
    _clear: "",
    _balance: "",
    _statelist: [],
    _citylist: [],
    _dmr2enable: true,
    _bank: [],
    _quickpay: [],
    _dashcard: {},
    _dashReport: [],
    _dmrenable: true,
    _dmr: null,
  },
  getters: {
    dmr2enable(state) {
      return state._dmr2enable;
    },
    bankdetails(state) {
      return state._bankdetails;
    },
    details(state) {
      return state._details;
    },
    logo(state) {
      return state._logo;
    },
    rwidth(state) {
      return state._rwidth;
    },
    way(state) {
      return state._way;
    },
    statelist(state) {
      return state._statelist;
    },
    citylist(state) {
      return state._citylist;
    },
    profile(state) {
      return state._profile;
    },
    operator(state) {
      return state._operator;
    },
    lasttrans(state) {
      return state._lasttrans;
    },
    user(state) {
      return state._user;
    },
    clear(state) {
      return state._clear;
    },
    balance(state) {
      return state._balance;
    },
    bank(state) {
      return state._bank;
    },
    quickpay(state) {
      return state._quickpay;
    },
    path(state) {
      return state._path;
    },
    dashcard(state) {
      return state._dashcard;
    },
    dashReport(state) {
      return state._dashReport;
    },
    dmrenable(state) {
      return state._dmrenable;
    },
    dmr(state) {
      return state._dmr;
    },
  },
  mutations: {
    setrwidth(state, value) {
      state._rwidth = value;
    },
    setuser(state, value) {
      state._user = value;
    },
    setstatelist(state, value) {
      state._statelist = value;
    },
    setcitylist(state, value) {
      state._citylist = value;
    },
    setoperator(state, value) {
      state._operator = value;
    },
    setprofile(state, value) {
      state._profile = value;
    },
    setlasttrans(state, value) {
      state._lasttrans = value;
    },
    setclear(state, value) {
      state._clear = value;
    },
    setbalance(state, value) {
      state._balance = value.Data.Balance;
    },
    setbank(state, value) {
      state._bank = value.Data;
    },
    setquickpay(state, value) {
      state._quickpay = value;
    },
    setdetails(state, value) {
      if (value.details != null) {
        state._details = value.details;
      }
      if (value.logo != null) {
        state._logo = path + value.logo;
      }
      if (value.bankdetails != null) {
        state._bankdetails = value.bankdetails;
      }
    },
    setway(state, value) {
      state._way = value;
    },
    setdashcard(state, value) {
      if (value && value.Status === "True") {
        state._dashcard = value.Data;
        return;
      }
      state._dashcard = {};
    },
    setDashReport(state, value) {
      state._dashReport = value;
    },
    setdmr(state, value) {
      state._dmr = value;
    },
  },
  actions: {
    axiosget(context, payload) {
      let url = baseUrl + payload.url;
      let params = payload.data;
      let { ApiAuthKey } = context.getters.user;
      if (this.state._way === "dmr") params.Balance = "DMR";
      let way = payload.way;
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(url, { params: { ...params, ApiAuthKey } })
          .then(function (docs) {
            if (docs.data.Message == "Not Authorised.") {
              window.localStorage.removeItem("user");
              context.dispatch("getuser");
            }
            if (docs.data && way) context.commit(way, docs.data);
            return resolve(docs);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    getbalance(context) {
      let user = context.getters.user;
      if (user != null && user.length != 0) {
        let doc = { PinNo: user.pin, MobileNo: user.phone };
        let url = "Balance";
        if (this.state._way === "dmr") url = "BalanceDMR";
        context.dispatch("axiosget", {
          data: doc,
          url: url,
          way: "setbalance",
        });
      }
    },

    setsync(context, payload) {
      context.commit("setquickpay", payload.data);
    },

    getuser(context) {
      let doc = [];
      if (localStorage.user) {
        doc = JSON.parse(localStorage.user);
      }
      if (doc != null) context.commit("setuser", doc);
    },
    axios(context, payload) {
      let url = baseUrl + payload.url;
      let { ApiAuthKey } = context.getters.user;
      let params = payload.data;
      if (ApiAuthKey) params.ApiAuthKey = ApiAuthKey;
      if (this.state._way === "dmr") params.Balance = "DMR";
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(url, { params: { ...params } })
          .then(function (docs) {
            if (docs.data.Message == "Not Authorised.") {
              window.localStorage.removeItem("user");
              context.dispatch("getuser");
              return resolve(docs);
            }
            resolve(docs);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    raxios(context, payload) {
      let params = payload.data;
      let { ApiAuthKey } = context.getters.user;
      params += `&ApiAuthKey=${ApiAuthKey}`
      if (this.state._way === "dmr") params += `&Balance=DMR`;
      let url = rbaseUrl + params;
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(url)
          .then(function (docs) {
            if (docs.data.Message == "Not Authorised.") {
              window.localStorage.removeItem("user");
              context.dispatch("getuser");
              return resolve(docs);
            }
            resolve(docs);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    saxios(context, payload) {
      let params = payload.data;
      let { ApiAuthKey } = context.getters.user;
      params += `&ApiAuthKey=${ApiAuthKey}`
      if (this.state._way === "dmr") params += "&Balance=DMR";
      let url = sbaseUrl + params;
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(url)
          .then(function (docs) {
            if (docs.data.Message == "Not Authorised.") {
              window.localStorage.removeItem("user");
              context.dispatch("getuser");
              return resolve(docs);
            }
            resolve(docs);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    notify(context, payload) {
      let vm = payload.vm;
      let message = payload.message;
      let type = payload.type;
      vm.$notify({
        group: "foo",
        title: "Important message",
        text: message,
        duration: 5000,
        type: type,
      });
    },
    planapi(context, payload) {
      let url = planUrl + payload.url;
      let params = {
        url: "apikey=" + apikey + "|" + payload.data,
      };
      if (this.state._way === "dmr") params.Balance = "DMR";
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(url, { params: params })
          .then(function (docs) {
            if (docs.data.Message == "Not Authorised.") {
              window.localStorage.removeItem("user");
              context.dispatch("getuser");
              return resolve(docs);
            }
            resolve(docs);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    axiospost(context, payload) {
      let url = baseUrl + payload.url;
      let params = payload.data;
      let { ApiAuthKey } = context.getters.user;
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(url, { params: { ...params, ApiAuthKey } })
          .then(function (docs) {
            if (docs.data.Message == "Not Authorised.") {
              window.localStorage.removeItem("user");
              context.dispatch("getuser");
              return resolve(docs);
            }
            resolve(docs);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    operator(context, payload) {
      let params = payload.data;
      let url = baseUrl + "ServiceInUseList";
      let { ApiAuthKey } = context.getters.user;
      if (this.state._way === "dmr") params.Balance = "DMR";
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(url, { params: { ...params, ApiAuthKey } })
          .then(function (docs) {
            if (docs.data.Message == "Not Authorised.") {
              window.localStorage.removeItem("user");
              context.dispatch("getuser");
              return resolve(docs);
            }

            if (docs.data) context.commit("setoperator", docs.data.Data);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    axiosdmrpost(context, payload) {
      let { type } = payload;
      let url = dmrUrl + payload.url;
      if (type === "dmr2") url = dmr2 + payload.url;
      if (type === "instapay") url = instapay + payload.url;
      if (type == "dmrCyberplat") url = config.dmrCyberplat + payload.url;
      if (type == "dmrPsy") url = config.dmrPsy + payload.url;
      let { ApiAuthKey } = context.getters.user;
      let params = payload.data;
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(url, { params: { ...params, ApiAuthKey } })
          .then(function (docs) {
            if (docs.data.Message == "Not Authorised.") {
              window.localStorage.removeItem("user");
              context.dispatch("getuser");
              return resolve(docs);
            }
            resolve(docs);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },

    /*
//for free recharge
operator(context,payload){
  let params = payload.data;
  let url = "http://free.mrechargesystem.com/ReCharge/androidapi.asmx/ServiceInUseList"
  return new Promise((resolve, reject) => {
    Vue.axios.get(url,{params:params}).then(function (docs) {
      if(docs.data.Message=="Not Authorised.") {
        window.localStorage.removeItem("user");
        context.dispatch("getuser");
        return resolve(docs)
      }
      if(docs.data)context.commit("setoperator",docs.data.Data)
    }).catch(function (error) {
      reject(error)
    })
  })
}
*/
  },
});
