<template>
  <div>
    <v-dialog v-model="view" persistent width="660">
      <v-layout>
        <v-flex>
          <v-card style="width:660px;margin-top:20px" class="center">
            <img
              src="../assets/profile.svg"
              width="100px"
              style="margin-left:20px;margin-top:20px;float:left"
              alt=""
            />
            <v-list two-line>
              <v-list-tile style="height:55px;width:250px;float:left"
                ><v-list-tile-content
                  ><v-text-field
                    v-model="fname"
                    label="First Name"
                    style="width:240px"/></v-list-tile-content
              ></v-list-tile>
              <v-list-tile style="height:55px;"
                ><v-list-tile-content
                  ><v-text-field
                    v-model="lname"
                    label="Last Name"
                    style="width:240px"/></v-list-tile-content
              ></v-list-tile>
              <v-list-tile style="height:55px;width:250px;float:left"
                ><v-list-tile-content
                  ><v-text-field
                    v-model="phone"
                    disabled
                    label="Mobile Number"
                    style="width:240px"/></v-list-tile-content
              ></v-list-tile>
              <v-list-tile style="height:55px;"
                ><v-list-tile-content
                  ><v-text-field
                    v-model="username"
                    label="Username"
                    disabled
                    style="width:240px"/></v-list-tile-content
              ></v-list-tile>
            </v-list>
            <v-card-title style="padding:0px">
              <div style="width:330px;float:left">
                <v-list two-line>
                  <v-list-tile style="height:55px;"
                    ><v-list-tile-content
                      ><v-text-field
                        label="Mail ID"
                        v-model="mail"
                        style="width:100%"/></v-list-tile-content
                  ></v-list-tile>
                  <v-list-tile style="height:55px;"
                    ><v-list-tile-content
                      ><v-text-field
                        label="Address"
                        v-model="address"
                        style="width:100%"/></v-list-tile-content
                  ></v-list-tile>
                  <v-list-tile style="height:55px;"
                    ><v-list-tile-content
                      ><v-text-field
                        label="City"
                        v-model="city"
                        style="width:100%"/></v-list-tile-content
                  ></v-list-tile>
                  <v-list-tile style="height:55px;"
                    ><v-list-tile-content>
                      <v-autocomplete
                        style="width:100%;height:53px"
                        v-model="state"
                        :items="states"
                        label="Select State"
                      /> </v-list-tile-content
                  ></v-list-tile>
                </v-list>
              </div>
              <div style="width:330px;float:left">
                <v-list two-line>
                  <v-list-tile style="height:55px;"
                    ><v-list-tile-content
                      ><v-text-field
                        label="Company Name"
                        v-model="companyname"
                        style="width:100%"/></v-list-tile-content
                  ></v-list-tile>
                  <v-list-tile style="height:55px;"
                    ><v-list-tile-content
                      ><v-text-field
                        label="Aadhaar Number"
                        v-model="aadhaarno"
                        style="width:100%"/></v-list-tile-content
                  ></v-list-tile>
                  <v-list-tile style="height:55px;"
                    ><v-list-tile-content
                      ><v-text-field
                        label="PAN Number"
                        v-model="panno"
                        style="width:100%"/></v-list-tile-content
                  ></v-list-tile>
                  <v-list-tile style="height:55px;"
                    ><v-list-tile-content
                      ><v-text-field
                        label="GST Number"
                        v-model="gstno"
                        style="width:100%"/></v-list-tile-content
                  ></v-list-tile>
                </v-list>
              </div>
            </v-card-title>
            <v-card-actions>
              <v-btn
                class="error"
                @click="close"
                style="margin-left:390px;margin-bottom:10px"
                >CANCEL</v-btn
              >
              <v-btn class="primary" style="margin-left:15px;margin-bottom:10px"
                >UPDATE PROFILE</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      view: true,
      lname: "",
      phone: "",
      fname: "",
      address: "",
      state: "",
      states: [],
      citys: [],
      city: "",
      stateid: "",
      cityid: "",
      mail: "",
      companyname: "",
      username: "",
      gstno: "",
      userid: "",
      panno: "",
      aadhaarno: ""
    };
  },
  computed: {
    profile() {
      return this.$store.getters.profile.Data;
    },
    statelist() {
      return this.$store.getters.statelist;
    },
    citylist() {
      return this.$store.getters.citylist;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  watch: {
    statelist() {
      let vm = this;
      let docs = vm.statelist.Data;
      if (docs == null || docs.length == 0) return;
      vm.states = [];
      for (let i = 0; i < docs.length; i++) {
        vm.states.push(docs[i].Name);
        if (docs[i].Id == vm.stateid) {
          vm.state = docs[i].Name;
        }
      }
    },
    citylist() {
      let vm = this;
      let docs = vm.citylist.Data;
      if (docs == null || docs.length == 0) return;
      vm.citys = [];
      for (let i = 0; i < docs.length; i++) {
        vm.citys.push(docs[i].Name);
        if (docs[i].Id == vm.cityid) {
          vm.city = docs[i].Name;
        }
      }
    }
  },
  methods: {
    close() {
      let vm = this;
      vm.$parent.edit = false;
    },
    update() {
      // let vm = this;
      // let doc = {
      //   AddharNo: vm.aadhaarno,
      //   Address: vm.address,
      //   CityId: vm.cityid,
      //   CompanyName: vm.companyname,
      //   Email: vm.mail,
      //   FirstName: vm.fname,
      //   GSTNo: vm.gstno,
      //   LastName: vm.lname,
      //   MobileNo: vm.phone,
      //   PanNo: vm.panno,
      //   StateId: vm.stateid,
      //   UserId: vm.userid,
      //   UserName: vm.username
      // };
    },
    setdetail() {
      let vm = this;
      let doc = vm.profile;
      if (vm.profile != null) {
        vm.fname = doc.FirstName;
        vm.lname = doc.LastName;
        vm.phone = doc.MobileNo;
        vm.username = doc.UserName;
        vm.mail = doc.Email;
        vm.address = doc.Address;
        vm.cityid = doc.CityId;
        vm.stateid = doc.StateId;
        vm.companyname = doc.CompanyName;
        vm.aadhaarno = doc.AddharNo;
        vm.panno = doc.PanNo;
        vm.gstno = doc.GSTNo;
        vm.userid = doc.UserId;
      }
      let docs = vm.statelist.Data;
      if (docs == null || docs.length == 0) return;
      for (let i = 0; i < docs.length; i++) {
        vm.states.push(docs[i].Name);
        if (docs[i].Id == vm.stateid) {
          vm.state = docs[i].Name;
        }
      }
    }
  },
  mounted() {
    let vm = this;
    vm.setdetail();
  }
};
</script>

<style>
.center {
  margin-left: auto;
  margin-right: auto;
}
</style>
