<template>
  <div>
    <v-dialog persistent v-model="dialog" width="400">
      <v-card>
        <v-card-title
          class="headline grey"
          primary-title
          style="
            background: #080f54 !important;
            padding-top: 15px;
            color: white;
          "
        >
          Login
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Phone Number"
            v-model="username"
            placeholder="Enter your phone"
          ></v-text-field>
          <v-text-field
            label="Pin Number"
            type="password"
            placeholder="Enter your pin"
            v-model="password"
          ></v-text-field>
      
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            flat
            @click="
              forgotDialog = true;
              dialog = false;
            "
          >
            Forgot pin?
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" flat @click="$parent.login = false">
            Cancel
          </v-btn>
          <v-btn color="success" flat @click="login"> Login </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="otpDialog" persistent width="300" height="300">
      <v-card>
        <v-card-title>Enter OTP</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            style="width: 200px; margin: 0 auto; height: 60px"
            label="OTP"
            v-model="otp"
            solo
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="sendOTP(true)">Resend </v-btn>
          <v-btn color="primary" @click="verify" class="ml-2">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="forgotDialog" persistent width="300" height="300">
      <v-card>
        <v-card-title>Forgot Pin</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            label="Phone Number"
            v-model="username"
            placeholder="Enter your phone"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            flat
            @click="
              forgotDialog = false;
              $parent.login = false;
            "
          >
            Cancel
          </v-btn>
          <v-btn color="success" flat @click="forgotPin()" class="ml-2"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      apiKey: "",
      dialog: true,
      otpDialog: false,
      forgotDialog: false,
      username: "",
      otp: "",
      password: "",
      type: "recharge",
    };
  },
  methods: {
    notification(type, message) {
      this.$notify({
        group: "foo",
        title: "Important message",
        text: message,
        duration: 5000,
        type: type,
      });
    },
    async forgotPin() {
      const vm = this;
      if (vm.username === "")
        return vm.$store.dispatch("notify", {
          vm,
          message: "Phone Number Required!",
          type: "warn",
        });
      if (vm.username.length !== 10)
        return vm.$store.dispatch("notify", {
          vm,
          message: "10 Digit Phone Number Required!",
          type: "warn",
        });
      try {
        vm.$parent.loader = true;
        let res = await vm.$store.dispatch("raxios", {
          data: `?Mob=${vm.username}&message=Rstpin&source=ANDROID`,
        });
        vm.notification("success", res.data);
        vm.forgotDialog = false;
        vm.dialog = true;
        vm.$parent.loader = false;
      } catch (error) {
        vm.notification("error", error);
        vm.$parent.loader = false;
      }
    },
    login() {
      const vm = this;
      if (vm.username === "")
        return vm.$store.dispatch("notify", {
          vm,
          message: "Phone Number Required!",
          type: "warn",
        });
      if (vm.username.length !== 10)
        return vm.$store.dispatch("notify", {
          vm,
          message: "10 Digit Phone Number Required!",
          type: "warn",
        });
      if (vm.password === "")
        return vm.$store.dispatch("notify", {
          vm,
          message: "Pin Number Required!",
          type: "warn",
        });
      localStorage.loginType = 'recharge';
      let apiKey = localStorage.apiKey;
      if (apiKey == null) {
        apiKey = new Date().getTime();
        localStorage.apiKey = apiKey;
        console.log("Api key not found");
      }
      vm.apiKey = apiKey;
      const doc = {
        MobileNo: vm.username,
        PinNo: vm.password,
        ApiAuthKey: vm.apiKey,
      };
      vm.$parent.loader = true;
      vm.$store
        .dispatch("axios", {
          url: "Authentication",
          data: doc,
        })
        .then(function (res) {
          vm.$parent.loader = false;
          let doc = res.data;
          if (doc.Status == "True") {
            let data = doc.Data[0];
            data.phone = vm.username;
            data.ApiAuthKey = apiKey;
            data.pin = vm.password;
            localStorage.user = JSON.stringify(data);
            vm.$store.dispatch("getuser");
            vm.$parent.login = false;
            location.reload();
          } else if (doc.Message == "Invalid ApiAuthKey") {
            vm.dialog = false;
            vm.otpDialog = true;
            vm.sendOTP(false);
          } else {
            vm.notification("error", doc.Message);
          }
        })
        .catch(function (error) {
          vm.notification("error", error);
          vm.$parent.loader = false;
        });
    },
    verify() {
      const vm = this;
      if (vm.otp == "") return vm.notification("error", "OTP Required");
      vm.$parent.loader = true;
      const doc = {
        MobileNo: vm.username,
        PinNo: vm.password,
        ApiAuthKey: vm.apiKey,
        OTP: vm.otp,
      };
      vm.$store
        .dispatch("axios", {
          url: "VerifyDevice",
          data: doc,
        })
        .then(function (res) {
          vm.$parent.loader = false;
          let doc = res.data;
          if (doc.Status == "True") {
            let data = {};
            data.phone = vm.username;
            data.pin = vm.password;
            data.ApiAuthKey = vm.apiKey;
            localStorage.user = JSON.stringify(data);
            vm.$store.dispatch("getuser");
            vm.$parent.login = false;
            location.reload();
          } else {
            vm.notification("error", doc.Message);
          }
        });
    },
    sendOTP(isResend) {
      const vm = this;
      vm.$parent.loader = true;
      const doc = {
        MobileNo: vm.username,
        PinNo: vm.password,
        ApiAuthKey: vm.apiKey,
      };
      vm.$store
        .dispatch("axios", {
          url: isResend ? "ResendOTP" : "SendOTP",
          data: doc,
        })
        .then(({ data }) => {
          let { Status, Message } = data;
          if (Status != "True") {
            vm.notification("error", Message);
          }
          vm.$parent.loader = false;
          console.log(data);
        })
        .catch((error) => {
          vm.notification("error", error);
          vm.$parent.loader = false;
        });
    },
  },
};
</script>

<style scoped>
.login__radio {
  display: grid;
  grid-template-columns: 50% 50%;
}
</style>
