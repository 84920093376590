<template>
  <div class="footer_wrapper">
    <div class="footer">
      <p class="headline">© {{ details.ContactName }} All Rights Reserved. version 1.00</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  computed: {
    details() {
      return this.$store.getters.details;
    },
  },
};
</script>

<style scoped>
.footer_wrapper {
  width: 100%;
  height: 80px;
  background: #080f54;
  float: left;
}
.footer {
  width: 1000px;
  margin: 0 auto;
  height: 80px;
}
.headline {
  color: white;
  margin-top: 25px;
  float: right;
  font-weight: bold;
}
</style>
