<template>
  <div>
    <v-card
      class="white--text"
      style="width:400px;margin-top:10px;float:left;margin-left:10px"
      v-for="(item, key) in offer"
      :key="'offers' + key"
    >
      <v-layout row>
        <img
          :src="item.url"
          height="125px"
          style="padding:5px;"
          @error="errorimg"
          contain
        />
        <v-card-title primary-title style="width:275px">
          <div style="overflow: hidden;">
            <div
              class="headline"
              style="color:mediumseagreen;white-space: nowrap"
            >
              {{ item.name }}
            </div>
            <div style="color:grey">{{ item.desc }}</div>
            <div style="color:black">{{ item.price }}</div>
          </div>
        </v-card-title>
      </v-layout>
      <v-divider light></v-divider>
      <v-card-actions>
        <v-btn flat color="blue">Details</v-btn>
        <v-spacer></v-spacer>

        <v-btn flat color="green">Book Now</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      offer: []
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  watch: {},
  methods: {
    errorimg(e) {
      e.target.src = require("../assets/offer.png");
    },

    fetchstatement() {
      let vm = this;
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc = { MobileNo: vm.user.phone, PinNo: vm.user.pin };
      vm.$store
        .dispatch("axios", {
          data: doc,
          url: "OfferList"
        })
        .then(function(res) {
          if (res.data.Data) {
            vm.pushstatement(res.data.Data);
          }
        })
        .catch(function(error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn"
          });
        });
    },
    pushstatement(datas) {
      let vm = this;
      vm.offer = [];
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        let doc = {
          name: data.ItemName,
          price: data.Price,
          desc: data.ItemDescription,
          url:
            "http://direct.mrechargesystem.com/ReCharge/UploadImages/" +
            data.ItemImage
        };
        vm.offer.push(doc);
      }
      vm.loading = false;
    }
  },
  mounted() {
    let vm = this;
    vm.fetchstatement();
  }
};
</script>
