<template>
    <div>
      <v-card style="width: 100%; padding: 10px">
        <v-layout row wrap style="height: 40px">
          <v-flex xs12 style="padding-top: 8px">
            <v-btn class="success searchbtn" @click="fetchstatement"
              >Search</v-btn
            >
            <v-menu
              :close-on-content-click="false"
              v-model="menu2"
              lazy
              transition="scale-transition"
              offset-y
              style="float: right"
            >
              <input
                type="text"
                slot="activator"
                v-model="date2"
                readonly
                class="search"
                placeholder="To Date"
              />
              <v-date-picker
                v-model="date2"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
              :close-on-content-click="false"
              v-model="menu1"
              lazy
              transition="scale-transition"
              offset-y
              style="float: right"
            >
              <input
                type="text"
                slot="activator"
                v-model="date1"
                readonly
                class="search"
                placeholder="From Date"
              />
              <v-date-picker
                v-model="date1"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
            <input
              type="text"
              class="search"
              style="float: left"
              v-model="search"
              placeholder="Search"
            />
          </v-flex>
          <v-layout row wrap>
            <v-spacer></v-spacer>
          </v-layout>
        </v-layout>
      </v-card>
      <v-data-table :headers="headers" :items="statementlist" :search="search">
        <template slot="items" slot-scope="props">
          <td>{{ props.item.username }}</td>
          <td>{{ props.item.amount }}</td>
          <td>{{ props.item.currentamount }}</td>
          <td>
            {{
              new Date(parseInt(props.item.time))
                | moment("DD-MM-YYYY, h:mm:ss a")
            }}
          </td>
         
          <td>{{ props.item.debittypename }}</td>
          <td>{{ props.item.remark }}</td>
          <td>{{ props.item.paytrfId }}</td>
          <td>{{ props.item.paymenttypename }}</td>         
        </template>
      </v-data-table>
      <v-dialog v-model="dialog" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Please wait
            <v-progress-linear indeterminate color="white" class="mb-0" />
          </v-card-text>
        </v-card>
      </v-dialog>
   
    </div>
  </template>
  
  <script>
  import moment from "moment";
  
  export default {
    components: {  },
    data() {
      return {
        modal: false,
        date1: moment().format("YYYY-MM-DD"),
        menu1: false,
        date2: moment().format("YYYY-MM-DD"),
        menu2: false,
        mobile: "",
        headers: [         
          { text: "UserName", value: "username", align: "left" },
          { text: "Amount", value: "amount" },
          { text: "CurrentAmount", value: "currentamount" },
          { text: "CreatedDate", value: "time" },
          { text: "DebitType", value: "debittypename" },
          { text: "Remark", value: "remark" },
          { text: "PayTrfId", value: "paytrfId" },
          { text: "Payment Type", value: "paymenttypename" }         
        ],
        dialog: false,
        viewstatus: false,
        summary: {},
        statementlist: [],
        modal: false,
        search: "",
      };
    },
    computed: {
      user() {
        return this.$store.getters.user;
      },
      dmr() {
        return this.$store.getters.dmr;
      },
    },
    watch: {
      dmr(val) {
        if (val != null) {
          this.mobile = val.mobile_no;
          this.fetchstatement();
        }
      },
    },
    methods: {

       
      notification(type, message) {
        this.$notify({
          group: "foo",
          title: "Important message",
          text: message,
          duration: 5000,
          type: type,
        });
      },
     
      pushstatement(value) {
        let vm = this;
        vm.statementlist = [];
        let pos = 0;
        let mobile = "";
        let account = "";
        for (let i = 0; i < value.length; i++) {
          let doc = value[i];
          let time = doc.CreatedDate.replace("/Date(", "").replace(")/", "");
    
         vm.statementlist.push({        
            username: doc.UserName,
            amount: doc.Amount,
            currentamount: doc.CurrentAmount,
            time: time,
            debittypename: doc.DebitTypeName,
            remark: doc.remark,
            paytrfId: doc.PayTrfId,
            paymenttypename: doc.PaymentTypeName,        
          });
        }
      },
      getsummary(doc) {
        let vm = this;
        vm.viewstatus = true;
        vm.summary = doc;
        console.log(doc);
        // vm.summary = {
        //   "Order No": doc.orderid,
        //   "Name ": doc.name,
        //   Mobile: doc.mobile,
        //   Amount: doc.amount,
        //   Status: doc.status,
        //   Time: doc.time,
        //   "Bank Transaction Id": doc.bank_txtid,
        //   "Transaction Id": doc.tid
        // };
      },
      fetchAll() {
        let vm = this;
        if (!vm.user.phone)
          return vm.notification("warn", "Authentication Required!");
        let doc = {
          MobileNo: vm.user.phone,
          pinNo: vm.user.pin,        
          StartDate: vm.date1,
          EndDate: vm.date2,         
        };
        vm.dialog = true;
        vm.$store
          .dispatch("axios", {
            url: "MydownLinePaymentDate",
            data: doc,
          })
          .then(function ({ data }) {
            vm.dialog = false;
            if (data.Data) {
              vm.pushstatement(data.Data);
            }
          })
          .catch(function (error) {
            vm.notification("error", error.message);
            vm.dialog = false;
          });
      },
      fetchstatement() {
        let vm = this;
        if (!vm.user.phone)
          return vm.notification("warn", "Authentication Required!");
        vm.fetchAll();
        
      },
    },
    mounted() {
      let vm = this;
      if (vm.dmr !== null) {
        vm.mobile = vm.dmr.mobile_no;
      }
      vm.fetchstatement();
    },
  };
  </script>
  
  <style scoped>
  .search {
    width: 150px;
    float: right;
    height: 34px;
    background: white;
    border: 1px solid lightgray;
    outline: none;
    margin-left: 20px;
    padding-left: 10px;
    font-size: 1em;
  }
  .searchbtn {
    margin-right: 15px;
    height: 34px;
    margin-left: 20px;
    float: right;
    margin-top: 0px;
  }
  </style>
  