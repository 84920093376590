<template>
  <div>
    <div v-if="type">
      <v-toolbar dense style="color:white;font-weight:bold">
        Downline Outstaning
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <download-excel
            :data="statementlist"
            name="Downlineoutstanding.xls"
            :fields="fields"
            style="height:30px;margin-top:9px;margin-right:10px"
          >
            <v-btn class="success" small>Export</v-btn>
          </download-excel>
          <v-btn small class="success" style="height:30px;" @click="adddown = true">ADD</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table :headers="headers" :items="statementlist" hide-actions>
        <template slot="items" slot-scope="props">
          <td>{{ props.item.child }}</td>
          <td v-if="props.item.out > 0">
            {{
            props.item.out
            .toString()
            .split("+")
            .join("") + " (DR)"
            }}
          </td>
          <td v-else>
            {{
            props.item.out
            .toString()
            .split("-")
            .join("") + " (CR)"
            }}
          </td>
          <td>{{ props.item.com }}</td>
          <td>{{ props.item.parent }}</td>
          <td>{{ props.item.type }}</td>
          <td>
            <v-btn small class="success" @click="openadjustment(props.item)">Adjustment</v-btn>
            <v-btn small class="success" @click="opencollection(props.item)">Collection</v-btn>
            <v-btn small class="success" @click="fetchstatement2(props.item.id)">Statement</v-btn>
            <v-btn small class="success" @click="opentransfer(props.item)">Transfer</v-btn>
          </td>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <v-toolbar dense style="color:white;font-weight:bold">
        <v-btn
          class="error searchbtn"
          style="height:34px;margin-top:4px;margin-left:0px"
          @click="type = true"
        >Close</v-btn>Downline Statement
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-menu
            :close-on-content-click="false"
            v-model="menu1"
            lazy
            transition="scale-transition"
            offset-y
            style="float:right"
          >
            <input
              type="text"
              slot="activator"
              style="color:black"
              v-model="date1"
              readonly
              class="search"
              placeholder="From Date"
            />
            <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
          </v-menu>
          <v-menu
            :close-on-content-click="false"
            v-model="menu2"
            lazy
            transition="scale-transition"
            offset-y
            style="float:right"
          >
            <input
              type="text"
              slot="activator"
              style="color:black"
              v-model="date2"
              readonly
              class="search"
              placeholder="To Date"
            />
            <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
          </v-menu>
          <v-btn
            class="success searchbtn"
            style="height:34px;margin-left:10px"
            @click="fetchstatement2"
          >Search</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table :headers="headers2" :items="statementlist2" hide-actions>
        <template slot="items" slot-scope="props">
          <td>{{ new Date(parseInt(props.item.date)) | moment("DD-MM-YYYY") }}</td>
          <td>{{ props.item.pname }}</td>
          <td>{{ props.item.cname }}</td>
          <td>{{ props.item.ttype }}</td>
          <td v-if="props.item.openbalance > 0">
            {{
            props.item.openbalance
            .toString()
            .split("+")
            .join("") + " (DR)"
            }}
          </td>
          <td v-else>
            {{
            props.item.openbalance
            .toString()
            .split("-")
            .join("") + " (CR)"
            }}
          </td>
          <td v-if="props.item.closebalance > 0">
            {{
            props.item.closebalance
            .toString()
            .split("+")
            .join("") + " (DR)"
            }}
          </td>
          <td v-else>
            {{
            props.item.closebalance
            .toString()
            .split("-")
            .join("") + " (CR)"
            }}
          </td>
          <td>{{ props.item.amount }}</td>
          <td>{{ props.item.crdr }}</td>
          <td>{{ props.item.paymentrid }}</td>
          <td>{{ props.item.paymenttid }}</td>
          <td>{{ props.item.remark }}</td>
        </template>
      </v-data-table>
    </div>
    <AddDownline v-if="adddown" />
    <Progress v-if="progress" />
    <Message v-if="message" :res="res" />
    <Adjust v-if="adjust" :vdata="data" />
    <Transfer v-if="transfer" :vdata="data" />
    <Collection v-if="collection" :vdata="data" />
  </div>
</template>

<script>
import Progress from "../components/Progress.vue";
import Message from "../components/Message.vue";
import Adjust from "./Adjust.vue";
import AddDownline from "./AddDownline.vue";
import Transfer from "./Transfer.vue";
import Collection from "./Collection.vue";
export default {
  data() {
    return {
      modal: false,
      date1: "",
      menu1: false,
      res: "",
      progress: false,
      date2: "",
      menu2: false,
      id: "",
      headers: [
        { text: "Child Name", value: "" },
        { text: "Outstanding", value: "" },
        { text: "Commission", value: "" },
        { text: "Parent Name", value: "" },
        { text: "User Type", value: "" },
        { text: "Action", value: "" }
      ],
      statementlist: [],
      headers2: [
        { text: "Created On", value: "outstanding" },
        { text: "Parent Name", value: "balance" },
        { text: "Child Name", value: "balance" },
        { text: "Trans Type", value: "balance" },
        { text: "Opening Balance", value: "balance" },
        { text: "Amount", value: "balance" },
        { text: "Closing Balance", value: "balance" },
        { text: "CR/DR", value: "balance" },
        { text: "Payment Request ID", value: "balance" },
        { text: "Payment Transaction ID", value: "balance" },
        { text: "Remark", value: "balance" }
      ],
      fields: {
        "Created On": "outstanding",
        "Parent Name": "balance",
        "Child Name": "balance",
        "Trans Type": "balance",
        "Opening Balance": "balance",
        Amount: "balance",
        "Closing Balance": "balance",
        "CR/DR": "balance",
        "Payment Request ID": "balance",
        "Payment Transaction ID": "balance",
        Remark: "balance"
      },
      statementlist2: [],
      adddown: false,
      message: false,
      type: true,
      adjust: false,
      transfer: false,
      collection: false,
      data: []
    };
  },
  components: {
    AddDownline,
    Progress,
    Message,
    Adjust,
    Collection,
    Transfer
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    openadjustment(data) {
      let vm = this;
      vm.data = data;
      vm.adjust = true;
    },
    opentransfer(data) {
      let vm = this;
      vm.data = data;
      vm.transfer = true;
    },
    opencollection(data) {
      let vm = this;
      vm.data = data;
      vm.collection = true;
    },
    fetchstatement() {
      let vm = this;
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc = { MobileNo: vm.user.phone, PinNo: vm.user.pin, Cid: "" };
      let url =
        vm.$root.loginType === "dmr"
          ? "GetDownlineOutstandingListDMR"
          : "GetDownlineOutstandingList";
      vm.$store
        .dispatch("axios", {
          data: doc,
          url: url
        })
        .then(function(res) {
          if (res.data.Data) {
            vm.pushstatement(res.data.Data);
          }
        })
        .catch(function(error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn"
          });
        });
    },
    fetchstatement2(id) {
      let vm = this;
      vm.id = id;
      vm.type = false;

      vm.statementlist2 = [];
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        Pid: vm.id,
        StartDate: vm.date1,
        EndDate: vm.date2
      };
      let url =
        vm.$root.loginType === "dmr"
          ? "GetMyOutstandingStatementDMR"
          : "GetMyOutstandingStatement";
      vm.$store
        .dispatch("axios", {
          data: doc,
          url: url
        })
        .then(function(res) {
          if (res.data.Data) {
            vm.pushstatement2(res.data.Data);
          }
        })
        .catch(function(error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn"
          });
        });
    },
    pushstatement(datas) {
      let vm = this;
      vm.statementlist = [];
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        let doc = {
          child: data.ChildUsername,
          out: data.Outstatding,
          com: data.AdvanceCommision,
          parent: data.ParentUsername,
          type: data.Type,
          id: data.ParentId,
          cid: data.ChildId,
          phone: data.MobileNo
        };
        vm.statementlist.push(doc);
      }
      vm.loading = false;
    },
    pushstatement2(datas) {
      let vm = this;
      vm.statementlist2 = [];
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        let doc = {
          date: data.DateTime.replace("/Date(", "").replace(")/", ""),
          pname: data.ParentUsername,
          cname: data.ChildUsername,
          ttype: data.BalanceTrnsType,
          openbalance: data.OpeningBal,
          amount: data.Amount,
          closebalance: data.ClosingBal,
          crdr: data.CrDr,
          paymentrid: data.PaysReqId,
          paymenttid: data.PayTrnsId,
          remark: data.Remark
        };
        vm.statementlist2.push(doc);
      }
      vm.loading = false;
    }
  },
  mounted() {
    let vm = this;
    vm.fetchstatement();
  }
};
</script>

<style scoped>
.search {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
