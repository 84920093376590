<template>
  <div>
    <v-layout>
      <v-flex>
        <v-card style="width: 700px; margin-top: 20px" class="center">
          <img
            src="../assets/profile.svg"
            width="100px"
            style="margin-left: 20px; margin-top: 20px; float: left"
            alt=""
          />
          <v-list two-line>
            <v-list-tile
              style="height: 55px; margin-top: 10px; width: 240px; float: left"
              ><v-list-tile-content style="width: 240px"
                ><v-list-tile-title> First Name </v-list-tile-title
                ><v-list-tile-sub-title>{{
                  format(profile.FirstName) || " ."
                }}</v-list-tile-sub-title></v-list-tile-content
              ></v-list-tile
            >
            <v-list-tile style="height: 55px; margin-top: 10px"
              ><v-list-tile-content style="width: 240px"
                ><v-list-tile-title>Last Name</v-list-tile-title
                ><v-list-tile-sub-title>{{
                  format(profile.LastName) || " ."
                }}</v-list-tile-sub-title></v-list-tile-content
              ></v-list-tile
            >
            <v-list-tile style="height: 55px; width: 240px; float: left"
              ><v-list-tile-content style="width: 240px"
                ><v-list-tile-title>Mobile Number</v-list-tile-title
                ><v-list-tile-sub-title>{{
                  profile.MobileNo
                }}</v-list-tile-sub-title></v-list-tile-content
              ></v-list-tile
            >
            <v-list-tile style="height: 55px"
              ><v-list-tile-content
                ><v-list-tile-title>Username</v-list-tile-title
                ><v-list-tile-sub-title>{{
                  profile.UserName
                }}</v-list-tile-sub-title></v-list-tile-content
              ></v-list-tile
            >
          </v-list>
          <v-card-title style="padding: 0px">
            <div style="width: 330px; float: left">
              <v-list two-line>
                <v-list-tile style="height: 55px"
                  ><v-list-tile-content
                    ><v-list-tile-title>Mail ID</v-list-tile-title
                    ><v-list-tile-sub-title>{{
                      profile.Email
                    }}</v-list-tile-sub-title></v-list-tile-content
                  ></v-list-tile
                >
                <v-list-tile style="height: 55px"
                  ><v-list-tile-content
                    ><v-list-tile-title>Address</v-list-tile-title
                    ><v-list-tile-sub-title>{{
                      profile.Address
                    }}</v-list-tile-sub-title></v-list-tile-content
                  ></v-list-tile
                >
                <v-list-tile style="height: 55px"
                  ><v-list-tile-content
                    ><v-list-tile-title>State</v-list-tile-title
                    ><v-list-tile-sub-title>{{
                      state
                    }}</v-list-tile-sub-title></v-list-tile-content
                  ></v-list-tile
                >
                <v-list-tile style="height: 55px"
                  ><v-list-tile-content
                    ><v-list-tile-title>City</v-list-tile-title
                    ><v-list-tile-sub-title>{{
                      city
                    }}</v-list-tile-sub-title></v-list-tile-content
                  ></v-list-tile
                >
              </v-list>
            </div>
            <div style="width: 330px; float: left">
              <v-list two-line>
                <v-list-tile style="height: 55px"
                  ><v-list-tile-content
                    ><v-list-tile-title>Company Name</v-list-tile-title
                    ><v-list-tile-sub-title>{{
                      profile.CompanyName
                    }}</v-list-tile-sub-title></v-list-tile-content
                  ></v-list-tile
                >
                <v-list-tile style="height: 55px"
                  ><v-list-tile-content
                    ><v-list-tile-title>Aadhaar Number</v-list-tile-title
                    ><v-list-tile-sub-title>{{
                      profile.AddharNo
                    }}</v-list-tile-sub-title>
                  </v-list-tile-content></v-list-tile
                >
                <v-list-tile style="height: 55px"
                  ><v-list-tile-content
                    ><v-list-tile-title>PAN Number</v-list-tile-title
                    ><v-list-tile-sub-title>{{
                      profile.PanNo
                    }}</v-list-tile-sub-title></v-list-tile-content
                  ></v-list-tile
                >
                <v-list-tile style="height: 55px"
                  ><v-list-tile-content
                    ><v-list-tile-title>GST Number</v-list-tile-title
                    ><v-list-tile-sub-title>{{
                      profile.GSTNo
                    }}</v-list-tile-sub-title></v-list-tile-content
                  ></v-list-tile
                >
              </v-list>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-btn flat color="red" @click="logout()">LOGOUT</v-btn>
            <v-btn
              flat
              @click="edit = true"
              color="blue"
              style="margin-left: 470px"
              >Edit Profile</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <EditProfile v-if="edit == true" />
  </div>
</template>
<script>
import EditProfile from "./EditProfile.vue";
export default {
  components: {
    EditProfile,
  },
  data() {
    return {
      edit: false,
      profile: {},
      state: "",
      city: "",
    };
  },
  computed: {
    getprofile() {
      return this.$store.getters.profile.Data;
    },
    user() {
      return this.$store.getters.user;
    },
    statelist() {
      return this.$store.getters.statelist;
    },
    citylist() {
      return this.$store.getters.citylist;
    },
  },
  watch: {
    getprofile() {
      let vm = this;
      this.profile = this.getprofile;
      vm.fetchprofile();
    },
    statelist() {
      let vm = this;
      if (vm.profile != null) {
        vm.fetchstate();
      }
    },
    citylist() {
      let vm = this;
      if (vm.profile != null) {
        vm.fetchcity();
      }
    },
  },
  methods: {
    fetchstate() {
      let vm = this;
      let docs = vm.statelist.Data;
      if (docs == null || docs.length == 0) return;
      for (let i = 0; i < docs.length; i++) {
        if (docs[i].Id == vm.profile.StateId) {
          vm.state = docs[i].Name;
        }
      }
    },
    fetchcity() {
      let vm = this;
      let docs = vm.citylist.Data;
      if (docs == null || docs.length == 0) return;
      for (let i = 0; i < docs.length; i++) {
        if (docs[i].Id == vm.profile.CityId) {
          vm.city = docs[i].Name;
        }
      }
    },
    fetchprofile() {
      let vm = this;
      vm.profile = vm.getprofile;
      console.log("profile", vm.profile);
      if (vm.profile.StateId != null) {
        let ldoc = {
          PinNo: vm.user.pin,
          MobileNo: vm.user.phone,
          StateId: vm.profile.StateId,
        };
        vm.$store.dispatch("axiosget", {
          url: "citylist",
          data: ldoc,
          way: "setcitylist",
        });
      }
    },
    format(val) {
      if (val != null) return val.toUpperCase();
      return "";
    },
    logout() {
      localStorage.removeItem("user");
      window.location.reload();
    },
  },
  mounted() {
    let vm = this;
    if (vm.getprofile != null && vm.getprofile.length != 0) {
      vm.fetchprofile();
    }
    if (vm.statelist.length != 0) {
      vm.fetchstate();
    }
    if (vm.citylist.length != 0) {
      vm.fetchstate();
    }
  },
};
</script>

<style>
.center {
  margin-left: auto;
  margin-right: auto;
}
</style>
