<template>
  <div>
    <div
      style="width: 100%; text-align: center; margin-top: 15px; height: 50px"
      class="headline"
    >
      Dish
    </div>
    <v-autocomplete
      v-model="operator"
      :items="sortedproviver"
      label="Select Operator"
      style="margin: 0 auto; height: 50px"
      chips
      item-text="name"
      item-value="name"
    >
      <template slot="selection" slot-scope="data">
        <v-chip :selected="data.selected" close class="chip--select-multi">
          <v-avatar>
            <img :src="data.item.avatar" @error="errorimg" />
          </v-avatar>
          {{ data.item.name }}
        </v-chip>
      </template>
      <template slot="item" slot-scope="data">
        <template>
          <v-list-tile-avatar>
            <img :src="data.item.avatar" @error="errorimg" />
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile v-html="data.item.name"></v-list-tile>
          </v-list-tile-content>
        </template>
      </template>
    </v-autocomplete>
    <div stle="width:100%;float:left">
      <v-btn
        small
        class="primary mt-3"
        depressed
        round
        style="
          float: right;
          font-size: 0.8em;
          padding-left: 15px;
          padding-right: 15px;
        "
        @click="fetchplan"
        >Browse Plan</v-btn
      >
    </div>

    <v-text-field
      v-model="rechargernumber"
      style="margin: 0 auto; height: 50px; width: 100%"
      label="Enter Customer ID"
    ></v-text-field>
    <div stle="width:100%;float:left">
      <v-btn
        small
        class="primary"
        depressed
        round
        style="
          float: right;
          font-size: 0.8em;
          padding-left: 15px;
          padding-right: 15px;
        "
        @click="fetchcustomer"
        >Customer Info</v-btn
      >
    </div>
    <v-text-field
      v-model="rechargeramount"
      style="margin: 0 auto; height: 60px; width: 100%"
      label="Enter Recharge Amount"
    />
    <div style="width: 100%; float: left">
      <v-btn
        class="light-blue lighten-1"
        dark
        style="width: 100%; margin: 0 auto"
        @click="submit"
        >Submit</v-btn
      >
    </div>
    <DTHPlans v-if="showplan" :plan="plan" :addon="addon" />
    <v-dialog v-model="view" persistent>
      <v-card color="#01579B">
        <v-card-title style="height: 40px; color: white">
          <span class="body-2">Confirm Details</span>
        </v-card-title>
        <v-card-text style="padding-top: 0px">
          <v-layout row wrap v-for="(item, key) in summary" :key="key + item">
            <v-flex xs6>
              <v-card-text
                class="px-0 title"
                style="color: white; padding-top: 5px; padding-bottom: 8px"
                >{{ key }}</v-card-text
              >
            </v-flex>
            <v-flex xs6>
              <v-card-text
                class="px-0 title"
                style="color: white; padding-top: 5px; padding-bottom: 8px"
                >{{ item }}</v-card-text
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions style="width: 100%">
          <v-spacer></v-spacer>
          <v-btn small class="error" @click="view = false">Cancel</v-btn>
          <v-btn small class="success" @click="view = false">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DTHPlans from "../plan/DTHPlans.vue";
export default {
  data() {
    return {
      operator: "",
      provider: [""],
      rechargernumber: "",
      rechargeramount: "",
      addon: [],
      showplan: false,
      plan: [],
      view: false,
      summary: [],
    };
  },
  components: {
    DTHPlans,
  },
  computed: {
    getprovider() {
      return this.$store.getters.operator;
    },
    rwidth() {
      return this.$store.getters.rwidth;
    },
    user() {
      return this.$store.getters.user;
    },
    clear() {
      return this.$store.getters.clear;
    },
    sortedproviver() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.provider.sort(compare);
    },
  },
  watch: {
    getprovider() {
      let vm = this;
      vm.pushoperator();
    },
    clear() {
      this.cleardata();
    },
  },
  methods: {
    cleardata() {
      let vm = this;
      vm.operator = "";
      vm.rechargernumber = "";
      vm.rechargeramount = "";
    },
    errorimg(e) {
      e.target.src = require("../assets/logo_single.png");
    },
    fetchplan() {
      let vm = this;
      let url = "DTH";
      if (vm.operator == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Operator Required",
          type: "error",
        });
      let doc = "operator=" + vm.changeprovider(vm.operator);
      vm.$parent.progress = true;
      vm.$store
        .dispatch("planapi", {
          url: url,
          data: doc,
        })
        .then(function (res) {
          vm.addon = [];
          vm.plan = [];
          vm.showplan = true;
          if (
            res.data.records != null &&
            res.data.records["Add-On Pack"] != null
          ) {
            vm.addon = res.data.records["Add-On Pack"];
          }
          if (res.data.records && res.data.records["Plan"]) {
            vm.plan = res.data.records["Plan"];
          }
          vm.$parent.progress = false;
          vm.showplan = true;
        })
        .catch(function (error) {
          vm.$parent.progress = false;
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error.message,
            type: "error",
          });
        });
    },
    fetchcustomer() {
      let vm = this;
      //  3021194791;
      if (vm.operator == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Operator Required",
          type: "error",
        });
      if (vm.rechargernumber == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Customer ID Required",
          type: "error",
        });
      let url = "DTH_CUSTOMER_INFO";
      let doc =
        "offer=roffer|tel=" +
        vm.rechargernumber +
        "|operator=" +
        vm.changeproviderinfo(vm.operator);
      vm.$parent.progress = true;
      vm.$store
        .dispatch("planapi", {
          url: url,
          data: doc,
        })
        .then(function (res) {
          vm.summary = [];
          if (res.data.records.length > 0) {
            let doc = res.data.records[0];
            vm.summary = {
              Name: doc.customerName,
              "Customer ID": vm.rechargernumber,
              Operator: vm.operator,
              Status: doc.status,
              "Monthly Recharge": doc.MonthlyRecharge,
              Balance: doc.Balance,
              "Next Recharge Date": doc.NextRechargeDate,
              "Plan Name": doc.planname,
            };
            vm.view = true;
          } else {
            vm.$store.dispatch("notify", {
              vm: vm,
              message: "No Record Found",
              type: "warn",
            });
          }
          vm.$parent.progress = false;
        })
        .catch(function (error) {
          vm.$parent.progress = false;
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error.message,
            type: "error",
          });
        });
    },

    pushoperator() {
      let vm = this;
      vm.provider = [];
      let path = this.$store.getters.path;
      for (let i = 0; i < vm.getprovider.length; i++) {
        let doc = vm.getprovider[i];
        if (doc.RechargeTypeName.toLowerCase() == "dth") {
          let data = {
            avatar: path + doc.CircleImg,
            name: doc.ServiceName,
          };
          vm.provider.push(data);
        }
      }
    },
    changeprovider(provider) {
      if (provider == "AirtelDTH") return "Airtel DTH";
      if (provider == "DishTV") return "Dish TV";
      if (provider == "RelianceBigTv") return "RelianceBigTv";
      if (provider == "SunDirect") return "Sun Direct";
      if (provider == "TataSky") return "Tata Sky";
      if (provider == "VideoconD2H") return "Videocon";
      return provider;
    },
    changeproviderinfo(provider) {
      if (provider == "AirtelDTH") return "Airteldth";
      if (provider == "DishTV") return "Dishtv";
      if (provider == "SunDirect") return "Sundirect";
      if (provider == "TataSky") return "TataSky";
      if (provider == "VideoconD2H") return "Videocon";
      return provider;
    },
    submit() {
      let vm = this;
      if (vm.user.length == 0)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Authentication Required!",
          type: "warn",
        });
      if (vm.operator == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Operator Required",
          type: "error",
        });
      if (vm.rechargernumber == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Customer ID Required",
          type: "error",
        });
      if (vm.rechargeramount == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Recharge Amount Required",
          type: "error",
        });
      let params =
        "?Mob=" +
        vm.user.phone +
        "&message=DTH+" +
        vm.operator +
        "+" +
        vm.rechargernumber +
        "+" +
        vm.rechargeramount +
        "+" +
        vm.user.pin +
        "&source=ANDROID";
      let data = {
        Operator: vm.operator,
        "Customer ID": vm.rechargernumber,
        "Recharge Amount": vm.rechargeramount,
      };
      vm.$parent.sync = {
        title: vm.operator,
        subtitle: vm.rechargernumber,
        status: "process",
      };
      vm.$parent.data = data;
      vm.$parent.params = params.toString();
      vm.$parent.preview = true;
    },
  },
  mounted() {
    let vm = this;
    if (vm.getprovider.length != 0) {
      vm.pushoperator();
    }
  },
};
</script>
