<template>
  <v-dialog v-model="dialog" persistent width="800" height="500">
    <v-card>
      <v-card-title style="padding:10px;"
        >Browse Plans
        <v-spacer />
        <v-btn style="margin:0px;" @click="close" class="error">Close</v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table :headers="headers" :items="planlist" hide-actions>
        <template slot="items" slot-scope="props">
          <td>
            <v-btn
              v-if="props.item.rs"
              @click="select(props.item.rs)"
              style="margin:0px;"
              small
              color="primary"
              >SELECT</v-btn
            >
          </td>
          <td>{{ props.item.rs || "" }}</td>
          <td>{{ props.item.validity || "" }}</td>
          <td>{{ props.item.desc || "" }}</td>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["model", "pplans"],
  data() {
    return {
      plans: [],
      dialog: false,
      planlist: [],
      headers: [
        { text: "Action", value: "action" },
        { text: "Rs", value: "rs", align: "left", sortable: false },
        { text: "Validity", value: "validity" },
        { text: "Description", value: "desc" }
      ]
    };
  },
  methods: {
    select(rs) {
      let vm = this;
      vm.dialog = false;
      vm.planlist = [];
      vm.$parent.showoffer = false;
      vm.$parent.rechargeramount = rs;
    },
    close() {
      let vm = this;
      vm.dialog = false;
      vm.$parent.showoffer = false;
      vm.planlist = [];
    }
  },
  mounted() {
    let vm = this;
    vm.dialog = false;
    vm.dialog = vm.model;
    vm.planlist = vm.pplans;
  }
};
</script>
