<template>
  <v-dialog v-model="dialog" max-width="400px" scrollable persistent>
    <v-card>
      <v-card-title class="d-block pa-0">
        <div class="d-flex pa-3 align-center">
          <div class="title">Child List</div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            tile
            class="red--text ma-0"
            style="max-width: 30px"
            @click="dialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <v-divider></v-divider>
        <div class="px-3 py-3">
          <v-text-field
            v-model="search"
            clearable
            hide-details
            solo
            label="Search"
          ></v-text-field>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0" style="height: 500px">
        <v-list two-line class="ma-0 pa-0">
          <template v-for="(item, i) in filteredList">
            <v-list-tile :key="i">
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ item.FirstName }} {{ item.LastName }} ({{ item.UserName }})
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  {{ item.MobileNo }}
                </v-list-tile-sub-title>
                <v-list-tile-sub-title>
                 Balance : {{ item.Balance }}
                </v-list-tile-sub-title>
                <v-list-tile-sub-title>
                 CompanyName : {{ item.CompanyName }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn small flat class="secondary" @click="initTransfer(item)">
                  Transfer</v-btn
                >
              </v-list-tile-action>
            </v-list-tile>
            <v-divider :key="'divider-' + i"></v-divider>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      list: [],
      search: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    filteredList() {
      return this.list.filter((item) => {
        let search = this.search.trim().toLowerCase();
        let { FirstName, LastName, UserId, MobileNo, UserName } = item;
        FirstName = FirstName || "";
        LastName = LastName || "";
        UserId = UserId || "";
        MobileNo = MobileNo || "";
        UserName = UserName || "";
        if (!search) return true;
        return (
          FirstName.toLowerCase().includes(search) ||
          LastName.toLowerCase().includes(search) ||
          UserId.toString().toLowerCase().includes(search) ||
          MobileNo.toString().toLowerCase().includes(search) ||
          UserName.toLowerCase().includes(search)
        );
      });
    },
  },
  methods: {
    async getChildList() {
      const vm = this;
      try {
        vm.$loader.show();
        let doc = {
          MobileNo: vm.user.phone,
          PinNo: vm.user.pin,
          UserId: "",
          UserMobileNo: "",
        };
        let { Data, Status } = (
          await vm.$store.dispatch("axiosget", {
            url: "/GetchiledTree",
            data: doc,
          })
        ).data;
        if (Status == "True") {
          vm.list = Data;
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    initTransfer(item) {
      this.$emit("onSelect", item.MobileNo);
      this.dialog = false;
    },
  },
  mounted() {
    this.getChildList();
  },
};
</script>

<style>
</style>