<template>
  <div v-if="type">
    <v-toolbar class="secondary" dense style="color:white;font-weight:bold">
      My Outstaning
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <download-excel
          :data="statementlist"
          name="Myoutstanding.xls"
          :fields="fields"
          style="height:30px;margin-top:9px;margin-right:10px"
        >
          <v-btn class="success" small>Export</v-btn>
        </download-excel>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table :headers="headers" :items="statementlist" hide-actions>
      <template slot="items" slot-scope="props">
        <td>{{ props.item.outstanding }}</td>
        <td v-if="props.item.balance > 0">
          {{
          props.item.balance
          .toString()
          .split("+")
          .join("") + " (DR)"
          }}
        </td>
        <td v-else>
          {{
          props.item.balance
          .toString()
          .split("-")
          .join("") + " (CR)"
          }}
        </td>
        <td>
          <v-btn small class="success" @click="fetchstatement2(props.item.id)">Statement</v-btn>
        </td>
      </template>
    </v-data-table>
  </div>
  <div v-else>
    <v-toolbar class="secondary" dense style="color:white;font-weight:bold">
      <v-btn
        class="error searchbtn"
        style="height:34px;margin-top:4px;margin-left:0px"
        @click="type = true"
      >Close</v-btn>Outstanding Statement
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-menu
          :close-on-content-click="false"
          v-model="menu1"
          lazy
          transition="scale-transition"
          offset-y
          style="float:right"
        >
          <input
            type="text"
            slot="activator"
            style="color:black"
            v-model="date1"
            readonly
            class="search"
            placeholder="From Date"
          />
          <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
        </v-menu>
        <v-menu
          :close-on-content-click="false"
          v-model="menu2"
          lazy
          transition="scale-transition"
          offset-y
          style="float:right"
        >
          <input
            type="text"
            slot="activator"
            style="color:black"
            v-model="date2"
            readonly
            class="search"
            placeholder="To Date"
          />
          <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
        </v-menu>
        <v-btn
          class="success searchbtn"
          style="height:34px;margin-left:10px"
          @click="fetchstatement2"
        >Search</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table :headers="headers2" :items="statementlist2" hide-actions>
      <template slot="items" slot-scope="props">
        <td>{{ new Date(parseInt(props.item.date)) | moment("DD-MM-YYYY") }}</td>
        <td>{{ props.item.pname }}</td>
        <td>{{ props.item.cname }}</td>
        <td>{{ props.item.ttype }}</td>
        <td v-if="props.item.openbalance > 0">
          {{
          props.item.openbalance
          .toString()
          .split("+")
          .join("") + " (DR)"
          }}
        </td>
        <td v-else>
          {{
          props.item.openbalance
          .toString()
          .split("-")
          .join("") + " (CR)"
          }}
        </td>
        <td v-if="props.item.closebalance > 0">
          {{
          props.item.closebalance
          .toString()
          .split("+")
          .join("") + " (DR)"
          }}
        </td>
        <td v-else>
          {{
          props.item.closebalance
          .toString()
          .split("-")
          .join("") + " (CR)"
          }}
        </td>
        <td>{{ props.item.amount }}</td>
        <td>{{ props.item.crdr }}</td>
        <td>{{ props.item.paymentrid }}</td>
        <td>{{ props.item.paymenttid }}</td>
        <td>{{ props.item.remark }}</td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      //         date1:new Date().toISOString().substr(0, 10),
      date1: "",
      menu1: false,
      date2: "",
      //         date2:new Date().toISOString().substr(0, 10),
      menu2: false,
      id: "",
      type: true,
      headers: [
        { text: "Outstanding With", value: "outstanding" },
        { text: "Balance", value: "balance" },
        { text: "Statement", value: "statement" }
      ],
      fields: {
        "Outstanding With": "outstanding",
        Balance: "balance",
        Statement: "statement"
      },
      statementlist: [],
      headers2: [
        { text: "Created On", value: "outstanding" },
        { text: "Parent Name", value: "balance" },
        { text: "Child Name", value: "balance" },
        { text: "Trans Type", value: "balance" },
        { text: "Opening Balance", value: "balance" },
        { text: "Amount", value: "balance" },
        { text: "Closing Balance", value: "balance" },
        { text: "CR/DR", value: "balance" },
        { text: "Payment Request ID", value: "balance" },
        { text: "Payment Transaction ID", value: "balance" },
        { text: "Remark", value: "balance" }
      ],
      statementlist2: []
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    fetchstatement() {
      let vm = this;
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc = { MobileNo: vm.user.phone, PinNo: vm.user.pin };
      let url =
        vm.$root.loginType === "dmr"
          ? "OutstadingBalanceDMR"
          : "OutstadingBalance";

      vm.$store
        .dispatch("axios", {
          data: doc,
          url: url
        })
        .then(function(res) {
          if (res.data.Data) {
            vm.pushstatement(res.data.Data);
          }
        })
        .catch(function(error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn"
          });
        });
    },
    fetchstatement2(id) {
      let vm = this;
      vm.id = id;
      vm.type = false;
      vm.statementlist2 = [];
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        Pid: vm.id,
        StartDate: vm.date1,
        EndDate: vm.date2
      };
      let url =
        vm.$root.loginType === "dmr"
          ? "GetMyOutstandingStatementDMR"
          : "GetMyOutstandingStatement";
      vm.$store
        .dispatch("axios", {
          data: doc,
          url: url
        })
        .then(function(res) {
          if (res.data.Data) {
            vm.pushstatement2(res.data.Data);
          }
        })
        .catch(function(error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn"
          });
        });
    },
    pushstatement(datas) {
      let vm = this;
      vm.statementlist = [];
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        let doc = {
          outstanding: data.ParentUsername,
          balance: data.Outstatding,
          id: data.ParentId
        };
        vm.statementlist.push(doc);
      }
      vm.loading = false;
    },
    pushstatement2(datas) {
      let vm = this;
      vm.statementlist2 = [];
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        let doc = {
          date: data.DateTime.replace("/Date(", "").replace(")/", ""),
          pname: data.ParentUsername,
          cname: data.ChildUsername,
          ttype: data.BalanceTrnsType,
          openbalance: data.OpeningBal,
          amount: data.Amount,
          closebalance: data.ClosingBal,
          crdr: data.CrDr,
          paymentrid: data.PaysReqId,
          paymenttid: data.PayTrnsId,
          remark: data.Remark
        };
        vm.statementlist2.push(doc);
      }
      vm.loading = false;
    }
  },
  mounted() {
    let vm = this;
    vm.fetchstatement();
  }
};
</script>

<style scoped>
.search {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
