<template>
  <div>
    <v-tabs
      next-icon="arrow_forward_ios"
      prev-icon="arrow_back_ios"
      show-arrows
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        class="primary--text"
        v-for="(tab, i) in tabs"
        :key="i + 'tab'"
        @click="way = tab.way"
      >
        <img :src="tab.img" :width="tab.width" />
        &nbsp; &nbsp;{{ tab.name }}
      </v-tab>
    </v-tabs>
    <Offer v-show="way == 'offer'" />
  </div>
</template>

<script>
import Offer from "../offer/Offer.vue";
export default {
  components: {
    Offer
  },
  data() {
    return {
      way: "offer",
      tabs: [
        {
          name: "Shopping Offer",
          img: require("../assets/voucher.png"),
          way: "offer",
          width: 25
        },
        {
          name: "Shopping List",
          img: require("../assets/cart.png"),
          way: "cart",
          width: 25
        }
      ]
    };
  },
  mounted() {
    let height = window.innerHeight - 210;
    let v_table = document.getElementsByClassName("v-table__overflow");
    for (let i = 0; i < v_table.length; i++) {
      v_table[i].style.height = height + "px";
    }
  }
};
</script>
