import { render, staticRenderFns } from "./DMRHome.vue?vue&type=template&id=3a84331c&scoped=true&"
import script from "./DMRHome.vue?vue&type=script&lang=js&"
export * from "./DMRHome.vue?vue&type=script&lang=js&"
import style0 from "./DMRHome.vue?vue&type=style&index=0&id=3a84331c&prod&lang=css&"
import style1 from "./DMRHome.vue?vue&type=style&index=1&id=3a84331c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a84331c",
  null
  
)

export default component.exports