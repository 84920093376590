<template>
  <v-app>
    <v-content>
      <notifications group="foo"/>
      <Loader></Loader>
      <Alert></Alert>
      <Confirm></Confirm>
      <Dialog/>
      <Website
          v-if="path === 'website'"
          style="background-color: white"
      ></Website>
      <Main style="background-color: #f0f0f0" v-else></Main>
    </v-content>
  </v-app>
</template>
<script>
import Main from "./pages/Main.vue";
import Website from "./pages/Website.vue";
import Dialog from "./components/Dialog";
import Loader from "./components/loader/Loader.vue";
import Confirm from "./components/confirm/Confirm.vue";
import Alert from "./components/alert/Alert.vue";

export default {
  name: "App",
  components: {
    Website,
    Main,
    Dialog,
    Loader,
    Confirm,
    Alert,
  },
  data() {
    return {
      home: false,
    };
  },
  computed: {
    path() {
      return this.$store.getters.way;
    },
    preview() {
      return this.$store.getters.preview;
    },
    user() {
      return this.$store.getters.user;
    },

    loader() {
      return this.$store.getters.loader;
    },
  },
  watch: {
    path() {
      this.initColor();
    },
  },
  methods: {
    login() {
    },
    initColor() {
      let vm = this;
      let {primary, secondary} = vm.$vuetify.theme;
      if (localStorage.loginType === "dmr") {
        vm.$vuetify.theme.primary = secondary;
        vm.$vuetify.theme.secondary = primary;
      } else {
        vm.$vuetify.theme.primary = primary;
        vm.$vuetify.theme.secondary = secondary;
      }
    },
  },
  mounted() {
    const vm = this;
    vm.$store.dispatch("getuser");
    if (vm.user.phone) {
      let doc = {PinNo: vm.user.pin, MobileNo: vm.user.phone};
      vm.$store.dispatch("axiosget", {
        url: "GetUserInfo",
        data: doc,
        way: "setprofile",
      });
    }
    vm.initColor();
  },
};
</script>
