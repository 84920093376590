<template>
  <div>
    <v-card flat class="pa-3" style="display:flex">
      <div class="flex-1"></div>
      <date-picker label="From Date" v-model="form.StartDate"></date-picker>
      <date-picker label="To Date" v-model="form.EndDate"></date-picker>
      <select class="search" v-model="form.Status">
        <option value="">All</option>
        <option v-for="(o, i) in status" :key="i" :value="i">{{ o }}</option>
      </select>
      <v-btn class="success searchbtn" depressed @click="fetchReports"
        >Search</v-btn
      >
    </v-card>
    <v-data-table :headers="headers" :items="list" :search="search">
      <template slot="items" slot-scope="props">
        <td v-for="(item, i) in headers" :key="i">
          {{ props.item[item.value] }}
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "../components/ui/DatePicker.vue";
import microAtmService from "../services/micro.atm.service";
export default {
  components: { DatePicker },
  data() {
    return {
      modal: false,
      status: ["Pending", "Fail", "Success"],
      form: {
        Status: "",
        MATMId: "",
        SearchbyValue: "",
        StartDate: moment()
          .add(-20, "days")
          .format("YYYY-MM-DD"),
        EndDate: moment().format("YYYY-MM-DD"),
      },
      mobile: "",
      headers: [
        { text: "Id", value: "Id", align: "left" },
        { text: "Amount", value: "Amount" },
        { text: "Dealer Name", value: "DealerName" },
        { text: "TransactionStatus", value: "TransactionStatus" },
        { text: "Card No", value: "Cardno" },
        { text: "TransactionDate", value: "CreatedDate" },
        { text: "Invoice Number", value: "InvoiceNumber" },
        { text: "TxId", value: "Txn_id" },
        { text: "BankReffNo", value: "RRN" },
        { text: "TxnMode", value: "Txn_type" },
        { text: "DealerComission", value: "DealerCommission" },
        { text: "PayTransferId", value: "PayTransferId" },
        { text: "Reason", value: "Reason" },
        { text: "Action", value: "" },
      ],
      list: [],
      search: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    dmr() {
      return this.$store.getters.dmr;
    },
  },
  methods: {
    async fetchReports() {
      const vm = this;
      try {
        vm.$loader.show();
        vm.list = [];
        let doc = { ...vm.form };
        let { Status, Message, data } = (
          await microAtmService.getMicroATMReport(doc)
        ).data;
        if (Status != "True") {
          vm.$alert.show(Message);
        }
        vm.list = data.map((item) => {
          let date = item.CreatedDate.replace("/Date(", "").replace(")/", "");
          item.CreatedDate = moment(new Date(Number(date))).format("DD-MM-YYYY");
          item.TransactionStatus = vm.status[item.TransactionStatus];
          return item;
        });
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.fetchReports();
  },
};
</script>

<style scoped>
.flex-1 {
  flex: 1;
}
.search {
  width: 150px;
  height: 34px;
  background: white;
  border: 1px solid lightgray;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
