<template>
  <div>
    <v-dialog v-model="proccess" persistent width="400">
      <v-card
        style="background: #f0f0f0; padding-left: 15px; padding-right: 15px"
      >
        <v-card-title style="padding: 5px"
          >Transfer
          <v-spacer />
          <v-btn @click="close" icon
            ><v-icon style="margin-top: 0px" color="red">close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="amount"
            style="margin: 0 auto; height: 60px"
            label="Enter Amount"
            solo
            @change="changed()"
          />
          <v-text-field
            v-model="commission"
            style="margin: 0 auto; height: 60px"
            label="Enter Commission"
            solo
            @change="changed()"
          />
          <v-text-field
            type="text"
            v-model="remark"
            disabled
            style="margin: 0 auto; height: 60px"
            label="Enter Advance Amount"
            solo
          />
          <v-btn
            color="primary"
            style="width: 100%; margin: 0 auto"
            @click="submit"
            >Submit</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="view" persistent width="400">
      <v-card color="#01579B">
        <v-card-title style="height: 40px; color: white">
          <span class="body-2">Confirm Details</span>
        </v-card-title>
        <v-card-text style="padding-top: 0px">
          <v-layout row wrap v-for="(item, key) in summary" :key="key + item">
            <v-flex xs6>
              <v-card-text
                class="px-0 title"
                style="color: white; padding-top: 5px; padding-bottom: 8px"
                >{{ key }}</v-card-text
              >
            </v-flex>
            <v-flex xs6>
              <v-card-text
                class="px-0 title"
                style="color: white; padding-top: 5px; padding-bottom: 8px"
                >{{ item }}</v-card-text
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions style="width: 100%">
          <v-spacer></v-spacer>
          <v-btn
            small
            class="error"
            @click="
              proccess = true;
              view = false;
            "
            >Cancel</v-btn
          >
          <v-btn small class="success" @click="confirm">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["vdata"],
  data() {
    return {
      proccess: true,
      progress: false,
      remark: "",
      commission: "",
      amount: "",
      advance: "",
      summary: [],
      data: [],
      view: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    clear() {
      return this.$store.getters.clear;
    },
  },
  watch: {
    clear() {
      this.cleardata();
    },
    amount() {
      let vm = this;
      let amount = vm.amount;
      let per = vm.commission;
      if (per != "" && amount != "") {
        let a = per / 100;
        let total = a * amount + parseInt(amount);
        vm.remark = total;
      } else {
        vm.remark = vm.amount;
      }
    },
    commission() {
      let vm = this;
      let amount = vm.amount;
      let per = vm.commission;
      if (per != "" && amount != "") {
        let a = per / 100;
        let total = a * amount + parseInt(amount);
        vm.remark = total;
      } else {
        vm.remark = vm.amount;
      }
    },
  },
  methods: {
    changed() {
      let vm = this;
      let amount = vm.amount;
      let per = vm.commission;
      if (per != "" && amount != "") {
        let a = per / 100;
        let total = a * amount + parseInt(amount);
        vm.remark = total;
      } else {
        vm.remark = vm.amount;
      }
    },
    cleardata() {
      let vm = this;
      vm.commission = "";
      vm.amount = "";
      vm.remark = "";
    },
    submit() {
      let vm = this;
      let data = vm.data;
      if (vm.user.length == 0)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Authentication Required!",
          type: "warn",
        });
      if (vm.amount == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Amount Required",
          type: "error",
        });
      if (vm.commission == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Commission Required",
          type: "error",
        });
      if (vm.remark == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Advance Amount Required",
          type: "error",
        });
      vm.summary = {
        "Phone Number": data.phone,
        Type: data.type,
        Amount: vm.amount,
        Comission: vm.commission,
        "Advanced Amount": vm.remark,
      };
      vm.proccess = false;
      vm.view = true;
    },
    confirm() {
      let vm = this;
      vm.view = false;
      vm.$parent.progress = true;
      let params =
        "?Mob=" +
        vm.user.phone +
        "&message=TFR+" +
        vm.phone +
        "+" +
        vm.remark +
        "+" +
        vm.user.pin +
        "&source=ANDROID";
      vm.$store
        .dispatch("raxios", {
          data: params,
        })
        .then(function (res) {
          vm.$parent.progress = false;
          vm.$parent.res = res.data;
          vm.$parent.message = true;
          vm.$parent.transfer = false;
          vm.$store.commit("setclear", new Date().getTime());
        })
        .catch(function (error) {
          vm.$parent.progress = false;
          vm.$parent.transfer = true;
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn",
          });
        });
    },
    close() {
      let vm = this;
      vm.$parent.transfer = false;
    },
  },
  components: {},
  mounted() {
    let vm = this;
    vm.data = vm.vdata;
    vm.commission = vm.data.com;
  },
};
</script>
