<template>
  <div>
    <v-toolbar class="secondary" dense style="color:white;font-weight:bold">
      My Payment Requet List
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <download-excel
          :data="statementlist"
          name="Mypaymentrequest.xls"
          :fields="fields"
          style="height:30px;margin-top:9px;margin-right:10px"
        >
          <v-btn class="success" small>Export</v-btn>
        </download-excel>
        <v-btn @click="fetchstatement" icon>
          <v-icon style="margin-top:0px" color="white">refresh</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table :headers="headers" :items="statementlist" :loading="loading" hide-actions>
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template slot="items" slot-scope="props">
        <td>{{ new Date(parseInt(props.item.date)) | moment("DD-MM-YYYY") }}</td>
        <td>{{ props.item.transferid }}</td>
        <td>
          <v-btn
            small
            :class="checkstatus(props.item.status)"
            style="width:100%"
          >{{ checkrstatusname(props.item.status) }}</v-btn>
        </td>
        <td>{{ props.item.paymenttype }}</td>
        <td>{{ props.item.amount }}</td>
        <td>{{ props.item.approveamount }}</td>
        <td>{{ props.item.paymentbankdetails }}</td>
        <td>{{ props.item.childbankname }}</td>
        <td>{{ props.item.childbankaccountno }}</td>
        <td>{{ props.item.remark }}</td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      date1: "",
      menu1: false,
      date2: "",
      menu2: false,
      loading: false,
      headers: [
        { text: "Created Date", value: "date" },
        { text: "Transfer ID", value: "transferid" },
        { text: "Status", value: "status" },
        { text: "Payment Type", value: "paymenttype" },
        { text: "Amount", value: "amount" },
        { text: "Approve Amount", value: "approveamount" },
        { text: "Parent Bank Details", value: "paymentbankdetails" },
        { text: "Child Bank Name", value: "childbankname" },
        { text: "Child Bank Account No", value: "childbankaccountno" },
        { text: "Remark", value: "remark" }
      ],
      fields: {
        "Created Date": "date",
        "Transfer ID": "transferid",
        Status: "status",
        "Payment Type": "paymenttype",
        Amount: "amount",
        "Approve Amount": "approveamount",
        "Parent Bank Details": "paymentbankdetails",
        "Child Bank Name": "childbankname",
        "Child Bank Account No": "childbankaccountno",
        Remark: "remark"
      },
      statementlist: []
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    checkstatus(status) {
      if (status == 4) return "success";
      if (status == 5) return "grey";
      if (status == 3) return "warning";
      if (status == 1) return "error";
      return "primary";
    },

    checkrstatusname(status) {
      if (status == 4) return "Payment Collected";
      if (status == 5) return "Payment Collected With Partial Transfer";
      if (status == 3) return "Payment Collected With Transfer";
      if (status == 1) return "Payment Rejected";
      return "Payment Requested";
    },
    fetchstatement() {
      let vm = this;
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc = { MobileNo: vm.user.phone, PinNo: vm.user.pin };
      let url =
        vm.$root.loginType === "dmr"
          ? "PaymentRequestListDMR"
          : "PaymentRequestList";
      vm.$store
        .dispatch("axios", {
          data: doc,
          url: url
        })
        .then(function(res) {
          vm.loading = false;
          if (res.data.Data) {
            vm.pushstatement(res.data.Data);
          }
        })
        .catch(function(error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn"
          });
        });
    },
    pushstatement(datas) {
      let vm = this;
      vm.statementlist = [];
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        let doc = {
          date: data.CreatedDate.replace("/Date(", "").replace(")/", ""),
          transferid: data.TransferId,
          status: data.PaymetStatus,
          paymenttype: data.PaymentType,
          amount: data.Amount,
          approveamount: data.ApproveAmount,
          paymentbankdetails: data.parentBankDetails,
          childbankname: data.ChildBankName,
          childbankaccountno: data.ChildAccountNo,
          remark: data.Remark
        };
        vm.statementlist.push(doc);
      }
      vm.loading = false;
    }
  },
  mounted() {
    let vm = this;
    vm.fetchstatement();
  }
};
</script>

<style scoped>
.search {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
