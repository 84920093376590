<template>
    <div class="text-xs-center">
      <v-dialog v-model="dialog" width="500" persistent>
        <v-card>
          <v-card-title class="pa-2 pl-3 headline grey lighten-2" primary-title
            >Message</v-card-title
          >
          <v-card-text class="subheading" v-html="message"></v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="success" depressed @click="printReceipt()">Print</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="dialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <Print :summary="summary" :list="filterTransferList" />
    </div>
  </template>
  
  <script>
  import Print from "./Print";
  import moment from "moment";
  export default {
    props: ["recId"],
    components: { Print },
    data() {
      return {
        viewstatus: false,
        dialog: false,
        message: "",
        filterTransferList: [],
        summary: {},
      };
    },
    computed: {
      user() {
        return this.$store.getters.user;
      },
      dmr() {
        return this.$store.getters.dmr;
      },
    },
    methods: {
      init(message) {
        this.message = message;
        this.dialog = true;
      },
      async printReceipt() {
      
      
        const vm = this;
        let doc = {
          MobileNo: vm.user.phone,
          pinNo: vm.user.pin,
          StartDate: moment().format("YYYY-MM-DD"),
          EndDate: moment().format("YYYY-MM-DD"),
          DMRGateway: "All",
        };
        try {
          let { Data } = (
            await vm.$store.dispatch("axios", {
              url: "DMRTransactionListByDateIdandGateWay",
              data: doc,
            })
          ).data;
          let pos = 0;
          let mobile = "";
          let account = "";
          let statementlist = [];
          Data = Data.reverse();
          for (let i = 0; i < Data.length; i++) {
            let doc = Data[i];
            let time = doc.CreatedDate.replace("/Date(", "").replace(")/", "");
          
            mobile = doc.MobileNo;
            account = doc.accountno;
            statementlist.push({
              amount: doc.Amount,
              orderid: doc.RechargeId,
              mobile: doc.MobileNo,
              time: time,
              pos: pos,
              name: doc.recipient_name,
              remark: doc.DMRResponse,
              sender: doc.CreateByUserName,
              account: doc.accountno,
              status: doc.TransactionStatus,
              bank_txtid: doc.BankRefrenceNo,
              user_name: doc.CreateByUserName,
              bank_name: doc.bankName,
              service: doc.ServiceName,
              bank_ifsc: doc.ifsc,
              tid: doc.TransactionId,
              mobile_no: doc.recipient_mobile,
              ReceiptId: doc.ReceiptId
            });
          }
          vm.filterTransferList = statementlist.filter((item) => item.ReceiptId == this.recId);
          vm.summary = vm.filterTransferList[0];
          vm.dialog = false;
          vm.viewstatus = true;
        } catch (error) {
          vm.$root.alert(error.message);
        }
      },
    },
  };
  </script>
  
  <style>
  </style>