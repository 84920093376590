<template>
  <v-menu
    :close-on-content-click="false"
    v-model="menu"
    lazy
    transition="scale-transition"
    offset-y
  >
    <input
      type="text"
      slot="activator"
      v-model="date"
      readonly
      class="date-picker"
      :placeholder="label"
    />
    <v-date-picker v-model="date" @input="onInput"></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    label: {},
    value: {},
  },
  data() {
    return {
      date: null,
      menu: false,
    };
  },
  methods: {
    onInput() {
      this.menu = false;
      this.$emit("input", this.date);
    },
  },
  beforeMount() {
    this.date = this.value;
  },
};
</script>

<style>
.date-picker {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  border: 1px solid lightgray;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
</style>
