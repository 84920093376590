<template>
  <div>
    <v-toolbar class="secondary" dense style="color:white;font-weight:bold">
      Complaint List
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <download-excel
          :data="statementlist"
          name="Complaint_Report.xls"
          :fields="fields"
          style="height:30px;margin-top:9px;margin-right:10px"
        >
          <v-btn class="success" small>Export</v-btn>
        </download-excel>
        <v-menu
          :close-on-content-click="false"
          v-model="menu1"
          lazy
          transition="scale-transition"
          offset-y
          style="float:right"
        >
          <input
            type="text"
            slot="activator"
            style="color:black"
            v-model="date1"
            readonly
            class="search"
            placeholder="From Date"
          />
          <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
        </v-menu>
        <v-menu
          :close-on-content-click="false"
          v-model="menu2"
          lazy
          transition="scale-transition"
          offset-y
          style="float:right"
        >
          <input
            type="text"
            slot="activator"
            style="color:black"
            v-model="date2"
            readonly
            class="search"
            placeholder="To Date"
          />
          <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
        </v-menu>

        <v-btn
          class="success searchbtn"
          style="height:34px;margin-left:10px"
          @click="fetchstatement"
        >Search</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table :headers="headers" :items="statementlist" :loading="loading" hide-actions>
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template slot="items" slot-scope="props">
        <td>{{ new Date(parseInt(props.item.date)) | moment("DD-MM-YYYY") }}</td>
        <td>{{ props.item.id }}</td>
        <td>{{ props.item.rid }}</td>
        <td>{{ props.item.status }}</td>
        <td>{{ props.item.remark }}</td>
        <td>{{ props.item.rremark }}</td>
        <td>{{ new Date(parseInt(props.item.rdate)) | moment("DD-MM-YYYY") }}</td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      date1: "",
      menu1: false,
      date2: "",
      menu2: false,
      loading: false,
      headers: [
        { text: "Complaint Date", value: "date" },
        { text: "Id", value: "id" },
        { text: "Recharge Id", value: "Rid" },
        { text: "Status", value: "status" },
        { text: "Complaint Remark", value: "remark" },
        { text: "Resolve Remark", value: "rremark" },
        { text: "Resolved Date", value: "rdate" }
      ],
      fields: {
        "Complaint Date": "date",
        Id: "id",
        "Recharge Id": "Rid",
        Status: "status",
        "Complaint Remark": "remark",
        "Resolve Remark": "rremark",
        "Resolved Date": "rdate"
      },
      statementlist: []
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    fetchstatement() {
      let vm = this;
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        StartDate: vm.date1,
        EndDate: vm.date2
      };
      let url =
        vm.$root.loginType === "dmr"
          ? "UserWiseComplainDMR"
          : "UserwiseComplain";

      vm.$store
        .dispatch("axios", {
          data: doc,
          url: url
        })
        .then(function(res) {
          vm.loading = false;
          if (res.data.Data) {
            vm.pushstatement(res.data.Data);
          }
        })
        .catch(function(error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn"
          });
        });
    },
    pushstatement(datas) {
      let vm = this;
      vm.statementlist = [];
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        let status = datas[i].Status;
        status = vm.getstatus(status);
        let rdate = ""
        if(data.ResolveDate) rdate = data.ResolveDate.replace("/Date(", "").replace(")/", "");
        let doc = {
          date: data.ComplainDate.replace("/Date(", "").replace(")/", ""),
          id: data.Id,
          rid: data.RechargeId,
          rremark: data.ResolveRemark,
          remark: data.ComplainRemark,
          rdate,
          status: status
        };
        vm.statementlist.push(doc);
      }
      vm.loading = false;
    },
    getstatus(status) {
      if (status == 2) return "Success";
      if (status == 0) return "Pending";
      if (status == 3) return "In Process";
      if (status == 1) return "Failure";
      return status;
    }
  },
  mounted() {
    let vm = this;
    vm.fetchstatement();
    let height = window.innerHeight - 210;
    let v_table = document.getElementsByClassName("v-table__overflow");
    for (let i = 0; i < v_table.length; i++) {
      v_table[i].style.height = height + "px";
    }
  }
};
</script>

<style scoped>
.search {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
