<template>
  <div>
    <v-tabs
      next-icon="arrow_forward_ios"
      prev-icon="arrow_back_ios"
      show-arrows
    >
      <v-tabs-slider></v-tabs-slider>
      <template v-for="(tab, i) in tabs">
        <v-tab
          class="primary--text"
          :key="i + 'tab'"
          v-if="check(tab)"
          @click="way = tab.way"
        >
          <img :src="tab.img" :width="tab.width" />
          &nbsp; &nbsp;{{ tab.name }}
        </v-tab>
      </template>
    </v-tabs>
    <Myoutstanding v-show="way == 'mine'" />
    <Downline v-show="way == 'downline'" />
  </div>
</template>

<script>
import Myoutstanding from "../outstanding/Myoutstanding.vue";
import Downline from "../outstanding/Downline.vue";
export default {
  components: {
    Myoutstanding,
    Downline
  },
  data() {
    return {
      way: "mine",
      tabs: [
        {
          name: "My Outstanding",
          img: require("../assets/account.svg"),
          way: "mine",
          width: 25
        },
        {
          name: "Downline Outstanding",
          img: require("../assets/downline.png"),
          way: "downline",
          width: 25
        }
      ]
    };
  },
  watch: {
    user() {
      this.fetch();
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    fetch() {
      let vm = this;
      if (vm.user.length != 0) {
        let doc = { PinNo: vm.user.pin, MobileNo: vm.user.phone };
        vm.$store.dispatch("axiosget", {
          url: "GetMyBankDetail",
          data: doc,
          way: "setbank"
        });
      }
    },
    check(item) {
      let vm = this;
      if (vm.user.Type == "Dealer" && item.way == "downline") return false;
      return true;
    }
  },
  mounted() {
    let vm = this;
    vm.fetch();
    let height = window.innerHeight - 210;
    let v_table = document.getElementsByClassName("v-table__overflow");
    for (let i = 0; i < v_table.length; i++) {
      v_table[i].style.height = height + "px";
    }
  }
};
</script>
