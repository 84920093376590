import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/src/stylus/app.styl";
import colors from 'vuetify/es5/util/colors'
let theme1 = {
  primary: "#F5A21C", // #E53935
  secondary: "#080F54", // #FFCDD2
  accent: "#263238" // #3F51B5
}
let theme2 = {
  secondary: "#F5A21C",
  primary: "#080F54", 
  accent: "#263238"
}
Vue.use(Vuetify, {
  iconfont: "md",
  theme: theme2
});
