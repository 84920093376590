<template>
  <div>
    <v-dialog v-model="proccess" persistent width="400">
      <v-card
        style="background: #f0f0f0; padding-left: 15px; padding-right: 15px"
      >
        <v-card-title style="padding: 5px">
          Adjust Outstanding
          <v-spacer />
          <v-btn @click="close" icon>
            <v-icon style="margin-top: 0px" color="red">close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="amount"
            style="margin: 0 auto; height: 60px"
            label="Enter Amount"
            solo
          />
          <v-autocomplete
            style="margin: 0 auto; height: 60px"
            v-model="type"
            :items="types"
            label="Select Type"
            solo
          />
          <v-text-field
            type="remark"
            v-model="remark"
            style="margin: 0 auto; height: 60px"
            label="Enter Remark"
            solo
          />
          <v-btn
            color="primary"
            style="width: 100%; margin: 0 auto"
            @click="submit"
            >Submit</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="view" persistent width="400">
      <v-card color="#01579B">
        <v-card-title style="height: 40px; color: white">
          <span class="body-2">Confirm Details</span>
        </v-card-title>
        <v-card-text style="padding-top: 0px">
          <v-layout row wrap v-for="(item, key) in summary" :key="key + item">
            <v-flex xs6>
              <v-card-text
                class="px-0 title"
                style="color: white; padding-top: 5px; padding-bottom: 8px"
                >{{ key }}</v-card-text
              >
            </v-flex>
            <v-flex xs6>
              <v-card-text
                class="px-0 title"
                style="color: white; padding-top: 5px; padding-bottom: 8px"
                >{{ item }}</v-card-text
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions style="width: 100%">
          <v-spacer></v-spacer>
          <v-btn
            small
            class="error"
            @click="
              proccess = true;
              view = false;
            "
            >Cancel</v-btn
          >
          <v-btn small class="success" @click="confirm">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["vdata"],
  data() {
    return {
      proccess: true,
      progress: false,
      remark: "",
      phone: "",
      amount: "",
      advance: "",
      types: ["CR", "DR"],
      type: "",
      summary: [],
      data: [],
      view: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    clear() {
      return this.$store.getters.clear;
    },
  },
  watch: {
    clear() {
      this.cleardata();
    },
  },
  methods: {
    cleardata() {
      let vm = this;
      vm.type = "";
      vm.amount = "";
      vm.remark = "";
    },
    submit() {
      let vm = this;
      let data = vm.data;
      if (vm.user.length == 0)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Authentication Required!",
          type: "warn",
        });
      if (vm.amount == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Amount Required",
          type: "error",
        });
      if (vm.type == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Select Type",
          type: "error",
        });
      vm.summary = {
        "Child Name": data.child,
        "Parent Name": data.parent,
        Type: vm.type,
        Amount: vm.amount,
        Remark: vm.remark,
      };
      vm.proccess = false;
      vm.view = true;
    },
    confirm() {
      let vm = this;
      vm.view = false;
      vm.$parent.progress = true;
      let data = vm.data;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        ParentUsername: data.parent,
        ChildId: data.cid,
        ParentId: data.id,
        ChildUsername: data.child,
        Amount: vm.amount,
        CrDr: vm.type,
        Remark: vm.remark,
      };
      let url =
        vm.$root.loginType === "dmr"
          ? "OutstadingAdjustmentDMR"
          : "OutstadingAdjustment";
      vm.$store
        .dispatch("axios", {
          data: doc,
          url: url,
        })
        .then(function (res) {
          vm.$parent.progress = false;
          vm.$parent.res = res.data.Message;
          vm.$parent.message = true;
          vm.$parent.adjust = false;
          vm.$store.commit("setclear", new Date().getTime());
        })
        .catch(function (error) {
          vm.$parent.progress = false;
          vm.$parent.adjust = true;
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn",
          });
        });
    },
    close() {
      let vm = this;
      vm.$parent.adjust = false;
    },
  },
  components: {},
  mounted() {
    let vm = this;
    vm.data = vm.vdata;
  },
};
</script>
