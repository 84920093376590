<template>
  <div
    style="width: 300px; margin: 0 auto; margin-top: 30px; padding-top: 10px"
  >
    <v-img
      :src="require('../assets/account.svg')"
      width="150"
      style="margin: 0 auto"
    ></v-img>
    <v-text-field
      style="width: 300px; margin: 0 auto; height: 60px; margin-top: 10px"
      label="Enter Mobile Number"
      solo
      v-model="phone"
    />

    <v-text-field
      type="password"
      style="width: 300px; margin: 0 auto; height: 60px"
      label="Enter Pin"
      solo
      v-model="pin"
    />
    <div style="width: 100%; float: left">
      <v-btn @click="login" color="primary" style="width: 300px; margin: 0 auto"
        >Submit</v-btn
      >
    </div>
    <Progress v-if="proccess" />
  </div>
</template>

<script>
import Progress from "../components/Progress.vue";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      proccess: false,
      phone: "",
      password: "",
      otpcheck: false,
      pin: "",
      otp: "",
      authkey: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    let vm = this;
    if (localStorage.user) {
      vm.$router.push("/setting");
    }
  },
  methods: {
    notification(type, message) {
      this.$notify({
        group: "foo",
        title: "Important message",
        text: message,
        duration: 5000,
        type: type,
      });
    },
    login() {
      let vm = this;
      if (vm.phone == "")
        return vm.notification("error", "Phone Number Required");
      if (vm.phone.length != 10)
        return vm.notification("error", "Valid Phone Number Required");
      if (vm.pin == "") return vm.notification("error", "Pin Number Required");
      vm.proccess = true;
      let doc = {
        PinNo: vm.pin,
        MobileNo: vm.phone,
      };
      vm.$store
        .dispatch("axios", {
          url: "Authentication",
          data: doc,
        })
        .then(function (res) {
          vm.proccess = false;
          let doc = res.data;
          if (doc.Status == "True") {
            let data = doc.Data[0];
            data.phone = vm.phone;
            data.pin = vm.pin;
            localStorage.user = JSON.stringify(data);
            vm.$store.dispatch("getuser");
            vm.$router.push("/");
            window.location.reload();
          } else {
            vm.notification("error", doc.Message);
          }
        })
        .catch(function (error) {
          vm.notification("error", error);
          vm.proccess = false;
        });
    },
  },
};
</script>
