import config from "../config";
import axios from "axios";
import store from "../store.js";
const baseURL = config.microATMUrl;
const http = axios.create({
  baseURL,
});
class MicroAtmService {
  getMicroATMReport(params) {
    return http.get("/MATMTransactionList", {
      params: this.addAuthParams(params),
    });
  }
  addAuthParams(params) {
    let { phone, pin, ApiAuthKey } = store.getters.user;
    let doc = { MobileNo: phone, PinNo: pin };
    return { ...doc, ApiAuthKey, ...params };
  }
}

export default new MicroAtmService();
