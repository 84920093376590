<template>
  <div>
    <v-dialog v-model="proccess" persistent width="300">
      <v-card style="background: #080F54" dark>
        <v-card-text
          >Please wait
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      proccess: true
    };
  }
};
</script>
