const host = "http://admin.selftechnology.in/";
export default {
  baseUrl: host + "ReCharge/androidapi.asmx/",
  moneyTransfer: host + "Recharge/MoneyTransfer.asmx/",
  aeps2Url: host + "Recharge/AEPSNY.asmx",
  microATMUrl: host + "Recharge/AEPSNI.asmx",
  apes2LoginUrl: "https://45.114.245.149/AEPS/login",
  dmr2: host + "ReCharge/opnDMRtransfer.asmx/",
  instapay: host + "ReCharge/INS2DMRTransfer.asmx/",
  dmrCyberplat: host + "ReCharge/INSPAYPDMTTransfer.asmx/",
  dmrPsy : host + "ReCharge/PysDMRApi.asmx/",
  rbaseUrl: host + "ReCharge/APIs.aspx",
  sbaseUrl: host + "ReCharge/APIAndroid.aspx",
  planUrl: "http://submplan.myselfshop.in/MPLAN/Services/API.asmx/",
  path: "http://admin.selftechnology.in/ReCharge/UploadImages/",
  apikey: "f78b5ce5263b18b28f9ebfcb6e9976f3",
  dmrUrl: host + "ReCharge/CBRMoneytransfer.asmx/",
  logo: require("../assets/logo.png"),
  details: {
    AndroidAppName: "Self Technology",
    PlayStoreUrl:
      "",
    EmailId: "selftechnologyindia@gmail.com",
    ServerNo: "xxx",
    HelpLineNo: "91 8238680386",
    Address1: "",
    Address2: "",
    ContactName: "",
    ContactMob: "91 8238680386",
    Footer: "",
    InqueryNumber: "91 8238680386",
  },
  banks: [
    {
      Id: 1,
      BankName: "ICICI Bank",
      CompanyName: "SELF TECHNOLOGY",
      AcountNo: "362405000631",
      Branch: "	MAHENDRA NAGAR,MORBI",
      IfscCode: "ICIC0003624",
      UserDomainName: null,
      AccountType: "CURRENT",
      CreatedBy: 0,
      IconImage: "SBI.jpg",
    },
    {
      Id: 2,
      BankName: "STATE BANK OF INDIA",
      CompanyName: "SELF TECHNOLOGY",
      AcountNo: "40315120347",
      Branch: "PARA BAJAR MORBI",
      IfscCode: "SBIN0060071",
      UserDomainName: null,
      AccountType: "CURRENT",
      CreatedBy: 0,
      IconImage: "SBI.jpg",
    },
    {
      Id: 3,
      BankName: "BANK OF INDIA",
      CompanyName: "SELF TECHNOLOGY",
      AcountNo: "311620110001081",
      Branch: "MORBI",
      IfscCode: "BKID0003116",
      UserDomainName: null,
      AccountType: "CURRENT",
      CreatedBy: 0,
      IconImage: "BOI.jpg",
    },
    {
      Id: 4,
      BankName: "Bank of Baroda",
      CompanyName: "SELF TECHNOLOGY",
      AcountNo: "03630200001546",
      Branch: "MORBI",
      IfscCode: "BARB0MORVIX",
      UserDomainName: null,
      AccountType: "CURRENT",
      CreatedBy: 0,
      IconImage: "SBI.jpg",
    },
    {
      Id: 5,
      BankName: "HDFC BANK",
      CompanyName: "SELF TECHNOLOGY",
      AcountNo: "50200061101607",
      Branch: "MORBI",
      IfscCode: "HDFC0000307",
      UserDomainName: null,
      AccountType: "CURRENT",
      CreatedBy: 0,
      IconImage: "SBI.jpg",
    },
    {
      Id: 6,
      BankName: "Punjab National Bank",
      CompanyName: "SELF TECHNOLOGY",
      AcountNo: "2532002100020616",
      Branch: "MORBI",
      IfscCode: "PUNB0058610",
      UserDomainName: null,
      AccountType: "CURRENT",
      CreatedBy: 0,
      IconImage: "SBI.jpg",
    },
    {
      Id: 7,
      BankName: "Axis Bank",
      CompanyName: "SELF TECHNOLOGY",
      AcountNo: "921020016314155",
      Branch: "MORBI",
      IfscCode: "UTIB0000662",
      UserDomainName: null,
      AccountType: "CURRENT",
      CreatedBy: 0,
      IconImage: "SBI.jpg",
    },
  ],
};
