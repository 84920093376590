<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn flat v-on="on">Registration</v-btn>
    </template>
    <v-card class="pa-2">
      <v-card-title class="subheading font-weight-bold pb-0">
        AEPS 2 Agent Register
      </v-card-title>
      <v-card-text>
        <v-form v-model="isValid" ref="form" class="d-flex aeps2-row">
          <v-text-field
            label="Mobile Number"
            :rules="[...field, ...phone]"
            v-model="form.MobileNumber"
            class="pr-3"
          />
          <v-text-field
            label="First Name"
            :rules="field"
            v-model="form.FirstName"
          />
          <v-text-field
            label="Middle Name"
            class="pr-3"
            :rules="field"
            v-model="form.middleName"
          />
          <v-text-field
            label="Last Name"
            v-model="form.LastName"
            :rules="field"
          />
          <v-text-field
            label="PAN Number"
            :rules="field"
            class="pr-3"
            v-model="form.pan"
          />
          <v-text-field
            label="Email"
            :rules="[...field, ...email]"
            v-model="form.email"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" @click="dialog = false" flat>Close</v-btn>
        <v-btn color="success" @click="submit()" flat>Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import aeps2Service from "../../services/aeps2.service.js";
export default {
  data() {
    return {
      dialog: false,
      isValid: false,
      field: [(v) => !!v || "Field is required"],
      phone: [(v) => v.length == 10 || "Mobile Number must be 10 digit"],
      email: [(v) => /.+@.+/.test(v) || "E-mail must be valid"],
      form: {
        MobileNumber: "",
        FirstName: "",
        middleName: "",
        LastName: "",
        pan: "",
        email: "",
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async submit() {
      const vm = this;
      vm.$refs.form.validate();
      if (!vm.isValid) return;
      try {
        vm.$loader.show();
        let doc = { ...vm.form };
        let { Status, Message } = (await aeps2Service.register(doc)).data;
        if (Status == "True") {
          vm.$emit("onNew");
          vm.dialog = false;
        } else {
          vm.$alert.show(Message);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style>
.aeps2-row {
  flex-wrap: wrap;
}
.aeps2-row .v-input {
  flex: 0 0 50% !important;
}
</style>
