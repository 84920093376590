<template>
  <div>
    <v-toolbar class="secondary" dense style="color: white; font-weight: bold">
      Downline List
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="statementlist"
      :loading="loading"
      hide-actions
    >
      <v-progress-linear
        slot="progress"
        color="blue"
        indeterminate
      ></v-progress-linear>
      <template slot="items" slot-scope="props">
        <td>{{ props.item.UserId }}</td>
        <td>{{ props.item.UserName }}</td>
        <td>{{ props.item.MobileNo }}</td>
        <td>{{ props.item.Balance }}</td>
        <td>{{ props.item.Type }}</td>
        <td>
          <v-btn small flat class="primary" @click="getChildList(props.item)">
            Child List</v-btn
          >
          <v-btn small flat class="secondary" @click="initTransfer(props.item)">
            Transfer</v-btn
          >
        </td>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="400px" scrollable persistent>
      <v-card>
        <v-card-title class="d-block pa-0">
          <div class="d-flex pa-3 align-center">
            <div class="title">Child List</div>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              tile
              class="red--text ma-0"
              style="max-width: 30px"
              @click="dialog = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </div>
          <v-divider></v-divider>
          <div class="px-3 py-3">
            <v-text-field
              v-model="search"
              clearable
              hide-details
              solo
              label="Search"
            ></v-text-field>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0" style="height: 500px">
          <v-list two-line class="ma-0 px-0" three-line>
            <template v-for="(item, i) in filteredList">
              <v-list-tile :key="i">
                <v-list-tile-content>
                  <v-list-tile-sub-title class="black--text">
                    User Name: {{ item.UserName }}
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title>
                    Mobile No: {{ item.MobileNo }}
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title>
                    Balance: {{ item.Balance }}
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title>
                    DMR Balance: {{ item.DmrBalance }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider :key="'divider-' + i"></v-divider>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      search: "",
      headers: [
        { text: "User ID", value: "UserId" },
        { text: "User Name", value: "UserName" },
        { text: "Mobile No", value: "MobileNo" },
        { text: "Balance", value: "Balance" },
        { text: "Username", value: "Type" },
        { text: "Action", value: "action" },
      ],
      statementlist: [],
      childList: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    filteredList() {
      return this.childList.filter((item) => {
        let search = this.search.trim().toLowerCase();
        let { MobileNo, UserName } = item;
        MobileNo = MobileNo || "";
        UserName = UserName || "";
        if (!search) return true;
        return (
          MobileNo.toString().toLowerCase().includes(search) ||
          UserName.toLowerCase().includes(search)
        );
      });
    },
  },
  methods: {
    async getChildList({ MobileNo, UserId }) {
      const vm = this;
      try {
        vm.$loader.show();
        let doc = {
          MobileNo: vm.user.phone,
          PinNo: vm.user.pin,
          UserId,
          UserMobileNo: MobileNo,
        };
        let { Data, Status } = (
          await vm.$store.dispatch("axiosget", {
            url: "/GetchiledTree",
            data: doc,
          })
        ).data;
        if (Status == "True") {
          vm.dialog = true;
          console.log(Data);
          vm.childList = Data;
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    initTransfer({ MobileNo }) {
      this.$router.push({ name: "transaction", query: { MobileNo } });
    },
    fetchstatement() {
      let vm = this;
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        UserId: "",
        UserMobileNo: "",
      };
      vm.$store
        .dispatch("axios", {
          url: "GetchiledTree",
          data: doc,
        })
        .then(function (res) {
          vm.statementlist = [];
          if (res.data.Data != null) {
            vm.statementlist = res.data.Data;
          }
          vm.loading = false;
        })
        .catch(function (error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn",
          });
        });
    },
  },
  mounted() {
    let vm = this;
    vm.fetchstatement();
  },
};
</script>

<style scoped>
.search {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
