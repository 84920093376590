<template>
  <div class="text-xs-center">
    <v-dialog v-model="$root.messagePop" width="500">
      <v-card>
        <v-card-title class="pa-2 pl-3 headline grey lighten-2" primary-title>Message</v-card-title>
        <v-card-text class="subheading" v-html="$root.message"></v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="$root.messagePop = false">okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log(this.$root.messagePop);
  }
};
</script>

<style>
</style>