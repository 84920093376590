import config from "../config";
import axios from "axios";
import store from "../store.js";
const baseURL = config.aeps2Url;
const http = axios.create({
  baseURL,
});
class Aeps2Service {
  register(params) {
    return http.get("/Registration", {
      params: this.addAuthParams(params),
    });
  }
  updateKYC(data) {
    return http.post("/UpdateKYC", data);
  }
  getAgents(params) {
    return http.get("/GetAgentList", {
      params: this.addAuthParams(params),
    });
  }
  getAgentDetails(params) {
    return http.get("/GetOutletDetail", {
      params: this.addAuthParams(params),
    });
  }
  getReports(params) {
    return http.get("/AEPSTransaction", {
      params: this.addAuthParams(params),
    });
  }
  getGateway(params) {
    return http.get("/GETAEPSGateway", {
      params: this.addAuthParams(params),
    });
  }
  getYesBankWithdrawToken(params) {
    return http.get("/WithdrawYESToken", {
      params: this.addAuthParams(params),
    });
  }
  getICICIWithdrawToken(params) {
    return http.get("/WithdrawICICIToken", {
      params: this.addAuthParams(params),
    });
  }
  addAuthParams(params) {
    let { phone, pin, ApiAuthKey } = store.getters.user;
    let doc = { MobileNo: phone, PinNo: pin };
    return { ...doc, ...params, ApiAuthKey };
  }
}

export default new Aeps2Service();
