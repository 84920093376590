<template>
  <div class="service-down mt-5 pt-5">
    <img width="250px" height="250px" src="../assets/service-down.svg" alt="" />
    <h3 class="mt-3">
      Service Down<br />
      Please try after sometime
    </h3>
  </div>
</template>

<script>
export default {};
</script>

<style>
.service-down {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.service-down h3 {
  color: #080f54;
  text-align: center;
}
</style>
