<template>
  <div>
    <v-toolbar class="secondary" dense style="color:white;font-weight:bold">
      Operator Commission
      <v-spacer></v-spacer>
      <v-toolbar-items></v-toolbar-items>
    </v-toolbar>
    <v-data-table :headers="headers" :items="statementlist" hide-actions>
      <template slot="items" slot-scope="props">
        <td>{{ props.item.rechargetype }}</td>
        <td>{{ props.item.servicename }}</td>
        <td>{{ props.item.commissiontype }}</td>
        <td>{{ props.item.commission }}</td>
        <td>{{ props.item.surcharge }}</td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      date1: "",
      menu1: false,
      date2: "",
      menu2: false,
      headers: [
        { text: "Recharge Type", value: "rechargetype" },
        { text: "Service Name", value: "servicename" },
        { text: "Commission Type", value: "commissiontype" },
        { text: "Commission", value: "commission" },
        { text: "Surcharge", value: "surcharge" }
      ],
      statementlist: []
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    fetchstatement() {
      let vm = this;
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc = { MobileNo: vm.user.phone, PinNo: vm.user.pin, Balance: "" };
      vm.$store
        .dispatch("axios", {
          data: doc,
          url: "CommissionList"
        })
        .then(function(res) {
          if (res.data.Data) {
            vm.pushstatement(res.data.Data);
          }
        })
        .catch(function(error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn"
          });
        });
    },
    pushstatement(datas) {
      let vm = this;
      vm.statementlist = [];
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        let doc = {
          rechargetype: data.RechargeType,
          servicename: data.ServiceName,
          commissiontype: data.CommissionType,
          commission: data.Commission,
          surcharge: data.SurCharge
        };
        vm.statementlist.push(doc);
      }
      vm.loading = false;
    }
  },
  mounted() {
    let vm = this;
    vm.fetchstatement();
  }
};
</script>

<style scoped>
.search {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
