<template>
  <div class="download">
    <div class="download_wrapper">
      <div class="display-3">Downloads</div>
      <v-layout row wrap>
        <v-flex xs4 class="col">
          <img src="../assets/googleplay.png" alt="" />
          <div class="headline">Android App</div>
          <div class="subheading">
            We Provide Android application to make transaction very quickly and
            easily.
          </div>
        </v-flex>
        <v-flex xs4 class="col">
          <img src="../assets/api.svg" alt="" />
          <div class="headline">API Document</div>
          <div class="subheading">
            We provide Soap Base API Document for secure payment
          </div>
        </v-flex>
        <v-flex xs4 class="col">
          <img src="../assets/sms.svg" alt="" />
          <div class="headline">SMS Format</div>
          <div class="subheading">
            Provide Facility to recharge even without internet connection using
            SMS.
          </div>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
export default {
  name: "Download"
};
</script>

<style scoped>
.download {
  width: 100%;
  background: #f0f0f0;
  float: left;
  height: 500px;
}
.download_wrapper {
  width: 1000px;
  height: 500px;
  margin: 0 auto;
}
.col img {
  margin-left: 90px;
  width: 150px;
  object-fit: contain;
  height: 200px;
  margin-top: 30px;
}
.col .headline {
  width: 100%;
  text-align: center;
}
.col .subheading {
  width: 100%;
  margin-top: 15px;
  text-align: center;
}
.display-3 {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: #F5A21C;
}
</style>
