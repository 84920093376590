<template>
  <div>
    <v-tabs
      next-icon="arrow_forward_ios"
      prev-icon="arrow_back_ios"
      show-arrows
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        class="primary--text"
        v-for="(tab, i) in tabs"
        :key="i + 'tab'"
        v-if="tab.enable"
        @click="way = tab.way"
      >
        <img :src="tab.img" :width="tab.width" />
        &nbsp; &nbsp;{{ tab.name }}
      </v-tab>
    </v-tabs>
    <template v-if="$root.loginType != 'dmr'">
      <Operator v-show="way == 'operator'" />
    </template>
    <Recharge v-show="way == 'recharge'" />
  </div>
</template>

<script>
import Operator from "../commission/Operator.vue";
import Recharge from "../commission/Recharge.vue";
export default {
  components: {
    Operator,
    Recharge
  },
  data() {
    return {
      way: this.$root.loginType != 'dmr' ? "operator" : 'recharge',
      tabs: [
        {
          name: "Operator Commission",
          img: require("../assets/operator.png"),
          way: "operator",
          enable: this.$root.loginType != 'dmr',
          width: 25
        },
        {
          name: "Recharge Commission",
          enable: true,
          img: require("../assets/phone.png"),
          way: "recharge",
          width: 25
        }
      ]
    };
  },
  mounted() {
    let height = window.innerHeight - 210;
    let v_table = document.getElementsByClassName("v-table__overflow");
    for (let i = 0; i < v_table.length; i++) {
      v_table[i].style.height = height + "px";
    }
  }
};
</script>
