<template>
  <div>
    <div
      style="width: 100%; text-align: center; margin-top: 15px; height: 50px"
      class="headline"
    >
      Postpaid
    </div>
    <v-autocomplete
      v-model="operator"
      :items="sortedproviver"
      label="Select Operator"
      style="margin: 0 auto; height: 60px"
      chips
      item-text="name"
      item-value="name"
    >
      <template slot="selection" slot-scope="data">
        <v-chip :selected="data.selected" close class="chip--select-multi">
          <v-avatar>
            <img :src="data.item.avatar" @error="errorimg" />
          </v-avatar>
          {{ data.item.name }}
        </v-chip>
      </template>
      <template slot="item" slot-scope="data">
        <template>
          <v-list-tile-avatar>
            <img :src="data.item.avatar" @error="errorimg" />
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile v-html="data.item.name"></v-list-tile>
          </v-list-tile-content>
        </template>
      </template>
    </v-autocomplete>

    <v-text-field
      v-model="rechargernumber"
      style="margin: 0 auto; height: 60px"
      label="Enter Mobile Number"
    ></v-text-field>
    <v-text-field
      type="text"
      v-model="rechargeramount"
      style="margin: 0 auto; height: 60px"
      label="Enter Recharge Amount"
    />
    <!--
           <v-autocomplete
                style="margin:0 auto;height:53px"
                v-model="type"
                :items="rechargetype"
                label="Select Recharge Type"
                solo
            />
            -->
    <div style="width: 100%; float: left">
      <v-btn color="primary" style="width: 100%; margin: 0 auto" @click="submit"
        >Submit</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      operator: "",
      type: "",
      provider: [],
      rechargetype: ["Recharge", "STV"],
      rechargernumber: "",
      rechargeramount: "",
    };
  },
  computed: {
    getprovider() {
      return this.$store.getters.operator;
    },
    rwidth() {
      return this.$store.getters.rwidth;
    },
    user() {
      return this.$store.getters.user;
    },
    clear() {
      return this.$store.getters.clear;
    },
    sortedproviver() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      let provider = this.provider;
      return provider.sort(compare);
    },
  },
  watch: {
    getprovider() {
      let vm = this;
      vm.pushoperator();
    },
    clear() {
      this.cleardata();
    },
  },
  methods: {
    cleardata() {
      let vm = this;
      vm.operator = "";
      vm.rechargernumber = "";
      vm.rechargeramount = "";
    },
    errorimg(e) {
      e.target.src = require("../assets/logo_single.png");
    },
    pushoperator() {
      let vm = this;
      vm.provider = [];
      for (let i = 0; i < vm.getprovider.length; i++) {
        let doc = vm.getprovider[i];
        let path = this.$store.getters.path;
        if (doc.RechargeTypeName == "Recharge") {
          let data = {
            avatar: path + doc.CircleImg,
            name: doc.ServiceName,
          };
          vm.provider.push(data);
        }
      }
    },
    submit() {
      let vm = this;
      if (vm.user.length == 0)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Authentication Required!",
          type: "warn",
        });
      if (vm.operator == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Operator Required",
          type: "error",
        });
      if (vm.rechargernumber == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Recharge Number Required",
          type: "error",
        });
      if (vm.rechargernumber.length != 10)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "10 Digit Number Required",
          type: "error",
        });
      if (vm.rechargeramount == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Recharge Amount Required",
          type: "error",
        });
      let params =
        "?Mob=" +
        vm.user.phone +
        "&message=PP+" +
        vm.operator +
        "+" +
        vm.rechargernumber +
        "+" +
        vm.rechargeramount +
        "+" +
        vm.user.pin +
        "&source=ANDROID";
      let data = {
        Operator: vm.operator,
        "Recharge Number": vm.rechargernumber,
        "Recharge Amount": vm.rechargeramount,
      };
      vm.$parent.sync = {
        title: vm.operator,
        subtitle: vm.rechargernumber,
        status: "process",
      };
      vm.$parent.data = data;
      vm.$parent.params = params.toString();
      vm.$parent.preview = true;
    },
  },
  mounted() {
    let vm = this;
    if (vm.getprovider.length != 0) {
      vm.pushoperator();
    }
  },
};
</script>

<style>
.leftdiv {
  width: 300px;
  float: left;
  margin-bottom: 10px;
}
.rightdiv {
  margin-top: 10px;
  float: left;
  height: 100px;
}
</style>
