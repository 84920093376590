<template>
  <div class="about_wrapper">
    <div class="about">
      <div class="about_left">
        <div class="display-2">
          about us
        </div>
        <img src="../assets/about.jpg" />
        <div class="title">
          It has secured direct relationships with service providers and has
          singularly focused on investing in developing a strong technical
          backbone to make the recharge experience reliable, secure, intuitive
          and seamless for the end user.
        </div>
      </div>
      <div class="about_right">
        <div class="title" style="margin-top: 70px;">
          Our specialty is to provide the recharge without gmail, facebook
          interface. Self Technology is one of the best recharge level website in the
          India. Self Technology gives you to multiple operator recharge in single
          balance. It means the retailer does’t have to invest separately for
          each operator and hence saving the hassle of maintaining separate sims
          and mobiles for different operators.
        </div>
        <div class="title" style="margin-top: 20px">
          The recharge solutions have been developed and scrubbed to ensure its
          availability at no extra cost to the end user so that the user can
          transact securely from any location at his convenience. We would like
          to take the opportunity to thank our patrons for helping us help them
          and essentially for shaping our growth.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
};
</script>

<style scoped>
.about_wrapper {
  width: 100%;
  height: 450px;
  background: #f0f0f0;
}
.about {
  width: 1000px;
  height: 450px;
  margin: 0 auto;
}
.about_left {
  width: 500px;
  height: 400px;
  float: left;
}
.about_right {
  width: 500px;
  height: 400px;
  float: left;
}
.display-2 {
  margin-top: 10px;
  color: #463734;
}
.about_left img {
  width: 500px;
  margin-top: 20px;
  border-radius: 20px;
}
.about_left .title {
  margin-top: 20px;
  font-weight: normal;
  color: grey;
}

.about_right .title {
  margin-top: 20px;
  margin-left: 20px;
  font-weight: normal;
  color: grey;
}
</style>
