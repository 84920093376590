<template>
  <div>
    <v-tabs
      next-icon="arrow_forward_ios"
      prev-icon="arrow_back_ios"
      show-arrows
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        class="primary--text"
        v-for="(tab, i) in tabs"
        @click="way = tab.way"
        :key="i + 'tab'"
      >
        <img :src="tab.img" :width="tab.width" />
        &nbsp; &nbsp;{{ tab.name }}
      </v-tab>
    </v-tabs>
    <Complaint v-if="way == 'complaint'" />
    <ComplaintList v-if="way == 'complaintlist'" />
    <Status v-if="way == 'status'" />
    <Progress v-if="progress" />
    <Preview v-if="preview" :data="data" :params="params" />
    <Message v-if="message" :res="response" />
  </div>
</template>

<script>
import Progress from "../components/Progress.vue";
import Preview from "../components/Preview.vue";
import Message from "../components/Message.vue";
import Status from "../complaint/Status.vue";
import Complaint from "../complaint/Complaint.vue";
import ComplaintList from "../complaint/Complaintlist.vue";
export default {
  components: {
    Complaint,
    Status,
    Progress,
    Preview,
    Message,
    ComplaintList
  },
  data() {
    return {
      way: "complaint",
      data: [],
      progress: false,
      preview: false,
      message: false,
      response: "",
      params: "",
      type: "CM",
      tabs: [
        {
          name: "Raise Complaint",
          img: require("../assets/complaint.png"),
          way: "complaint",
          width: 25
        },
        {
          name: "Complaint Status",
          img: require("../assets/status.png"),
          way: "status",
          width: 25
        },
        {
          name: "Complaint List",
          img: require("../assets/success.svg"),
          way: "complaintlist",
          width: 25
        }
      ]
    };
  },
  mounted() {
    let height = window.innerHeight - 210;
    let v_table = document.getElementsByClassName("v-table__overflow");
    for (let i = 0; i < v_table.length; i++) {
      v_table[i].style.height = height + "px";
    }
  }
};
</script>
