<template>
  <div style="width: 300px; margin: 0 auto">
    <div
      style="width: 100%; text-align: center; margin-top: 15px; height: 50px"
      class="headline"
    >
      IMPS
    </div>
    <v-text-field
      v-model="number"
      style="margin: 0 auto; height: 60px"
      label="Enter Mobile Number"
      solo
    />
    <v-text-field
      type="text"
      v-model="accountname"
      style="margin: 0 auto; height: 60px"
      label="Enter Account Name"
      solo
    />
    <v-text-field
      v-model="accountnumber"
      style="margin: 0 auto; height: 60px"
      label="Enter Account Number"
      solo
    />
    <v-text-field
      type="text"
      v-model="ifsccode"
      style="margin: 0 auto; height: 60px"
      label="Enter IFSC Code"
      solo
    />
    <v-text-field
      v-model="receivernumber"
      style="margin: 0 auto; height: 60px"
      label="Enter Receiver Number"
      solo
    />
    <v-text-field
      v-model="amount"
      style="margin: 0 auto; height: 60px"
      label="Enter Amount"
      solo
    />
    <v-text-field
      type="text"
      v-model="remark"
      style="margin: 0 auto; height: 60px"
      label="Enter Remark"
      solo
    />
    <div style="width: 100%; float: left; margin-bottom: 10px">
      <v-btn color="primary" style="width: 100%; margin: 0 auto" @click="submit"
        >Submit</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      number: "",
      accountname: "",
      accountnumber: "",
      ifsccode: "",
      receivernumber: "",
      remark: "",
      amount: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    clear() {
      return this.$store.getters.clear;
    },
  },
  watch: {
    clear() {
      this.cleardata();
    },
  },
  methods: {
    cleardata() {
      let vm = this;
      vm.number = "";
      vm.accountname = "";
      vm.accountnumber = "";
      vm.ifsccode = "";
      vm.receivernumber = "";
      vm.remark = "";
      vm.amount = "";
    },
    submit() {
      let vm = this;
      if (vm.user.length == 0)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Authentication Required!",
          type: "warn",
        });
      if (vm.number == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Mobile Number Required",
          type: "error",
        });
      if (vm.number.length != 10)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "10 Digit Number Required",
          type: "error",
        });
      if (vm.accountname == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Account Name Required",
          type: "error",
        });
      if (vm.accountnumber == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Account Number Required",
          type: "error",
        });
      if (vm.ifsccode == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "IFSC Code Required",
          type: "error",
        });
      if (vm.receivernumber == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Receiver Number Required",
          type: "error",
        });
      if (vm.receivernumber.length != 10)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "10 Digit Number Required",
          type: "error",
        });
      if (vm.amount == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Transfer Amount Required",
          type: "error",
        });
      let params =
        "?Mob=" +
        vm.user.phone +
        "&message=BP+IMPS+" +
        vm.number +
        "-" +
        vm.accountname +
        "-" +
        vm.accountnumber +
        "-" +
        vm.ifsccode +
        "-" +
        vm.receivernumber +
        "+" +
        vm.amount +
        "+" +
        vm.user.pin +
        "+" +
        vm.remark +
        "&source=ANDROID";
      let data = {
        "Mobile Number": vm.number,
        "Account Name": vm.accountname,
        "Account Number": vm.accountnumber,
        "IFSC Code": vm.ifsccode,
        "Receiver Number": vm.receivernumber,
        "Transfer Amount": vm.amount,
        Remark: vm.remark,
      };
      vm.$parent.data = data;
      vm.$parent.params = params.toString();
      vm.$parent.preview = true;
    },
  },
};
</script>

<style>
.leftdiv {
  width: 300px;
  float: left;
  margin-bottom: 10px;
}
.rightdiv {
  margin-top: 10px;
  float: left;
  height: 100px;
}
</style>
