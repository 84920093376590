<template>
  <div>
    <div
      style="width: 400px; margin: 0 auto; margin-top: 30px; padding-top: 10px"
    >
      <v-text-field
        style="min-width: 300px; width: 400px; margin: 0 auto; height: 60px"
        label="Sender Phone Number"
        @input="onInput"
        v-model="phone"
        solo
      />
      <div style="width: 100%; float: left">
        <v-btn
          color="primary"
          style="min-width: 300px; width: 400px; margin: 0 auto"
          @click="validate"
          >Validate</v-btn
        >
      </div>
    </div>
    <v-dialog v-model="registerdialog" width="350" height="300">
      <v-card>
        <v-card-title>Enter Detail</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            type="text"
            style="width: 300px; margin: 0 auto; height: 60px"
            label="Enter First Name"
            v-model="firstname"
            solo
          />
          <v-text-field
            type="text"
            style="width: 300px; margin: 0 auto; height: 60px"
            label="Enter Last Name"
            v-model="lastname"
            solo
          />
          <v-text-field
            style="width: 300px; margin: 0 auto; height: 60px"
            label="Enter Pincode"
            v-model="pincode"
            solo
          />
          <!-- <v-text-field
                                    type="text"
                                    style="width:300px;margin:0 auto;height:60px;"
                                    label="Enter Middle Name"
                                    v-model="middlename"
                                    solo
                                    />          
          -->
          <v-btn
            color="primary"
            @click="register"
            style="width: 300px; margin-left: 10px"
            >Validate</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="otpcheck" persistent width="300" height="300">
      <v-card>
        <v-card-title>Enter OTP</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            style="width: 200px; margin: 0 auto; height: 60px"
            label="OTP"
            v-model="otp"
            solo
          />
          <v-btn
            color="primary"
            @click="verify"
            style="width: 200px; margin-left: 35px"
            >Submit</v-btn
          >
          <!-- <v-btn color="primary" @click="resend" style="width:200px;margin-left:35px">Resend OTP</v-btn> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <Loader v-if="loader"></Loader>
  </div>
</template>
<script>
import Loader from "../components/Progress.vue";
export default {
  data() {
    return {
      phone: "",
      loader: false,
      firstname: "",
      middlename: "",
      lastname: "",
      pincode: "",
      registerdialog: false,
      otpcheck: false,
      verify_referenceno: "",
      otp: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    security() {
      return this.$store.getters.security;
    },
    dmr() {
      return this.$store.getters.dmr;
    },
  },
  watch: {
    dmr() {
      console.log(this.dmr);
    },
  },
  methods: {
    notification(type, message) {
      this.$notify({
        group: "foo",
        title: "Important message",
        text: message,
        duration: 5000,
        type: type,
      });
    },
    register() {
      let vm = this;
      if (vm.phone === "")
        return vm.notification("error", "Phone Number Required");
      if (vm.phone.length !== 10)
        return vm.notification("error", "10 Digit Number Required");
      if (vm.firstname === "")
        return vm.notification("error", "First Name Required");
      if (vm.lastname === "")
        return vm.notification("error", "Last Name Required");
      if (vm.pincode === "")
        return vm.notification("error", "Pincode Required");
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        AuthKey: vm.user.authkey,
        source: "json",
        mobilenumber: vm.phone,
        Security_Key: vm.security,
        firstName: vm.firstname,
        LastName: vm.lastname,
        PinCode: vm.pincode,
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "CreateCustomer",
          type: "dmr2",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          let data = res.data;
          if (data.status === "True") {
            vm.registerdialog = false;
            vm.otpcheck = true;
            vm.notification("success", data.message);
            return;
          }
          vm.notification("error", data.message);
        })
        .catch(function (err) {
          vm.loader = false;
          vm.notification("error", err.message);
        });
    },
    onInput() {
      if (this.phone.length != 10) return;
      this.validate();
    },
    validate() {
      let vm = this;
      if (vm.user == null || !vm.user.phone)
        return vm.notification("error", "Login Required!");
      if (vm.phone === "")
        return vm.notification("error", "Phone Number Required");
      if (vm.phone.length !== 10)
        return vm.notification("error", "10 Digit Number Required");
      let doc = {
        MobileNo: vm.user.phone,
        pinNo: vm.user.pin,
        mobilenumber: vm.phone,
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "ValidateCustomer",
          type: "dmr2",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          let data = res.data;
          if (data.status === "True") {
            let { bONDMRSenderDTO, bONDMRMonthalylimitDTO } = data;
            let user = bONDMRSenderDTO;
            user.mobile_no = user.MobileNumber;
            user.remaininglimit = bONDMRMonthalylimitDTO.Availblelimit;
            user.consumedLimit = bONDMRMonthalylimitDTO.UseLimit;
            user.split = bONDMRMonthalylimitDTO.SplitLimit;
            vm.$store.commit("setdmr", user);
            vm.$emit("onVerify");
          } else {
            if (data.message == "Create New Account") {
              vm.registerdialog = true;
            }
            if (
              (data.message = "Verification Pending.please verify Using otp.")
            ) {
              vm.otpcheck = true;
            }
            vm.notification("error", data.message);
          }
        })
        .catch(function (err) {
          vm.loader = false;
          vm.notification("error", err.message);
        });
    },
    resend() {
      let vm = this;
      if (vm.phone === "")
        return vm.notification("error", "Phone Number Required");
      if (vm.phone.length !== 10)
        return vm.notification("error", "10 Digit Number Required");
      let doc = {
        MobileNo: vm.user.phone,
        Password: vm.user.password,
        AuthKey: vm.user.authkey,
        source: "json",
        Sender_MobileNo: vm.phone,
        Security_Key: vm.security,
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "Resend_Otp_Validate",
          type: "dmr2",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          let data = res.data;
          if (data.status === "True") {
            vm.notification("success", data.description);
            return;
          }
          if (data.status === "False") {
            vm.notification("success", "Try after sometime");
            return;
          }
          vm.notification("error", data.description);
        })
        .catch(function (err) {
          vm.loader = false;
          vm.notification("error", err.message);
        });
    },
    verify() {
      let vm = this;
      if (vm.otp === "") return vm.notification("error", "OTP Required");
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        AuthKey: vm.user.authkey,
        source: "json",
        mobilenumber: vm.phone,
        Security_Key: vm.security,
        OTP: vm.otp,
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "ValidateOTP",
          data: doc,
          type: "dmr2",
        })
        .then(function (res) {
          vm.loader = false;
          let data = res.data;
          if (data.status === "True") {
            vm.notification("success", data.message);
            vm.otpcheck = false;
            vm.validate();
            return;
          }
          vm.notification("error", data.message);
        })
        .catch(function (err) {
          vm.loader = false;
          vm.notification("error", err.message);
        });
    },
  },
  components: { Loader },
};
</script>
