<template>
  <div>
    <Head></Head>
    <Banner id="home"></Banner>
    <Introduction></Introduction>
    <About id="about"></About>
    <Services id="service"></Services>
    <Download id="download"></Download>
    <Bank id="bank"></Bank>
    <Contact id="contact"></Contact>
    <Footer></Footer>
  </div>
</template>

<script>
import Head from "../website/Header.vue";
import Banner from "../website/Banner.vue";
import Introduction from "../website/Introduction.vue";
import About from "../website/About.vue";
import Services from "../website/Services.vue";
import Download from "../website/Download.vue";
import Bank from "../website/Bank.vue";
import Contact from "../website/Contact.vue";
import Footer from "../website/Footer.vue";

export default {
  name: "Website",
  data() {
    return {
      loader: false
    };
  },
  components: {
    Head,
    Banner,
    Introduction,
    About,
    Services,
    Download,
    Bank,
    Contact,
    Footer
  }
};
</script>

<style scoped></style>
