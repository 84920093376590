<template>
  <div>
    <Validate v-if="!isValidated" @onVerify="initDetails()"></Validate>
    <div v-else>
      <v-container fluid>
        <v-layout row>
          <v-flex sm6 style="margin-right: 10px">
            <div>
              <v-toolbar dense>
                <v-toolbar-title>Beneficiary List</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items></v-toolbar-items>
                <input type="text"   v-model="accsearch" class="search" style="float: left"  @input="onAccountnumberChange()"   placeholder="Search Account Number" />
              </v-toolbar>
             
             
            
              <v-card
                v-for="(item, i) in fbenifylist"
                :key="i"
                style="margin-top: 5px"
              >
                <v-card-title style="padding-bottom: 0px">
                  <div>
                    <div>Name: {{ item.BenName }}</div>
                    <div>Bank: {{ item.BankName }}</div>
                    <div>Acc No: {{ item.AccountNumber }}</div>
                    <div>IFSC Code: {{ item.IFSC }}</div>
                  </div>
                </v-card-title>
                <v-card-actions>
                  <v-btn
                    flat
                    color="success"
                    @click="
                      currentb = item;
                      transfer = true;
                    "
                    >Transfer</v-btn
                  >
                  <v-btn flat color="info" @click="validate(item)">{{
                    item.VerifyStatus == true ? "Revalidate" : "Validate"
                  }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn flat color="error" @click="remove(item)">remove</v-btn>
                </v-card-actions>
              </v-card>
            </div>
          </v-flex>
          <v-flex sm6 style="margin-left: 10px">
            <v-toolbar dense>
              <v-toolbar-title>Add Beneficiary</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items></v-toolbar-items>
              <a href="https://youtu.be/I43VxyUccQ4" target="_blank">UPI Transfer Demo</a>
            </v-toolbar>
            <div>
              <v-text-field
                type="text"
                style="
                  width: 300px;
                  margin: 0 auto;
                  height: 60px;
                  margin-top: 20px;
                "
                label="Beneficiary Name"
                v-model="bname"
                solo
              />
              <v-text-field
                style="width: 300px; margin: 0 auto; height: 60px"
                label="Beneficiary Mobile"
                v-if="false"
                v-model="bmobile"
                solo
              />
              <v-text-field
                style="width: 300px; margin: 0 auto; height: 60px"
                label="Account Number/ UPI Address"
                v-model="baccount"
                solo
              />
              <v-autocomplete
                style="width: 300px; margin: 0 auto; height: 60px"
                :items="banklist"
                item-text="bank_name"
                item-value="bank_name"
                @change="onBankChange"
                label="Bank Name"
                v-model="bank"
                solo
              />
              <v-text-field
                type="text"
                style="width: 300px; margin: 0 auto; height: 60px"
                label="IFSC Code"
                v-model="bifsccode"
                solo
              />
              <!-- <v-text-field
                type="text"
                style="width:300px;margin:0 auto;height:60px;"
                label="Beneficiary Name"
                v-model="bbname"
                solo
              />-->
              <v-text-field
                type="text"
                style="
                  width: 300px;
                  margin: 0 auto;
                  height: 60px;
                  display: none;
                "
                label="Branch Name"
                v-model="bbranch"
                solo
              />
              <div style="width: 300px; margin: 0 auto; height: 60px">
                <v-btn
                  color="primary"
                  @click="addbeneficiary"
                  style="width: 300px; margin-left: 0px"
                  >Add Beneficiary</v-btn
                >
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <v-dialog v-model="transfer" persistent width="400">
      <v-card>
        <v-card-title>Transfer Money</v-card-title>
        <v-divider></v-divider>
        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <div>Name: {{ currentb.BenName }}</div>
            <div>Bank: {{ currentb.BankName }}</div>
            <div>Acc No: {{ currentb.AccountNumber }}</div>
            <div>IFSC Code: {{ currentb.IFSC }}</div>
          </div>
        </v-card-title>
        <v-layout row wrap>
          <v-flex sm6>
            <v-radio-group style="margin-left: 20px" row v-model="type">
              <v-radio label="IMPS" value="4"></v-radio>
              <v-radio label="NEFT" value="2"></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex sm6>
            <v-text-field
              style="height: 60px; width: 180px"
              label="Amount"
              v-model="amount"
              @input="numbertoword"
              solo
            />
          </v-flex>
          <v-card-title>
          <div>
            <div style="color: red">Amount In Word : {{ numberinWord }}</div>
          </div>
        </v-card-title>
        </v-layout>
        <v-text-field
          class="mx-3"
        
          type="password"
          label="Pin No"
          v-model="pinno"
          solo
        />

        <v-card-title>
          <div>
            <div style="color: red">{{ dmrmessage }}</div>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-btn flat color="success" v-if="dmrmessage !== ''" :disabled="loader" @click="submit"
            >Confirm and pay</v-btn
          >
          <v-btn flat color="info" v-else @click="dmrcharge">Transfer</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            flat
            color="error"
            @click="
              transfer = false;
              amount = '';
              dmrmessage = '';
              currentb = {};
            "
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="otpcheck" persistent width="300" height="300">
      <v-card>
        <v-card-title>Enter OTP</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            style="width: 200px; margin: 0 auto; height: 60px"
            label="OTP"
            v-model="otp"
            solo
          />
          <v-btn
            color="primary"
            @click="verifyotp"
            style="width: 200px; margin-left: 35px"
            >Submit</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <Loader v-if="loader"></Loader>
    <dmr-1-success-message ref="message" :recId="recId"></dmr-1-success-message>
  </div>
</template>
<script>
import Loader from "../components/Progress.vue";
import Validate from "./Validate.vue";
import Dmr1SuccessMessage from "./Dmr1SuccessMessage.vue";
export default {
  data() {
    return {
      isValidated: false,
      loader: false,
      transfer: false,
      otpcheck: false,
      paymentPop: false,
      type: "4",
      otp: "",
      benifylist: [],
      fbenifylist: [],
      banklist: [],
      bname: "",
      bank: "",
      bmobile: "",
      baccount: "",
      bifsccode: "",
      bbname: "",
      bbranch: "",
      currentb: {},
      amount: "",
      dmrmessage: "",
      numberinWord:"",
      bid: "",
      startPayment: null,
      transferList: [],
      isPaytm: false,
      charge: 0,
      pinno: "",
      start: false,
      openingBalance: "",
      headers: [
        { text: "DMR Amount", value: "", sortable: false },
        { text: "DMR Charge", value: "", sortable: false },
        { text: "Status", value: "", sortable: false },
        { text: "Bank", value: "", sortable: false },
        { text: "UTR No", value: "", sortable: false },
        { text: "Closing", value: "", sortable: false },
        { text: "Progress", value: "", sortable: false, align: "center" },
      ],
      viewstatus: false,
      summary: {},      
      recId : ""
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    security() {
      return this.$store.getters.security;
    },
    dmr() {
      return this.$store.getters.dmr;
    },
    balance() {
      return this.$store.getters.balance;
    },
    totalDMR() {
      let vm = this;
      let amt = 0;
      vm.transferList.forEach((item) => (amt += item.charge));
      return amt;
    },
    filterTransferList() {},
    resultQuery(){
     
    }
  },
  watch: {
    amount() {
      this.dmrmessage = "";
    },
  },
  methods: {
    async submit() {
      this.disabled = true;
      let vm = this;
      if (!vm.pinno) {
        return vm.notification("warn", "Please enter the pin");
      }
      if (vm.pinno != vm.user.pin) {
        return vm.notification("warn", "Your pin is wrong");
      }
      if (vm.amount === "") return vm.notification("warn", "Amount Required");
      if (vm.user == null || !vm.user.phone)
        return vm.notification("error", "Login Required!");
      let item = vm.currentb;
      var date = new Date();
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        sndrmobile: vm.dmr.mobile_no,
        servicename: vm.type,
        benaccountno: item.AccountNumber,
        ifsc: item.IFSC,
        benid: item.Id,
        benname: item.BenName,
        benmobile: item.CustomerNumber,
        Amount: vm.amount,
        benbankname: item.BankName,
        ReceiptId:date.getDay() + "" + date.getMonth() + "" + date.getFullYear() + "" + date.getHours() + "" + date.getMinutes() + "" + date.getSeconds() + "" + date.getMilliseconds(),
      };

      vm.recId = doc.ReceiptId;
      vm.loader = true;
      let list = vm.transList();
      console.log(list);
      let error = false;
      let totalamount = vm.amount;
      let total = 0;
      let message = "";
      for (var i = 0; i < list.length; i++) {
        try {
          doc.Amount = list[i];
          if (!error) {
            message += `Transfer amount : ${list[i]} </br>`;
            let res = await vm.sendMoney(doc);
            message += `Response : ${res}</br> </br>`;
            console.log("total", total);
            total = Number(total) + Number(list[i]);
            console.log(total);
            console.log(list[i]);
          }
        } catch (e) {
          error = true;
          message += `Response : ${e} </br></br>`;
        }
      }
      message += `Total amount : ${totalamount} </br>`;
      message += `Transfered amount : ${total} </br>`;
      vm.loader = false;
      vm.amount = "";
      vm.dmrmessage = "";
      vm.transfer = false;
      vm.$store.dispatch("getbalance");
      vm.fetchbenify();
      vm.$refs.message.init(message);
    },
    sendMoney(doc) {
      const vm = this;
      return new Promise((resolve, reject) => {
        vm.$store
          .dispatch("axiosdmrpost", {
            url: "SendMoney",
            data: doc,
            type: "dmrCyberplat",
          })
          .then(function ({ data }) {
            if (data.status == "True") {
              resolve(data.message);
            } else {
              reject(data.message);
            }
          })
          .catch(function (error) {
            reject(error.message);
          });
      });
    },
    onBankChange() {
      let bank = this.banklist.find((item) => item.bank_name == this.bank);
      this.bifsccode = "";
      if (bank != null) {
        this.bifsccode = bank.branch_ifsc;
      }
      console.log(this.bifsccode);
    },
    onAccountnumberChange() {
    
      if(this.accsearch){
        this.fbenifylist = this.benifylist.filter((item)=>{
        return this.accsearch.toLowerCase().split(' ').every(v => item.AccountNumber.toLowerCase().includes(v))
      })    
      }else{
        this.fbenifylist = this.benifylist;
      }
    },
    numbertoword() {
      debugger   
      let num = this.amount;
      var converter = require('number-to-words');
    this.numberinWord =converter.toWords(num);
    this.numberinWord = this.numberinWord.toUpperCase();
    },
    transList() {
      const vm = this;
      let list = [];
      let total = vm.amount;
      let limit = vm.dmr.split;
      console.log("limit", limit);
      if (total > limit) {
        let reminder = total % limit;
        let subtotal = total - reminder;
        let count = subtotal / limit;
        for (var i = 0; i < count; i++) {
          list.push(limit);
        }
        if (reminder !== 0) {
          list.push(reminder);
        }
      } else {
        list.push(total);
      }
      return list;
    },
    dmrcharge(amount) {
      let vm = this;
      if (!vm.pinno) {
        return vm.notification("warn", "Please enter the pin");
      }
      if (vm.pinno != vm.user.pin) {
        return vm.notification("warn", "Your pin is wrong");
      }
      if (vm.amount === "") return vm.notification("warn", "Amount Required");
      if (vm.amount <= 0)
        return vm.notification("error", "Amount must be above 0");
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        Amount: vm.amount,
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "DMRCharges",
          type: "dmrCyberplat",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          vm.dmrmessage = res.data.Message;
          vm.charge = parseFloat(res.data.DMRCharge.split("Total Rs.")[1]);
        })
        .catch(function (error) {
          vm.loader = false;
          vm.notification("error", error);
        });
    },

    addbeneficiary() {
      let vm = this;
      console.log(vm.bname);
      if (vm.bname === "")
        return vm.notification("warn", "Beneficiary Name Required");
      // if (vm.bmobile === "")
      //   return vm.notification("warn", "Beneficiary Mobile Required");
      // if (vm.bmobile.length !== 10)
      //   return vm.notification("warn", "Enter Valid Mobile");
      if (vm.baccount === "")
        return vm.notification("warn", "Beneficiary Account Required");
      if (vm.bank === "")
        return vm.notification("warn", "Please select the bank name");
      if (vm.bifsccode === "")
        return vm.notification("warn", "Beneficiary IFSC Code Required");
      // if (vm.bbname === "")
      //   return vm.notification("warn", "Beneficiary Name Required");
      //    if(vm.bbranch === "") return vm.notification("warn","Beneficiary Branch Required")
      let split = vm.bname.split(" ");
      let lastname = split.length > 1 ? split[1] : "";
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        RemId: vm.dmr.id,
        BankName: vm.bank,
        Benfname: split[0],
        BenLname: lastname,
        AccountNo: vm.baccount,
        IFSC: vm.bifsccode,
        mobilenumber: vm.dmr.mobile_no,
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "AddBeneficiary",
          type: "dmrCyberplat",
          data: doc,
        })
        .then(function (res) {
          vm.fetchbenify();
          vm.loader = false;
          let data = res.data;
          vm.bname = "";
          vm.bank = "";
          vm.bmobile = "";
          vm.baccount = "";
          vm.bifsccode = "";
          vm.bbname = "";
          vm.bbranch = "";
          if (data.status === "True") {
            vm.notification("success", "Beneficiary added");
            return;
          }
          vm.notification("error", data.message);
        })
        .catch(function (err) {
          vm.loader = false;
          vm.notification("error", err.message);
        });
    },
    print() {},
    verifyotp() {
      let vm = this;
      if (vm.otp === "") return vm.notification("warn", " OTP Required");
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        remid: vm.dmr.id,
        benid: vm.currentb.id,
        OTP: vm.otp,
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "DeleteValidateBeneficiary",
          type: "dmrCyberplat",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          let data = res.data;
          if (data.status === "False") {
            vm.notification("error", data.message);
            return;
          }
          vm.otpcheck = false;
          vm.notification("success", data.message);
          vm.otp = "";
          vm.fetchbenify();
        })
        .catch(function (err) {
          vm.loader = false;
          vm.notification("error", err.message);
        });
    },
    fetchbenify() {
      let vm = this;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        mobilenumber: vm.dmr.mobile_no,
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "ValidateCustomer",
          type: "dmrCyberplat",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          let data = res.data;
          if (data.status === "True") {
            let { bONDMRMonthalylimitDTO, bONDMRBeneficiaryDetailDTO } = data;
            vm.benifylist = bONDMRBeneficiaryDetailDTO || [];
            vm.fbenifylist  =  vm.benifylist ;
            let user = { ...vm.$store.getters.dmr };
            user.remaininglimit = bONDMRMonthalylimitDTO.Availblelimit;
            user.consumedLimit = bONDMRMonthalylimitDTO.UseLimit;
            user.split = bONDMRMonthalylimitDTO.SplitLimit;
            console.log(user);
            vm.$store.commit("setdmr", user);
          }
        })
        .catch(function (err) {
          vm.loader = false;
          vm.notification("error", err.message);
        });
    },
    fetchbank() {
      let vm = this;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
      };
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "Banklist",
          type: "dmrCyberplat",
          data: doc,
        })
        .then(function (res) {
          console.log(res);
          vm.banklist = res.data.data;
        })
        .catch(function (err) {
          vm.notification("error", err.message);
        });
    },
    remove(item) {
      let vm = this;
      vm.currentb = item;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        BenId: item.Id,
      };
      let check = confirm("Are you sure?");
      if (!check) return;
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "DeleteBeneficiary",
          type: "dmrCyberplat",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          let data = res.data;
          if (data.status === "True") {
            vm.fetchbenify();
            vm.notification("success", data.message);
            return;
          }
          vm.notification("error", data.message);
        })
        .catch(function (err) {
          vm.loader = false;
          vm.notification("error", err.message);
        });
    },
    validate(item) {
      let vm = this;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        sndrMobileNo: vm.dmr.mobile_no,
        BenName: item.BankName,
        RecipientId: item.Id,
        BenAccountNo: item.AccountNumber,
        BenIFSC: item.IFSC,
        benbankname: item.BankName,
        benmobileno: item.CustomerNumber,
        pinnno: vm.user.pin,
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "Validatebeneficiary",
          type: "dmrCyberplat",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          let data = res.data;
          vm.$store.dispatch("getbalance");
          let message = data.Message;
          vm.fetchbenify();
          vm.$root.alert(message);
        })
        .catch(function (err) {
          vm.loader = false;
          vm.notification("error", err.Message);
        });
    },
    notification(type, message) {
      this.$notify({
        group: "foo",
        title: "Important message",
        text: message,
        duration: 5000,
        type: type,
      });
    },
    initDetails() {
      this.isValidated = true;
      this.fetchbenify();
      this.fetchbank();
    },
  },
  async mounted() {
    let vm = this;
    vm.fetchbank();
    // vm.paymentPop = true;
    // vm.transferList = await vm.transList();
  },
  components: { Loader, Validate, Dmr1SuccessMessage },
};
</script>

<style >
.payment__table .v-table__overflow {
  width: 670px;
}


</style>

<style scoped>
.search {
  width: 200px;
  float: right;
  height: 34px;
  background: white;
  border: 1px solid lightgray;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
</style>
