import Vue from "vue";
import "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import "./assets/index.css";
import VueScrollTo from "vue-scrollto";
import "material-design-icons/iconfont/material-icons.css";
import JsonExcel from "vue-json-excel";
import Notifications from "vue-notification";
import "vue-snotify/styles/material.css";
import ConfirmModel from "./components/confirm";
import LoaderModel from "./components/loader";
import AlertModel from "./components/alert";
import commonMixins from "./js/common.mixins.js";


Vue.use(VueScrollTo, {
  container: "body",
  duration: 1000,
  easing: "ease-in",
  offset: -80,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

import Snotify from "vue-snotify"; // 1. Import Snotify
Vue.use(Snotify);
Vue.mixin(commonMixins)
Vue.use(ConfirmModel);
Vue.use(LoaderModel);
Vue.use(AlertModel);
Vue.use(Notifications);
Vue.use(require("vue-moment"));
Vue.component("downloadExcel", JsonExcel);
Vue.config.productionTip = false;
Vue.config.disableHostCheck = true;
Vue.use(VueAxios, axios);
new Vue({
  router,
  store,
  data() {
    return {
      messagePop: false,
      message: "welcome",
    };
  },
  computed: {
    loginType() {
      return store.getters.way;
    },
  },
  methods: {
    alert(msg) {
      let vm = this;
      vm.message = msg;
      vm.messagePop = true;
    },
  },

  render: (h) => h(App),
}).$mount("#app");
