<template>
  <div>
    <v-card style="width:100%;padding:10px;">
      <v-layout row wrap style="height:40px;">
        <v-flex xs12 style="padding-top:8px">
          <v-btn class="success searchbtn" @click="fetchReports">Search</v-btn>
          <v-menu
            :close-on-content-click="false"
            v-model="menu2"
            lazy
            transition="scale-transition"
            offset-y
            style="float:right"
          >
            <input
              type="text"
              slot="activator"
              v-model="date2"
              readonly
              class="search"
              placeholder="To Date"
            />
            <v-date-picker
              v-model="date2"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            :close-on-content-click="false"
            v-model="menu1"
            lazy
            transition="scale-transition"
            offset-y
            style="float:right"
          >
            <input
              type="text"
              slot="activator"
              v-model="date1"
              readonly
              class="search"
              placeholder="From Date"
            />
            <v-date-picker
              v-model="date1"
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-flex>
        <v-layout row wrap>
          <v-spacer></v-spacer>
        </v-layout>
      </v-layout>
    </v-card>
    <v-data-table :headers="headers" :items="list" :search="search">
      <template slot="items" slot-scope="props">
        <td v-for="(item, i) in headers" :key="i">
          {{ props.item[item.value] }}
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import aeps2Service from "../../services/aeps2.service";
export default {
  data() {
    return {
      modal: false,
      date1: moment()
        .add(-20, "days")
        .format("YYYY-MM-DD"),
      menu1: false,
      date2: moment().format("YYYY-MM-DD"),
      menu2: false,
      mobile: "",
      headers: [
        { text: "Id", value: "Id", align: "left" },
        { text: "Status", value: "TransactionStatus" },
        { text: "Amount", value: "Amount" },
        { text: "Dealer Name", value: "DealerName" },
        { text: "Aadhar Number", value: "AddharNumber" },
        { text: "Mobile Number", value: "MobileNo" },
        { text: "Tx ID", value: "TxId" },
        { text: "Dealer Commission", value: "DealerCommission" },
        { text: "Pay Transfer ID", value: "PayTransferInfo" },
        { text: "Created At", value: "Date" },
      ],
      list: [],
      search: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    dmr() {
      return this.$store.getters.dmr;
    },
  },
  methods: {
    async fetchReports() {
      const vm = this;
      try {
        vm.$loader.show();
        vm.list = [];
        let doc = {
          StartDate: vm.date1,
          EndDate: vm.date2,
          Status: "",
          AepsId: "",
          SearchbyValue: "",
        };
        let { Status, Message, data } = (
          await aeps2Service.getReports(doc)
        ).data;
        if (Status != "True") {
          vm.$alert.show(Message);
        }
        let status = { 0: "Pending", 1: "Failure", 2: "Success" };
        vm.list = data.map((item) => {
          let date = item.Date.replace("/Date(", "").replace(")/", "");
          item.Date = moment(new Date(Number(date))).format("DD-MM-YYYY");
          item.TransactionStatus = status[item.TransactionStatus];
          return item;
        });
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.fetchReports();
  },
};
</script>

<style scoped>
.search {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  border: 1px solid lightgray;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
