<template>
  <div>
    <v-tabs
      next-icon="arrow_forward_ios"
      prev-icon="arrow_back_ios"
      style="margin-top:10px;"
      dense
    >
      <v-tab
        class="primary"
        style="color:white"
        v-for="(tab, i) in tabs"
        @click="way = tab.way"
        :key="i + 'tab'"
      >
        <img :src="tab.img" :width="20" />
        &nbsp; &nbsp;{{ tab.name }}
      </v-tab>
    </v-tabs>
    <IMPS v-show="way == 'imps'" />
    <IMPSMMID v-show="way == 'impsmmid'" />
    <NEFT v-show="way == 'neft'" />
    <Progress v-if="progress" />
    <Preview v-if="preview" :data="data" :params="params" />
    <Message v-if="message" :res="response" />
  </div>
</template>
<script>
import IMPS from "./IMPS.vue";
import IMPSMMID from "./IMPSMMID.vue";
import NEFT from "./NEFT.vue";
import Progress from "../components/Progress.vue";
import Preview from "../components/Preview.vue";
import Message from "../components/Message.vue";
export default {
  data() {
    return {
      data: [],
      type: "BP",
      progress: false,
      preview: false,
      message: false,
      response: "",
      params: "",
      tabs: [
        {
          name: "IMPS",
          img: require("../assets/imps.jpeg"),
          way: "imps",
          width: 40
        },
        {
          name: "NEFT",
          img: require("../assets/neft.png"),
          way: "neft",
          width: 40
        },
        {
          name: "IMPS MMID",
          img: require("../assets/imps.jpeg"),
          way: "impsmmid",
          width: 40
        }
      ],
      way: "imps"
    };
  },
  methods: {
    fetchdata() {
      this.$parent.fetchdata;
    }
  },
  components: {
    IMPS,
    IMPSMMID,
    NEFT,
    Progress,
    Preview,
    Message
  }
};
</script>
