<template>
  <div>
    <v-toolbar class="secondary" dense style="color: white; font-weight: bold">
      Recharge Report
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <download-excel
          :data="statementlist"
          name="Recharge.xls"
          :fields="fields"
          style="height: 30px; margin-top: 9px; margin-right: 10px"
        >
          <v-btn class="success" small>Export</v-btn>
        </download-excel>

        <v-menu
          :close-on-content-click="false"
          v-model="menu1"
          lazy
          transition="scale-transition"
          offset-y
          style="float: right"
        >
          <input
            type="text"
            slot="activator"
            style="color: black"
            v-model="date1"
            readonly
            class="search"
            placeholder="From Date"
          />
          <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
        </v-menu>
        <v-menu
          :close-on-content-click="false"
          v-model="menu2"
          lazy
          transition="scale-transition"
          offset-y
          style="float: right"
        >
          <input
            type="text"
            slot="activator"
            style="color: black"
            v-model="date2"
            readonly
            class="search"
            placeholder="To Date"
          />
          <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
        </v-menu>

        <v-btn
          class="success searchbtn"
          style="height: 34px; margin-left: 10px"
          @click="fetchstatement"
          >Search</v-btn
        >
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="statementlist"
      hide-actions
      :loading="loading"
    >
      <v-progress-linear
        slot="progress"
        color="blue"
        indeterminate
      ></v-progress-linear>
      <template slot="items" slot-scope="props">
        <td>
          {{
            new Date(parseInt(props.item.date))
              | moment("DD-MM-YYYY, h:mm:ss a")
          }}
        </td>
        <td>{{ props.item.servicename }}</td>
        <td>{{ props.item.mobilenumber }}</td>
        <td>{{ props.item.openbalance }}</td>
        <td>{{ props.item.amount }}</td>
        <td>{{ props.item.closebalance }}</td>
        <td>{{ props.item.commission }}</td>
        <td>{{ props.item.surcharge }}</td>
        <td>{{ props.item.incentive }}</td>
        <td>{{ props.item.rechargetype }}</td>
        <td>
          {{
            new Date(parseInt(props.item.requestdate))
              | moment("DD-MM-YYYY, h:mm:ss a")
          }}
        </td>
        <td>{{ props.item.tid }}</td>
        <td>{{ props.item.transactionid }}</td>
        <td>{{ props.item.clienttxid }}</td>
        <td>{{ props.item.source }}</td>
        <td>{{ props.item.circle }}</td>
        <td>
          <v-btn
            small
            :class="checkstatus(props.item.status)"
            style="width: 100%"
            flat
            >{{ props.item.status === 2 ? "Success" : "Fail" }}</v-btn
          >
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["change"],
  data() {
    return {
      modal: false,
      vchange: "",
      date1: new Date().toISOString().substr(0, 10),
      menu1: false,
      date2: new Date().toISOString().substr(0, 10),
      menu2: false,
      loading: false,
      fields: {
        "Created Date": "date",
        "Service Name": "servicename",
        "Mobile Number": "mobilenumber",
        "Opening Balance": "openbalance",
        Amount: "amount",
        "Closing Balance": "closebalance",
        Commission: "commission",
        Surcharge: "surcharge",
        Incentive: "incentive",
        "Recharge Type": "rechargetype",
        "Request Date": "requestdate",
        ID: "tid",
        "Transaction ID": "transactionid",
        "Client TXID": "clienttxid",
        Source: "source",
        Circle: "circle",
        Status: "status",
      },
      headers: [
        { text: "Created Date", value: "date" },
        { text: "Service Name", value: "servicename" },
        { text: "Mobile Number", value: "mobilenumber" },
        { text: "Opening Balance", value: "openbalance" },
        { text: "Amount", value: "amount" },
        { text: "Closing Balance", value: "closebalance" },
        { text: "Commission", value: "commission" },
        { text: "Surcharge", value: "surcharge" },
        { text: "Incentive", value: "incentive" },
        { text: "Recharge Type", value: "rechargetype" },
        { text: "Request Date", value: "requestdate" },
        { text: "ID", value: "tid" },
        { text: "Transaction ID", value: "transactionid" },
        { text: "Client TXID", value: "clienttxid" },
        { text: "Source", value: "source" },
        { text: "Circle", value: "circle" },
        { text: "Status", value: "status" },
      ],
      statementlist: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    checkstatus(status) {
      if (status == 2) return "success";
      if (status == 1) return "error";
      return "primary";
    },
    changeview() {
      this.$parent.change = true;
    },
    fetchstatement() {
      let vm = this;
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        StartDate: vm.date1.split("-").join("/"),
        EndDate: vm.date2.split("-").join("/"),
      };
      let url =
        vm.$root.loginType === "dmr" ? "RechargeSearchDMR" : "RechargeSearch";
      vm.$store
        .dispatch("axios", {
          url: url,
          data: doc,
        })
        .then(function (res) {
          vm.loading = false;
          vm.pushstatement(res.data);
        })
        .catch(function (error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn",
          });
        });
    },
    pushstatement(datas) {
      datas = datas.Data;
      let vm = this;
      vm.statementlist = [];
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        let doc = {
          date: data.CreatedDate.replace("/Date(", "").replace(")/", ""),
          servicename: data.ServiceName,
          mobilenumber: data.MobileNo,
          openbalance: data.OpeningBal,
          amount: data.Amount,
          closebalance: data.ClosingBal,
          commission: data.Commission,
          surcharge: data.Surcharge,
          incentive: data.Incentive,
          rechargetype: data.RechargeType,
          requestdate: data.RequestDate.replace("/Date(", "").replace(")/", ""),
          tid: data.Id,
          transactionid: data.TransactionId,
          clienttxid: data.ClientTxid,
          source: data.Source,
          circle: data.Circle,
          status: data.Status,
        };
        vm.statementlist.push(doc);
      }
      vm.loading = false;
    },
  },
  mounted() {
    let vm = this;
    vm.fetchstatement();
    if (vm.change != null) {
      vm.vchange = vm.change;
    }
  },
};
</script>

<style scoped>
.search {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
