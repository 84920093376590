<template>
  <div style="width: 350px; margin-left: auto; margin-right: auto">
    <div
      style="width: 100%; text-align: center; margin-top: 15px; height: 50px"
      class="headline"
    >
      Change Pin
    </div>
    <v-text-field
      v-model="newpin"
      style="margin: 0 auto; height: 60px"
      label="Enter New Pin"
      solo
    ></v-text-field>
    <v-text-field
      v-model="oldpin"
      style="margin: 0 auto; height: 60px"
      label="Enter Old Pin"
      solo
    />
    <div style="width: 100%; float: left">
      <v-btn color="primary" style="width: 100%; margin: 0 auto" @click="submit"
        >Submit</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newpin: "",
      oldpin: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    clear() {
      return this.$store.getters.clear;
    },
  },
  watch: {
    clear() {
      this.cleardata();
    },
  },
  methods: {
    cleardata() {
      let vm = this;
      vm.number = "";
      vm.username = "";
    },
    submit() {
      let vm = this;
      if (vm.user.length == 0)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Authentication Required!",
          type: "warn",
        });
      if (vm.newpin == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Enter New Pin",
          type: "error",
        });
      if (vm.oldpin == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Enter Old Pin",
          type: "error",
        });
      if (vm.oldpin.length < 3)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "4 Digit PIN Required",
          type: "error",
        });
      let params =
        "?Mob=" +
        vm.user.phone +
        "&message=chgpin+" +
        vm.oldpin +
        "+" +
        vm.newpin +
        "&source=ANDROID";
      vm.$parent.progress = true;
      let path = "raxios";
      vm.$store
        .dispatch(path, {
          data: params,
        })
        .then(function (doc) {
          if (doc.data == "Your Pin has been change successfully.") {
            let json = JSON.parse(localStorage.user);
            json.pin = vm.newpin;
            localStorage.user = JSON.stringify(json);
            vm.$store.dispatch("getuser");
          }
          vm.$store.commit("setclear", new Date().getTime());
          vm.$parent.progress = false;
          vm.$parent.message = true;
          vm.$parent.response = doc.data;
          vm.$parent.preview = false;
        })
        .catch(function (error) {
          vm.$parent.progress = false;
          vm.$parent.message = true;
          vm.$parent.response = error;
        });
    },
  },
};
</script>

<style>
.leftdiv {
  width: 300px;
  float: left;
  margin-bottom: 10px;
}
.rightdiv {
  margin-top: 10px;
  float: left;
  height: 100px;
}
</style>
